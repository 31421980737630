import {ausspaehenVonDaten_indices} from "./topics/ausspaehenVonDaten_indices";
import {ausspaehenVonDaten} from "./topics/ausspaehenVonDaten";
import {phishing} from "./topics/phishing";
import {botnetze} from "./topics/botnetze";
import {phishing_indices} from "./topics/phishing_indices";
import {botnetze_indices} from "./topics/botnetze_indices";
import {trojaner_indices} from "./topics/trojaner_indices";
import {trojaner} from "./topics/trojaner";
import {computerviren} from "./topics/computerviren";
import {computerviren_indices} from "./topics/computerviren_indices";
import {malware} from "./topics/malware";
import {fakeShops} from "./topics/fakeShops";
import {fakeShops_indices} from "./topics/fakeShops_indices";
import {malware_indices} from "./topics/malware_indices";
import {catfishing} from "./topics/catfishing";
import {catfishing_indices} from "./topics/catfishing_indices";
import {cyberkriminalitaet} from "./topics/cyberkriminalitaet";
import {cybergrooming} from "./topics/cybergrooming";
import {cybermobbing} from "./topics/cybermobbing";
import {datenschutz} from "./topics/datenschutz";
import {ddos} from "./topics/ddos";
import {gutePasswoerter} from "./topics/gutePasswoerter";
import {hackingOnlineBankdaten} from "./topics/hackingOnlineBankdaten";
import {industriespionage} from "./topics/industriespionage";
import {iukStrafrecht} from "./topics/iukStrafrecht";
import {ransomware} from "./topics/ransomware";
import {sexting} from "./topics/sexting";
import {sichererUmgangImNetz} from "./topics/sichererUmgangImNetz";
import {skimming} from "./topics/skimming";
import {socialEngineering} from "./topics/socialEngineering";
import {sprachassistenten} from "./topics/sprachassistenten";
import {cyberkriminalitaet_indices} from "./topics/cyberkriminalitaet_indices";
import {cybergrooming_indices} from "./topics/cybergrooming_indices";
import {cybermobbing_indices} from "./topics/cybermobbing_indices";
import {datenschutz_indices} from "./topics/datenschutz_indices";
import {ddos_indices} from "./topics/ddos_indices";
import {gutePasswoerter_indices} from "./topics/gutePasswoerter_indices";
import {hackingOnlineBankdaten_indices} from "./topics/hackingOnlineBankdaten_indices";
import {industriespionage_indices} from "./topics/industriespionage_indices";
import {iukStrafrecht_indices} from "./topics/iukStrafrecht_indices";
import {ransomware_indices} from "./topics/ransomware_indices";
import {sexting_indices} from "./topics/sexting_indices";
import {sichererUmgangImNetz_indices} from "./topics/sichererUmgangImNetz_indices";
import {skimming_indices} from "./topics/skimming_indices";
import {socialEngineering_indices} from "./topics/socialEngineering_indices";
import {sprachassistenten_indices} from "./topics/sprachassistenten_indices";

let topicsMap = new Map();
topicsMap.set("70", ausspaehenVonDaten);
topicsMap.set("40", botnetze);
topicsMap.set("79", catfishing);
topicsMap.set("42", computerviren);
topicsMap.set("74", cyberkriminalitaet);
topicsMap.set("80", cybergrooming);
topicsMap.set("75", cybermobbing);
topicsMap.set("69", datenschutz);
topicsMap.set("41", ddos);
topicsMap.set("76", fakeShops);
topicsMap.set("44", gutePasswoerter);
topicsMap.set("73", hackingOnlineBankdaten);
topicsMap.set("71", industriespionage);
topicsMap.set("77", iukStrafrecht);
topicsMap.set("1", malware);
topicsMap.set("39", phishing);
topicsMap.set("43", ransomware);
topicsMap.set("78", sexting);
topicsMap.set("68", sichererUmgangImNetz);
topicsMap.set("67", skimming);
topicsMap.set("51", socialEngineering);
topicsMap.set("72", sprachassistenten);
topicsMap.set("2", trojaner);

let indicesMap = new Map();
indicesMap.set("70", ausspaehenVonDaten_indices);
indicesMap.set("40", botnetze_indices);
indicesMap.set("79", catfishing_indices);
indicesMap.set("42", computerviren_indices);
indicesMap.set("74", cyberkriminalitaet_indices);
indicesMap.set("80", cybergrooming_indices);
indicesMap.set("75", cybermobbing_indices);
indicesMap.set("69", datenschutz_indices);
indicesMap.set("41", ddos_indices);
indicesMap.set("76", fakeShops_indices);
indicesMap.set("44", gutePasswoerter_indices);
indicesMap.set("73", hackingOnlineBankdaten_indices);
indicesMap.set("71", industriespionage_indices);
indicesMap.set("77", iukStrafrecht_indices);
indicesMap.set("1", malware_indices);
indicesMap.set("39", phishing_indices);
indicesMap.set("43", ransomware_indices);
indicesMap.set("78", sexting_indices);
indicesMap.set("68", sichererUmgangImNetz_indices);
indicesMap.set("67", skimming_indices);
indicesMap.set("51", socialEngineering_indices);
indicesMap.set("72", sprachassistenten_indices);
indicesMap.set("2", trojaner_indices);

let colorMap = new Map();
colorMap.set("70", "#007bff");
colorMap.set("40", "#007bff");
colorMap.set("79", "#007bff");
colorMap.set("42", "#007bff");
colorMap.set("74", "#007bff");
colorMap.set("80", "#007bff");
colorMap.set("75", "#007bff");
colorMap.set("69", "#007bff");
colorMap.set("41", "#007bff");
colorMap.set("76", "#007bff");
colorMap.set("44", "#007bff");
colorMap.set("73", "#007bff");
colorMap.set("71", "#007bff");
colorMap.set("77", "#007bff");
colorMap.set("1", "#007bff");
colorMap.set("39", "#007bff");
colorMap.set("43", "#007bff");
colorMap.set("78", "#007bff");
colorMap.set("68", "#007bff");
colorMap.set("67", "#007bff");
colorMap.set("51", "#007bff");
colorMap.set("72", "#007bff");
colorMap.set("2", "#007bff");

export {topicsMap, indicesMap, colorMap};