import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Col,
    Container, Form,
    Modal,
    Row, Tab, Tabs
} from "react-bootstrap";
import {Link, useHistory, RouteComponentProps, useParams} from "react-router-dom";
import {userContext} from "../contexts/UserContext";
import {Answer} from "../models/Answers";
import {AppSettings} from "../config";
import {UserStatus} from "../models/User";
import {shuffleArray} from "../helper/array_shuffle";
import {Check, X} from "react-bootstrap-icons";
import {categoryContext} from "../contexts/CategoryContext";


type TParams = { id: string };
type Test = {
    question_id: number;
    description: string;
    category_id: number;
    difficult_id: number;
    right_answer: number;
    test_id: number;
    answers: Answer[];
};

type Post = {
    user_id: number | undefined;
    test_id: number;
    answers: Answer[];
    timer: number;
};


export const FinishTestPage = ({ match }: RouteComponentProps<TParams>) => {
    const [category_id] = useState(match.params.id);
    const context = useContext(userContext);
    const [Test_Passed, setTest_Passed] = useState(false);
    const [percentage, setPercentage] = useState(-1);
    const history = useHistory();
    const [userID] = useState<number>(context.user!.user_id);
    const [tests, setTests] = useState<Test[]>([]);
    const [radio, setRadio] = useState<any>([{}]);
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [postRadio, setPostRadio] = useState<any>([...radio]);
    const [test_id, setTestID] = useState(0);
    const [gaveFeedback, setgaveFeedback] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [post_values] = useState([...radio]);
    const {id} = useParams<TParams>();
    const [userStatus] = useState<UserStatus[]>(context.status);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [nextQuestion, setNextQuestion] = useState(0);
    const [questionAnswer, setQuestionAnswer] = useState<any>([{}]);
    const [testReview] = useState([...questionAnswer]);
    const [showValidation, setShowValidation] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [modalValidationState, setModalValidationState] = useState(false);
    const [timerState] = useState(context.timer);
    const categories = useContext(categoryContext);

    if (
        window.location.toString() !== AppSettings.AFTER_FINISH_URL + "fragender" ||
        window.location.toString() !== AppSettings.AFTER_FINISH_URL + "befragter"
    ) {
        document.querySelector("body > div.WatsonAssistantChatHost")?.remove();
    }

    const goToHomepage = () => {
        // eslint-disable-next-line no-restricted-globals
        history.push("/");
    };

    const goToFeedback = () => {
        // eslint-disable-next-line no-restricted-globals
        history.push("/OnlineUmfrage");
    };

    const changeHandler = (
        answerId: number,
        indexQuestion: number,
        questionId: number,
    ) => {
        const values = [...radio];

        values[indexQuestion] = answerId;
        post_values[indexQuestion] = [questionId, answerId];

        setPostRadio(post_values);
        setRadio(values);
        setTestID(tests[0].test_id);
        setIsActive(true);
    };

    const retrievePassedValue = async (test_id: number) =>
        await axios.get(AppSettings.TEST_URL + test_id,
            {headers: {
                    "Content-Type": "application/json",
                    Authorization: context.actions.authHeader(),}})
            .then((res) => res);

    const updateCategoryStatus = (test_id : number) => {
        let user_status_id: number;
        userStatus.forEach(async (value) => {
            if (value.category.category_id.toString() === id) {
                user_status_id = value.user_status_id;
                let passed_bool: number;
                let passed_value : string = "1";
                retrievePassedValue(test_id).then((response) => {
                    passed_bool = response.data.data.finished_test;
                    if (passed_bool == 0){
                        passed_value = "2";
                    }
                    if (passed_bool == 1){
                        passed_value = "3";
                        setTest_Passed(true);
                    }
                }).finally(() => {
                    axios
                        .put(
                            AppSettings.USER_STATUS + user_status_id,
                            {
                                status: passed_value,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: context.actions.authHeader(),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                // "Status sucessfully updated"
                            }
                        })
                        .catch((error) => {
                            if (error.response) {
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            } else if (error.request) {
                                console.log(error.request);
                            } else {
                                console.log("Error", error.message);
                            }
                            console.log(error.config);
                        });
                });
            }
        });
    };

    const submitHandler = () => {
        const user_id = context.user?.user_id;
        const submitObject: Post = {
            user_id: user_id,
            test_id: test_id,
            answers: postRadio,
            timer: seconds,
        };
        if (
            postRadio.filter((e: any) => Object.keys(e).length !== 0).length ===
            tests.length
        ) {
            setIsActive(false);
            axios
                .post(
                    AppSettings.TEST_URL + "finishedPointsTest",
                    {
                        submitObject,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: context.actions.authHeader(),
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        updateCategoryStatus(test_id);
                        setPercentage(response.data.data.points);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("data", error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    console.log(error.config);
                });
            axios.get(AppSettings.FEEDBACK + userID.toString()).then((response) => {
                if (response.status === 200 && response.data.data === 1) {
                    setgaveFeedback(true);
                }
            });
        }
    };

    useEffect(() => {
        let interval: any;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds + 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    useEffect(() => {
        let isMounted = true;

        axios
            .post(
                AppSettings.TEST_URL + "createExitTest",
                {
                    user_id: context.user?.user_id,
                    category_id: id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: context.actions.authHeader(),
                    },
                }
            )
            .then((response) => {

                if (response.status === 200 && isMounted) {
                    response.data.data
                        .forEach((e: any) => shuffleArray(e.answers));
                    setTests(shuffleArray(response.data.data));
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
        return () => {
            isMounted = false;
        };
    }, [context.actions, context.user?.user_id, id]);

    return (
        <div>
            {!showValidation && (
                <div>
                    <h1 className="text-center mt-4">Abschlusstest: {categories.actions.getCategory(Number(category_id))}</h1>
                    <p className="text-center lead text-muted mb-4">Fragen dein Wissen erneut ab!</p>
                    <div className="d-flex align-items-center testHeight " style={{backgroundColor: "#FFFFFF"}}>
                        <div className="container align-items-center justify-content-center testContainer box-blur"
                             style={{backgroundColor: "#FFFFFF"}}>
                            {tests.length > 0 ? (
                                <Container className="d-flex questionBoxSize" style={{minHeight: 450}}>
                                    {timerState && (
                                        <p className="text-primary font-weight-bolder font-weight-bold">Timer: {seconds}</p>
                                    )}
                                    <Row className="mx-auto my-auto">
                                        <Col>
                                            <Tabs className={"invisible"} activeKey={questionIndex}
                                                  id="controlled-tab-example">
                                                {tests.map((question, index) => {
                                                    return (renderQuestion(question, index))
                                                })}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Container>
                            ) : (
                                // <h1>Loading...</h1>
                                <h1></h1>
                            )}
                            <div className="container d-flex justify-content-end">
                                <Button
                                    className="mr-2"
                                    variant="secondary"
                                    disabled={questionIndex === 0}
                                    onClick={() => setQuestionIndex((prev) => prev - 1)}
                                >
                                    Zurück
                                </Button>
                                {questionIndex !== tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex === tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            setQuestionIndex((prev) => prev + 1)
                                        }}
                                    >
                                        Weiter
                                    </Button>
                                )}
                                {questionIndex === tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex !== tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            submitHandler();
                                            setQuestionIndex(0);
                                            setModalValidationState(true);
                                        }}
                                    >
                                        Eingabe bestätigen
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showValidation && (
                <div>
                    <h1 className="text-center mt-4">Deine Ergebnisse</h1>
                    <p className="text-center lead text-muted mb-4">Übersicht deiner Antworten</p>
                    <div className="d-flex align-items-center testHeight " style={{backgroundColor: "#FFFFFF"}}>
                        <div className="container align-items-center justify-content-center testContainer box-blur"
                             style={{backgroundColor: "#FFFFFF"}}>
                            {tests.length > 0 ? (
                                <Container>
                                    <Row>
                                        <Col>
                                            <Tabs className={"invisible"} activeKey={questionIndex}
                                                  id="controlled-tab-example">
                                                {tests.map((question, index) => {
                                                    return (renderQuestionValidation(question, index))
                                                })}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Container>
                            ) : (
                                // <h1>Loading...</h1>
                                <h1></h1>
                            )}
                            <div className="container d-flex justify-content-end">
                                <Button
                                    className="mr-2"
                                    variant="secondary"
                                    disabled={questionIndex === 0}
                                    onClick={() => setQuestionIndex((prev) => prev - 1)}
                                >
                                    Zurück
                                </Button>
                                {questionIndex !== tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex === tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            setQuestionIndex((prev) => prev + 1)
                                        }}
                                    >
                                        Weiter
                                    </Button>
                                )}
                                {questionIndex === tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex !== tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            setModalState(true);
                                        }}
                                    >
                                        Fertigstellen
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal show={modalState}>
                <Modal.Header>
                    <Modal.Title>{Test_Passed? "Fachthema erfolgreich abgeschlossen🎉": "Leider nicht bestanden! 😔"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{Test_Passed? "Möchtest du nun dein Wissen zu einem anderen Fachthema austesten?\n" +
                        "Dann drücke \"Zurück zur Homepage.\"" : "Leider hat es dieses Mal beim Ausgangstest nicht geklappt.\n"+
                        "Versuche es doch erneut, nachdem du mit unserem Chatbot ein wenig trainiert hast."
                    }</p>
                    <p>
                        Du kannst uns auch ein Feedback hinterlassen. Es dauert weniger als eine Minute und hilft uns immens.
                        {" "}
                    </p>
                    <p>
                        <Link to="/statistik">
                            Klicke hier um deine Ergebnisse zu sehen 📊
                        </Link>
                    </p>

                    <b>Vielen Dank für deine Teilnahme!</b>
                    <br/>
                    <br/>
                </Modal.Body>
                <Modal.Footer>
                    {!gaveFeedback ? (
                        <>
                            <Button variant="secondary" onClick={() => goToHomepage()}>
                                Zurück zur Homepage
                            </Button>
                            <Button variant="primary" onClick={() => goToFeedback()}>
                                Feedback
                            </Button>
                        </>
                    ) : (
                        <Button variant="primary" onClick={() => goToHomepage()}>
                            Zurück zur Homepage
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal show={modalValidationState}>
                <Modal.Header>
                    <Modal.Title>Abschlusstest abgeschlossen!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        Du hast {percentage}% von 100% im Abschlusstest erreicht.
                    </h5>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button variant="secondary" onClick={() => {
                            setModalValidationState(false);
                            setModalState(true);
                        }}>
                            Überspringen
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setShowValidation(true);
                            setModalValidationState(false);
                        }}>
                            Zur Übersicht
                        </Button>
                    </>
                </Modal.Footer>
            </Modal>

        </div>
    );

    function renderAnswers(answer: Answer) {
        return (
            <div key={`default-radio-${answer.answer_id}`} className="mb-3">
                <Form.Check
                    type="radio"
                    name={`${answer.question_id}`}
                    id={`${answer.answer_id}`}
                    label={answer.description}
                    onClick={() => {
                        changeHandler(answer.answer_id, questionIndex, answer.question_id);
                        validateAnswers(answer.answer_id, questionIndex, answer.description);
                        if (questionIndex >= nextQuestion) {
                            setNextQuestion(questionIndex + 1);
                        }
                    }}
                />
            </div>
        )
    }

    function renderQuestion(question: Test, index: number) {
        return (
            <Tab title="" eventKey={String(index)}>
                <div className="container d-flex mb-5 mt-4">
                    <h4 className="text-center flex-grow-1" style={{hyphens:"auto"}}>{question.description}</h4>
                    <h4 className="text-center font-weight-bold ml-3">{questionIndex + 1}/{tests.length}</h4>
                </div>
                <Form>
                    {question.answers.map((answer) => {
                        return (renderAnswers(answer))
                    })}
                </Form>
            </Tab>
        )
    }

    function renderQuestionValidation(question: Test, index: number) {
        return (
            <Tab title="" eventKey={String(index)}>
                <div className="container d-flex mb-5 mt-4">
                    <h4 className="text-center flex-grow-1" style={{hyphens:"auto"}}>{question.description}</h4>
                    <h4 className="text-center font-weight-bold ml-3">{questionIndex + 1}/{tests.length}</h4>
                </div>
                <Form>
                    {question.answers.map((answer) => {
                        return (renderAnswerValidation(answer, question.right_answer, index))
                    })}
                </Form>
            </Tab>
        )
    }

    function renderAnswerValidation(answer: Answer, rightAnswer: number, index: number) {
        let selectedAnswer = testReview[index][0];
        let check = false;
        let color;
        if (selectedAnswer === answer.description) {
            check = true;
        }

        if (answer.answer_id === rightAnswer) {
            color = "blue";
        } else {
            color = "red";
        }

        return (
            <div className="container d-flex">
                <div className="text-center">
                    {color == "blue" && (
                        <Check/>
                    )}
                    {color == "red" && (
                        <X/>
                    )}
                </div>
                <div key={`default-radio-${answer.answer_id}`} className="mb-3">
                    <Form.Check type="radio" id={`${answer.answer_id}` + 1000}>
                        <Form.Check.Input type="radio" disabled checked={check}/>
                        <Form.Check.Label
                            style={{color: `${color}`}}
                        >{answer.description}</Form.Check.Label>
                    </Form.Check>
                </div>
            </div>

        )
    }

    function validateAnswers(answerId: number, indexQuestion: number, answerDescription: string) {
        const values = [...radio];
        values[indexQuestion] = answerId;

        if (tests[indexQuestion].right_answer === answerId) {
            testReview[indexQuestion] = [answerDescription, true];
        } else {
            testReview[indexQuestion] = [answerDescription, false];
        }
        setQuestionAnswer(values);
        validateCorrectAnswers();
    }

    function validateCorrectAnswers() {
        let counter = 0;
        {
            testReview.map((question) => {
                if (question[1] == true) {
                    counter++;
                }
            })
        }
        setCorrectAnswers(counter);
    }
};
