import React, { useContext, useEffect, useState } from "react";
import {Button, Form} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { categoryContext } from "../contexts/CategoryContext";
import { userContext } from "../contexts/UserContext";
import { Category } from "../models/Category";
import "../styles.css";
import axios from "axios";
import {AppSettings} from "../config";
import {UserStatus} from "../models/User";

type TParams = { id: string };

export type Self = {
  user_id: number;
  category_id: number;
  value: number;
};

export const SelfPage = ({ match }: RouteComponentProps<TParams>) => {
  const user_context = useContext(userContext);
  const category_context = useContext(categoryContext);
  const [categories, setCategory] = useState<Category[]>(
    category_context.categories
  );
  const [category] = useState(Number(match.params.id));
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [Slider, setSlider] = useState(0);
  const context = useContext(userContext);
  const [userStatus, setUserStatus] = useState<UserStatus[]>(context.status);
  const [categoryImageSource] = useState(() => {
        let src = "/images/topics";
        userStatus.forEach((entry) => {
            if (Number(match.params.id) === entry.category.category_id) {
                src += entry.category.image
            }
        });
        return src;
  });


    useEffect(() => {
    if (categories !== null) {
      setCategory((categories: Category[]) =>
        categories.filter(
          (category) => Number(category.category_id) === Number(match.params.id)
        )
      );
    }
  }, [match.params.id]);

  const onSubmitHandler = (event: any, selfData: Self) => {
    event.preventDefault();
  
    setLoading(true);
    axios
      .post(AppSettings.SELF_URL, selfData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user_context.actions.authHeader(),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          history.push("/eingangstest/" + category);
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  return (
      <div className="d-flex align-items-center customHeight " style={{backgroundColor: "#FFFFFF"}}>
        <div className="container  align-items-center justify-content-center selfAssessmentContainer box-blur" style={{backgroundColor: "#FFFFFF"}}>
            <Form>
              <Form.Group>
                  <h2 className="text-center ">Wissensstand (Selbsteinschätzung)</h2>
                  <p className="lead text-muted text-center">Bitte gib eine Selbsteinschätzung deines Wissensstandes ab</p>
                  <h4 className="text-center mt-4">{categories[0].category_name}</h4>
                  <div className={"topic-image-container"}>
                      <img
                          className="card-img-top img-thumbnail topic-selfassessment-image mb-3"
                          src={categoryImageSource}
                          alt={categoryImageSource}
                      />
                  </div>


                  <div className="d-flex justify-content-center mb-4">
                  <p className="p-2 mr-2">kein Vorwissen [0]</p>
                  <RangeSlider
                      tooltipPlacement="bottom"
                      min={0}
                      max={5}
                      value={Slider}
                      variant="primary"
                      size="lg"
                      className="align-middle"
                      onChange={(e) => setSlider(Number(e.target.value))}
                  />
                  <p className="p-2 ml-2">Experte [5]</p>
                </div>
          </Form.Group>
              <Button
                  className="mb-2 mt-2"
                  variant="primary"
                  size="lg"
                  block
                  onClick={(e: any) =>
                      onSubmitHandler(e, {
                        user_id: user_context.user?.user_id!,
                        category_id: category,
                        value: Slider,
                      })
                  }
              >
                Selbsteinschätzung übermitteln
                {isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm ml-5"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    <span/>
                )}
              </Button>

          </Form>
        </div>
      </div>
  )
};
