// Prod/Stage/Local/Env-environment via docker
class AppSettingsDocker {
	public static BASE_URL = process.env.REACT_APP_BASE_URL;
	public static AFTER_FINISH_URL = process.env.REACT_APP_BASE_URL;
	public static AUTH_REGISTER_URL = process.env.REACT_APP_BASE_URL + "/api/register";
	public static CHECK_USERNAME_AVAILABILITY_URL = process.env.REACT_APP_BASE_URL + "/api/checkUsernameAvailability";
	public static CHECK_SECRET_KEY_URL = process.env.REACT_APP_BASE_URL + "/api/checkSecretKey";
	public static AUTH_LOGIN_URL = process.env.REACT_APP_BASE_URL + "/api/login";
	public static AUTH_LOGOUT_URL = process.env.REACT_APP_BASE_URL + "/api/logout";
	public static AUTH_CURRENT_URL = process.env.REACT_APP_BASE_URL + "/api/me";
	public static USER_DATA = process.env.REACT_APP_BASE_URL + "/api/user/";
	public static USER_EMAIL = process.env.REACT_APP_BASE_URL + "/api/user/email";
	public static USER_NAME = process.env.REACT_APP_BASE_URL + "/api/user/name";
	public static USER_PASSWORD = process.env.REACT_APP_BASE_URL + "/api/user/password";
	public static CATEGORY_URL = process.env.REACT_APP_BASE_URL + "/api/category";
	public static SELF_URL = process.env.REACT_APP_BASE_URL + "/api/self/";
	public static TEST_URL = process.env.REACT_APP_BASE_URL + "/api/Test/";
	public static QUESTION_URL = process.env.REACT_APP_BASE_URL + "/api/question/";
	public static ANSWER_URL = process.env.REACT_APP_BASE_URL + "/api/answer";
	public static USER_STATUS = process.env.REACT_APP_BASE_URL + "/api/user/status/";
	public static ADMIN_STATS = process.env.REACT_APP_BASE_URL + "/api/stats/";
	public static USER_STATS = process.env.REACT_APP_BASE_URL + "/api/stats/";
	public static FEEDBACK = process.env.REACT_APP_BASE_URL + "/api/feedback/";
	public static FEEDBACK_QUESTIONS = process.env.REACT_APP_BASE_URL + "/api/feedback/getQuestionnaire/";
	public static FEEDBACK_STATS = process.env.REACT_APP_BASE_URL + "/api/statsfeedback/";
	public static QUIZ_SCORETRACKER_URL = process.env.REACT_APP_BASE_URL + "/api/score/";
	public static HIGH_SCORE_URL = process.env.REACT_APP_BASE_URL + "/api/highscore/";
	public static USER_PERSONALIZATION = process.env.REACT_APP_BASE_URL + "/api/personalization/";
}

// local development e.g. XAMPP
class AppSettingsLocal {
	public static BASE_URL = "http://localhost:4000";
	public static AFTER_FINISH_URL = "http://localhost:3000/";
	public static AUTH_REGISTER_URL = AppSettingsLocal.BASE_URL + "/api/register";
	public static CHECK_USERNAME_AVAILABILITY_URL = AppSettingsLocal.BASE_URL + "/api/checkUsernameAvailability";
	public static CHECK_SECRET_KEY_URL = AppSettingsLocal.BASE_URL + "/api/checkSecretKey";
	public static AUTH_LOGIN_URL = AppSettingsLocal.BASE_URL + "/api/login";
	public static AUTH_LOGOUT_URL = AppSettingsLocal.BASE_URL + "/api/logout";
	public static AUTH_CURRENT_URL = AppSettingsLocal.BASE_URL + "/api/me";
	public static USER_DATA = AppSettingsLocal.BASE_URL + "/api/user/";
	public static USER_EMAIL = AppSettingsLocal.BASE_URL + "/api/user/email";
	public static USER_NAME = AppSettingsLocal.BASE_URL + "/api/user/name";
	public static USER_PASSWORD = AppSettingsLocal.BASE_URL + "/api/user/password";
	public static CATEGORY_URL = AppSettingsLocal.BASE_URL + "/api/category";
	public static SELF_URL = AppSettingsLocal.BASE_URL + "/api/self/";
	public static TEST_URL = AppSettingsLocal.BASE_URL + "/api/Test/";
	public static QUESTION_URL = AppSettingsLocal.BASE_URL + "/api/question/";
	public static ANSWER_URL = AppSettingsLocal.BASE_URL + "/api/answer";
	public static USER_STATUS = AppSettingsLocal.BASE_URL + "/api/user/status/";
	public static ADMIN_STATS = AppSettingsLocal.BASE_URL + "/api/stats/";
	public static USER_STATS = AppSettingsLocal.BASE_URL + "/api/stats/";
	public static FEEDBACK = AppSettingsLocal.BASE_URL + "/api/feedback/";
	public static FEEDBACK_QUESTIONS = AppSettingsLocal.BASE_URL + "/api/feedback/getQuestionnaire/";
	public static FEEDBACK_STATS = AppSettingsLocal.BASE_URL + "/api/statsfeedback/";
	public static QUIZ_SCORETRACKER_URL = AppSettingsLocal.BASE_URL + "/api/score/";
	public static HIGH_SCORE_URL = AppSettingsLocal.BASE_URL + "/api/highscore/";
	public static USER_PERSONALIZATION = AppSettingsLocal.BASE_URL + "/api/personalization/";
}

export let AppSettings: {
	new(): AppSettingsDocker;
	new(): AppSettingsLocal;
	BASE_URL?: string;
	AFTER_FINISH_URL: any;
	CHECK_USERNAME_AVAILABILITY_URL: any;
	CHECK_SECRET_KEY_URL: any;
	AUTH_REGISTER_URL: any;
	AUTH_LOGIN_URL: any;
	AUTH_LOGOUT_URL: any;
	AUTH_CURRENT_URL: any;
	USER_DATA: any;
	USER_EMAIL: any;
	USER_NAME: any;
	USER_PASSWORD: any;
	CATEGORY_URL: any;
	SELF_URL: any;
	TEST_URL: any;
	QUESTION_URL: any;
	ANSWER_URL: any;
	USER_STATUS: any;
	ADMIN_STATS: any;
	USER_STATS: any;
	FEEDBACK: any;
	FEEDBACK_QUESTIONS: any;
	FEEDBACK_STATS: any;
	QUIZ_SCORETRACKER_URL: string;
	HIGH_SCORE_URL: string;
	prototype?: any;
	USER_PERSONALIZATION: any;
};

// Setting env variables
// Prod/Stage/Local/Env-environment via docker
if (process.env.REACT_APP_RUN_ENV === "docker") {
	AppSettings = AppSettingsDocker;
}

// Setting for local development e.g. XAMPP
else {
	AppSettings = AppSettingsLocal;
}