export const cyberkriminalitaet = `
# Cyber- bzw. Computerkriminalität
## <br> Definition
- Cyberkriminalität
- Computerkriminalität
## <br> Vorgehensweise
## <br> Betroffene
## <br> Strafrecht
- erlaubt/legal
- nicht erlaubt/illegal
## <br> Schutz
- Sicherheitsupdates
- Virenschutz
- Verhalten im Internet
`;