import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Navbar from "./components/Navbar";
import {HomePage} from "./pages/HomePage";
import {AnswerPage} from "./pages/AnswerPage";
import {AnswerProvider} from "./contexts/AnswerContext";
import {QuestionProvider} from "./contexts/QuestionContext";
import {QuestionPage} from "./pages/QuestionPage";
import {CategoryProvider} from "./contexts/CategoryContext";
import {AnswersByQuestion} from "./pages/AnswersByQuestion";
import {Signup} from "./pages/Signup";
import {SignIn} from "./pages/SignIn";
import {LearningPage} from "./pages/LearningPage";
import {UserProvider} from "./contexts/UserContext";
import {CategoryPage} from "./pages/CategoryPage";
import {SelectionPage} from "./pages/SelectionPage";
import {StatisticPage} from "./pages/StatisticPage";
import {SelfPage} from "./pages/SelfPage";
import {EntryTestPage} from "./pages/EntryTestPage";
import {LearningEntryPage} from "./pages/LearningEntryPage";
import {InterviewedPage} from "./pages/InterviewedPage";
import {FinishTestPage} from "./pages/FinishTestPage";
import {UmfrageOnline} from "./pages/OnlineUmfragePage";
import {UsersPage} from "./pages/UserPage";
import {SettingsPage} from "./pages/SettingsPage";
import {FreeAnswerInterviewPage} from "./pages/FreeAnswerInterviewPage";
import {StatusProvider} from "./contexts/StatusContext";
import { QuizPage } from "./pages/QuizPage";
import {QuizContextProvider} from "./contexts/QuizContext";
import { PersonalizationPage } from "./pages/PersonalizationPage";

export const App: React.FC = () => {
    return (
        //<React.StrictMode>
        <Router>
            <UserProvider>
                <StatusProvider>
                    <CategoryProvider>
                        <Navbar/>
                        <Route exact path="/" component={HomePage}/>
                        <PrivateRoute path="/einstellungen" component={SettingsPage} exact/>

                        <QuestionProvider>
                            <CategoryProvider>
                                <PrivateRoute path="/fragen" component={QuestionPage} exact/>
                                <PrivateRoute path="/kategorien" component={CategoryPage} exact/>
                                <PrivateRoute path="/themen" component={SelectionPage} exact/>

                                <Route path="/registrieren" component={Signup}/>

                                <PrivateRoute path="/selbsttest/:id" component={SelfPage} exact/>
                                <PrivateRoute path="/eingangstest/:id" component={EntryTestPage} exact/>
                            </CategoryProvider>
                        </QuestionProvider>
                        <AnswerProvider>
                          <PrivateRoute path="/antworten" component={AnswerPage} exact/>
                          <PrivateRoute path="/statistik" component={StatisticPage} exact/>
                          <QuizContextProvider>
                            <PrivateRoute path="/quiz" component={QuizPage} exact />
                          </QuizContextProvider>
                          <PrivateRoute path="/benutzer" component={UsersPage} exact/>
                          <PrivateRoute path="/antworten/:id" component={AnswersByQuestion} exact/>
                        </AnswerProvider>
                        <PrivateRoute path="/fragender/:id" component={LearningPage} exact/>
                        <PrivateRoute path="/befragter/:id" component={InterviewedPage} exact/>
                        <PrivateRoute path="/freieAntworten/:id" component={FreeAnswerInterviewPage} exact/>
                        <Route path="/login" component={SignIn}/>
                        <Route path="/OnlineUmfrage" component={UmfrageOnline}/>
                        <PrivateRoute path="/lernmethode/:id" component={LearningEntryPage} exact/>
                        <PrivateRoute path="/abschlusstest/:id" component={FinishTestPage} exact/>
                        <PrivateRoute path="/personalisierung" component={PersonalizationPage} exact/>
                    </CategoryProvider>
                </StatusProvider>
            </UserProvider>
        </Router>
        // </React.StrictMode>
    );
};

export default App;
