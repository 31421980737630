// eslint-disable-next-line one-var
// Sequential
export const
	YlGn3 = ['#f7fcb9', '#addd8e', '#31a354'];
export const
	YlGn4 = ['#ffffcc', '#c2e699', '#78c679', '#238443'];
export const
	YlGn5 = ['#ffffcc', '#c2e699', '#78c679', '#31a354', '#006837'];
export const
	YlGn6 = ['#ffffcc', '#d9f0a3', '#addd8e', '#78c679', '#31a354', '#006837'];
export const
	YlGn7 = ['#ffffcc', '#d9f0a3', '#addd8e', '#78c679', '#41ab5d', '#238443', '#005a32'];
export const
	YlGn8 = ['#ffffe5', '#f7fcb9', '#d9f0a3', '#addd8e', '#78c679', '#41ab5d', '#238443', '#005a32'];
export const
	YlGn9 = ['#ffffe5', '#f7fcb9', '#d9f0a3', '#addd8e', '#78c679', '#41ab5d', '#238443', '#006837', '#004529'];
export const
	YlGnBu3 = ['#edf8b1', '#7fcdbb', '#2c7fb8'];
export const
	YlGnBu4 = ['#ffffcc', '#a1dab4', '#41b6c4', '#225ea8'];
export const
	YlGnBu5 = ['#ffffcc', '#a1dab4', '#41b6c4', '#2c7fb8', '#253494'];
export const
	YlGnBu6 = ['#ffffcc', '#c7e9b4', '#7fcdbb', '#41b6c4', '#2c7fb8', '#253494'];
export const
	YlGnBu7 = ['#ffffcc', '#c7e9b4', '#7fcdbb', '#41b6c4', '#1d91c0', '#225ea8', '#0c2c84'];
export const
	YlGnBu8 = ['#ffffd9', '#edf8b1', '#c7e9b4', '#7fcdbb', '#41b6c4', '#1d91c0', '#225ea8', '#0c2c84'];
export const
	YlGnBu9 = ['#ffffd9', '#edf8b1', '#c7e9b4', '#7fcdbb', '#41b6c4', '#1d91c0', '#225ea8', '#253494', '#081d58'];
export const
	GnBu3 = ['#e0f3db', '#a8ddb5', '#43a2ca'];
export const
	GnBu4 = ['#f0f9e8', '#bae4bc', '#7bccc4', '#2b8cbe'];
export const
	GnBu5 = ['#f0f9e8', '#bae4bc', '#7bccc4', '#43a2ca', '#0868ac'];
export const
	GnBu6 = ['#f0f9e8', '#ccebc5', '#a8ddb5', '#7bccc4', '#43a2ca', '#0868ac'];
export const
	GnBu7 = ['#f0f9e8', '#ccebc5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e'];
export const
	GnBu8 = ['#f7fcf0', '#e0f3db', '#ccebc5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e'];
export const
	GnBu9 = ['#f7fcf0', '#e0f3db', '#ccebc5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#0868ac', '#084081'];
export const
	BuGn3 = ['#e5f5f9', '#99d8c9', '#2ca25f'];
export const
	BuGn4 = ['#edf8fb', '#b2e2e2', '#66c2a4', '#238b45'];
export const
	BuGn5 = ['#edf8fb', '#b2e2e2', '#66c2a4', '#2ca25f', '#006d2c'];
export const
	BuGn6 = ['#edf8fb', '#ccece6', '#99d8c9', '#66c2a4', '#2ca25f', '#006d2c'];
export const
	BuGn7 = ['#edf8fb', '#ccece6', '#99d8c9', '#66c2a4', '#41ae76', '#238b45', '#005824'];
export const
	BuGn8 = ['#f7fcfd', '#e5f5f9', '#ccece6', '#99d8c9', '#66c2a4', '#41ae76', '#238b45', '#005824'];
export const
	BuGn9 = ['#f7fcfd', '#e5f5f9', '#ccece6', '#99d8c9', '#66c2a4', '#41ae76', '#238b45', '#006d2c', '#00441b'];
export const PuBuGn3 = ['#ece2f0', '#a6bddb', '#1c9099'];
export const PuBuGn4 = ['#f6eff7', '#bdc9e1', '#67a9cf', '#02818a'];
export const PuBuGn5 = ['#f6eff7', '#bdc9e1', '#67a9cf', '#1c9099', '#016c59'];
export const PuBuGn6 = ['#f6eff7', '#d0d1e6', '#a6bddb', '#67a9cf', '#1c9099', '#016c59'];
export const PuBuGn7 = ['#f6eff7', '#d0d1e6', '#a6bddb', '#67a9cf', '#3690c0', '#02818a', '#016450'];
export const PuBuGn8 = ['#fff7fb', '#ece2f0', '#d0d1e6', '#a6bddb', '#67a9cf', '#3690c0', '#02818a', '#016450'];
export const PuBuGn9 = ['#fff7fb', '#ece2f0', '#d0d1e6', '#a6bddb', '#67a9cf', '#3690c0', '#02818a', '#016c59', '#014636'];
export const PuBu3 = ['#ece7f2', '#a6bddb', '#2b8cbe'];
export const PuBu4 = ['#f1eef6', '#bdc9e1', '#74a9cf', '#0570b0'];
export const PuBu5 = ['#f1eef6', '#bdc9e1', '#74a9cf', '#2b8cbe', '#045a8d'];
export const PuBu6 = ['#f1eef6', '#d0d1e6', '#a6bddb', '#74a9cf', '#2b8cbe', '#045a8d'];
export const PuBu7 = ['#f1eef6', '#d0d1e6', '#a6bddb', '#74a9cf', '#3690c0', '#0570b0', '#034e7b'];
export const PuBu8 = ['#fff7fb', '#ece7f2', '#d0d1e6', '#a6bddb', '#74a9cf', '#3690c0', '#0570b0', '#034e7b'];
export const PuBu9 = ['#fff7fb', '#ece7f2', '#d0d1e6', '#a6bddb', '#74a9cf', '#3690c0', '#0570b0', '#045a8d', '#023858'];
export const BuPu3 = ['#e0ecf4', '#9ebcda', '#8856a7'];
export const BuPu4 = ['#edf8fb', '#b3cde3', '#8c96c6', '#88419d'];
export const BuPu5 = ['#edf8fb', '#b3cde3', '#8c96c6', '#8856a7', '#810f7c'];
export const BuPu6 = ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'];
export const BuPu7 = ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8c6bb1', '#88419d', '#6e016b'];
export const BuPu8 = ['#f7fcfd', '#e0ecf4', '#bfd3e6', '#9ebcda', '#8c96c6', '#8c6bb1', '#88419d', '#6e016b'];
export const BuPu9 = ['#f7fcfd', '#e0ecf4', '#bfd3e6', '#9ebcda', '#8c96c6', '#8c6bb1', '#88419d', '#810f7c', '#4d004b'];
export const RdPu3 = ['#fde0dd', '#fa9fb5', '#c51b8a'];
export const RdPu4 = ['#feebe2', '#fbb4b9', '#f768a1', '#ae017e'];
export const RdPu5 = ['#feebe2', '#fbb4b9', '#f768a1', '#c51b8a', '#7a0177'];
export const RdPu6 = ['#feebe2', '#fcc5c0', '#fa9fb5', '#f768a1', '#c51b8a', '#7a0177'];
export const RdPu7 = ['#feebe2', '#fcc5c0', '#fa9fb5', '#f768a1', '#dd3497', '#ae017e', '#7a0177'];
export const RdPu8 = ['#fff7f3', '#fde0dd', '#fcc5c0', '#fa9fb5', '#f768a1', '#dd3497', '#ae017e', '#7a0177'];
export const RdPu9 = ['#fff7f3', '#fde0dd', '#fcc5c0', '#fa9fb5', '#f768a1', '#dd3497', '#ae017e', '#7a0177', '#49006a'];
export const PuRd3 = ['#e7e1ef', '#c994c7', '#dd1c77'];
export const PuRd4 = ['#f1eef6', '#d7b5d8', '#df65b0', '#ce1256'];
export const PuRd5 = ['#f1eef6', '#d7b5d8', '#df65b0', '#dd1c77', '#980043'];
export const PuRd6 = ['#f1eef6', '#d4b9da', '#c994c7', '#df65b0', '#dd1c77', '#980043'];
export const PuRd7 = ['#f1eef6', '#d4b9da', '#c994c7', '#df65b0', '#e7298a', '#ce1256', '#91003f'];
export const PuRd8 = ['#f7f4f9', '#e7e1ef', '#d4b9da', '#c994c7', '#df65b0', '#e7298a', '#ce1256', '#91003f'];
export const PuRd9 = ['#f7f4f9', '#e7e1ef', '#d4b9da', '#c994c7', '#df65b0', '#e7298a', '#ce1256', '#980043', '#67001f'];
export const OrRd3 = ['#fee8c8', '#fdbb84', '#e34a33'];
export const OrRd4 = ['#fef0d9', '#fdcc8a', '#fc8d59', '#d7301f'];
export const OrRd5 = ['#fef0d9', '#fdcc8a', '#fc8d59', '#e34a33', '#b30000'];
export const OrRd6 = ['#fef0d9', '#fdd49e', '#fdbb84', '#fc8d59', '#e34a33', '#b30000'];
export const OrRd7 = ['#fef0d9', '#fdd49e', '#fdbb84', '#fc8d59', '#ef6548', '#d7301f', '#990000'];
export const OrRd8 = ['#fff7ec', '#fee8c8', '#fdd49e', '#fdbb84', '#fc8d59', '#ef6548', '#d7301f', '#990000'];
export const OrRd9 = ['#fff7ec', '#fee8c8', '#fdd49e', '#fdbb84', '#fc8d59', '#ef6548', '#d7301f', '#b30000', '#7f0000'];
export const YlOrRd3 = ['#ffeda0', '#feb24c', '#f03b20'];
export const YlOrRd4 = ['#ffffb2', '#fecc5c', '#fd8d3c', '#e31a1c'];
export const YlOrRd5 = ['#ffffb2', '#fecc5c', '#fd8d3c', '#f03b20', '#bd0026'];
export const YlOrRd6 = ['#ffffb2', '#fed976', '#feb24c', '#fd8d3c', '#f03b20', '#bd0026'];
export const YlOrRd7 = ['#ffffb2', '#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c', '#b10026'];
export const YlOrRd8 = ['#ffffcc', '#ffeda0', '#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c', '#b10026'];
export const YlOrRd9 = ['#ffffcc', '#ffeda0', '#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c', '#bd0026', '#800026'];
export const YlOrBr3 = ['#fff7bc', '#fec44f', '#d95f0e'];
export const YlOrBr4 = ['#ffffd4', '#fed98e', '#fe9929', '#cc4c02'];
export const YlOrBr5 = ['#ffffd4', '#fed98e', '#fe9929', '#d95f0e', '#993404'];
export const YlOrBr6 = ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'];
export const YlOrBr7 = ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#ec7014', '#cc4c02', '#8c2d04'];
export const YlOrBr8 = ['#ffffe5', '#fff7bc', '#fee391', '#fec44f', '#fe9929', '#ec7014', '#cc4c02', '#8c2d04'];
export const YlOrBr9 = ['#ffffe5', '#fff7bc', '#fee391', '#fec44f', '#fe9929', '#ec7014', '#cc4c02', '#993404', '#662506'];
export const Purples3 = ['#efedf5', '#bcbddc', '#756bb1'];
export const Purples4 = ['#f2f0f7', '#cbc9e2', '#9e9ac8', '#6a51a3'];
export const Purples5 = ['#f2f0f7', '#cbc9e2', '#9e9ac8', '#756bb1', '#54278f'];
export const Purples6 = ['#f2f0f7', '#dadaeb', '#bcbddc', '#9e9ac8', '#756bb1', '#54278f'];
export const Purples7 = ['#f2f0f7', '#dadaeb', '#bcbddc', '#9e9ac8', '#807dba', '#6a51a3', '#4a1486'];
export const Purples8 = ['#fcfbfd', '#efedf5', '#dadaeb', '#bcbddc', '#9e9ac8', '#807dba', '#6a51a3', '#4a1486'];
export const Purples9 = ['#fcfbfd', '#efedf5', '#dadaeb', '#bcbddc', '#9e9ac8', '#807dba', '#6a51a3', '#54278f', '#3f007d'];
export const Blues3 = ['#deebf7', '#9ecae1', '#3182bd'];
export const Blues4 = ['#eff3ff', '#bdd7e7', '#6baed6', '#2171b5'];
export const Blues5 = ['#eff3ff', '#bdd7e7', '#6baed6', '#3182bd', '#08519c'];
export const Blues6 = ['#eff3ff', '#c6dbef', '#9ecae1', '#6baed6', '#3182bd', '#08519c'];
export const Blues7 = ['#eff3ff', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#084594'];
export const Blues8 = ['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#084594'];
export const Blues9 = ['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#08519c', '#08306b'];
export const Greens3 = ['#e5f5e0', '#a1d99b', '#31a354'];
export const Greens4 = ['#edf8e9', '#bae4b3', '#74c476', '#238b45'];
export const Greens5 = ['#edf8e9', '#bae4b3', '#74c476', '#31a354', '#006d2c'];
export const Greens6 = ['#edf8e9', '#c7e9c0', '#a1d99b', '#74c476', '#31a354', '#006d2c'];
export const Greens7 = ['#edf8e9', '#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#238b45', '#005a32'];
export const Greens8 = ['#f7fcf5', '#e5f5e0', '#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#238b45', '#005a32'];
export const Greens9 = ['#f7fcf5', '#e5f5e0', '#c7e9c0', '#a1d99b', '#74c476', '#41ab5d', '#238b45', '#006d2c', '#00441b'];
export const Oranges3 = ['#fee6ce', '#fdae6b', '#e6550d'];
export const Oranges4 = ['#feedde', '#fdbe85', '#fd8d3c', '#d94701'];
export const Oranges5 = ['#feedde', '#fdbe85', '#fd8d3c', '#e6550d', '#a63603'];
export const Oranges6 = ['#feedde', '#fdd0a2', '#fdae6b', '#fd8d3c', '#e6550d', '#a63603'];
export const Oranges7 = ['#feedde', '#fdd0a2', '#fdae6b', '#fd8d3c', '#f16913', '#d94801', '#8c2d04'];
export const Oranges8 = ['#fff5eb', '#fee6ce', '#fdd0a2', '#fdae6b', '#fd8d3c', '#f16913', '#d94801', '#8c2d04'];
export const Oranges9 = ['#fff5eb', '#fee6ce', '#fdd0a2', '#fdae6b', '#fd8d3c', '#f16913', '#d94801', '#a63603', '#7f2704'];
export const Reds3 = ['#fee0d2', '#fc9272', '#de2d26'];
export const Reds4 = ['#fee5d9', '#fcae91', '#fb6a4a', '#cb181d'];
export const Reds5 = ['#fee5d9', '#fcae91', '#fb6a4a', '#de2d26', '#a50f15'];
export const Reds6 = ['#fee5d9', '#fcbba1', '#fc9272', '#fb6a4a', '#de2d26', '#a50f15'];
export const Reds7 = ['#fee5d9', '#fcbba1', '#fc9272', '#fb6a4a', '#ef3b2c', '#cb181d', '#99000d'];
export const Reds8 = ['#fff5f0', '#fee0d2', '#fcbba1', '#fc9272', '#fb6a4a', '#ef3b2c', '#cb181d', '#99000d'];
export const Reds9 = ['#fff5f0', '#fee0d2', '#fcbba1', '#fc9272', '#fb6a4a', '#ef3b2c', '#cb181d', '#a50f15', '#67000d'];
export const Greys3 = ['#f0f0f0', '#bdbdbd', '#636363'];
export const Greys4 = ['#f7f7f7', '#cccccc', '#969696', '#525252'];
export const Greys5 = ['#f7f7f7', '#cccccc', '#969696', '#636363', '#252525'];
export const Greys6 = ['#f7f7f7', '#d9d9d9', '#bdbdbd', '#969696', '#636363', '#252525'];
export const Greys7 = ['#f7f7f7', '#d9d9d9', '#bdbdbd', '#969696', '#737373', '#525252', '#252525'];
export const Greys8 = ['#ffffff', '#f0f0f0', '#d9d9d9', '#bdbdbd', '#969696', '#737373', '#525252', '#252525'];
export const Greys9 = ['#ffffff', '#f0f0f0', '#d9d9d9', '#bdbdbd', '#969696', '#737373', '#525252', '#252525', '#000000'];
// Diverging
export const PuOr3 = ['#f1a340', '#f7f7f7', '#998ec3'];
export const PuOr4 = ['#e66101', '#fdb863', '#b2abd2', '#5e3c99'];
export const PuOr5 = ['#e66101', '#fdb863', '#f7f7f7', '#b2abd2', '#5e3c99'];
export const PuOr6 = ['#b35806', '#f1a340', '#fee0b6', '#d8daeb', '#998ec3', '#542788'];
export const PuOr7 = ['#b35806', '#f1a340', '#fee0b6', '#f7f7f7', '#d8daeb', '#998ec3', '#542788'];
export const PuOr8 = ['#b35806', '#e08214', '#fdb863', '#fee0b6', '#d8daeb', '#b2abd2', '#8073ac', '#542788'];
export const PuOr9 = ['#b35806', '#e08214', '#fdb863', '#fee0b6', '#f7f7f7', '#d8daeb', '#b2abd2', '#8073ac', '#542788'];
export const PuOr10 = ['#7f3b08', '#b35806', '#e08214', '#fdb863', '#fee0b6', '#d8daeb', '#b2abd2', '#8073ac', '#542788', '#2d004b'];
export const PuOr11 = ['#7f3b08', '#b35806', '#e08214', '#fdb863', '#fee0b6', '#f7f7f7', '#d8daeb', '#b2abd2', '#8073ac', '#542788', '#2d004b'];
export const BrBG3 = ['#d8b365', '#f5f5f5', '#5ab4ac'];
export const BrBG4 = ['#a6611a', '#dfc27d', '#80cdc1', '#018571'];
export const BrBG5 = ['#a6611a', '#dfc27d', '#f5f5f5', '#80cdc1', '#018571'];
export const BrBG6 = ['#8c510a', '#d8b365', '#f6e8c3', '#c7eae5', '#5ab4ac', '#01665e'];
export const BrBG7 = ['#8c510a', '#d8b365', '#f6e8c3', '#f5f5f5', '#c7eae5', '#5ab4ac', '#01665e'];
export const BrBG8 = ['#8c510a', '#bf812d', '#dfc27d', '#f6e8c3', '#c7eae5', '#80cdc1', '#35978f', '#01665e'];
export const BrBG9 = ['#8c510a', '#bf812d', '#dfc27d', '#f6e8c3', '#f5f5f5', '#c7eae5', '#80cdc1', '#35978f', '#01665e'];
export const BrBG10 = ['#543005', '#8c510a', '#bf812d', '#dfc27d', '#f6e8c3', '#c7eae5', '#80cdc1', '#35978f', '#01665e', '#003c30'];
export const BrBG11 = ['#543005', '#8c510a', '#bf812d', '#dfc27d', '#f6e8c3', '#f5f5f5', '#c7eae5', '#80cdc1', '#35978f', '#01665e', '#003c30'];
export const PRGn3 = ['#af8dc3', '#f7f7f7', '#7fbf7b'];
export const PRGn4 = ['#7b3294', '#c2a5cf', '#a6dba0', '#008837'];
export const PRGn5 = ['#7b3294', '#c2a5cf', '#f7f7f7', '#a6dba0', '#008837'];
export const PRGn6 = ['#762a83', '#af8dc3', '#e7d4e8', '#d9f0d3', '#7fbf7b', '#1b7837'];
export const PRGn7 = ['#762a83', '#af8dc3', '#e7d4e8', '#f7f7f7', '#d9f0d3', '#7fbf7b', '#1b7837'];
export const PRGn8 = ['#762a83', '#9970ab', '#c2a5cf', '#e7d4e8', '#d9f0d3', '#a6dba0', '#5aae61', '#1b7837'];
export const PRGn9 = ['#762a83', '#9970ab', '#c2a5cf', '#e7d4e8', '#f7f7f7', '#d9f0d3', '#a6dba0', '#5aae61', '#1b7837'];
export const PRGn10 = ['#40004b', '#762a83', '#9970ab', '#c2a5cf', '#e7d4e8', '#d9f0d3', '#a6dba0', '#5aae61', '#1b7837', '#00441b'];
export const PRGn11 = ['#40004b', '#762a83', '#9970ab', '#c2a5cf', '#e7d4e8', '#f7f7f7', '#d9f0d3', '#a6dba0', '#5aae61', '#1b7837', '#00441b'];
export const PiYG3 = ['#e9a3c9', '#f7f7f7', '#a1d76a'];
export const PiYG4 = ['#d01c8b', '#f1b6da', '#b8e186', '#4dac26'];
export const PiYG5 = ['#d01c8b', '#f1b6da', '#f7f7f7', '#b8e186', '#4dac26'];
export const PiYG6 = ['#c51b7d', '#e9a3c9', '#fde0ef', '#e6f5d0', '#a1d76a', '#4d9221'];
export const PiYG7 = ['#c51b7d', '#e9a3c9', '#fde0ef', '#f7f7f7', '#e6f5d0', '#a1d76a', '#4d9221'];
export const PiYG8 = ['#c51b7d', '#de77ae', '#f1b6da', '#fde0ef', '#e6f5d0', '#b8e186', '#7fbc41', '#4d9221'];
export const PiYG9 = ['#c51b7d', '#de77ae', '#f1b6da', '#fde0ef', '#f7f7f7', '#e6f5d0', '#b8e186', '#7fbc41', '#4d9221'];
export const PiYG10 = ['#8e0152', '#c51b7d', '#de77ae', '#f1b6da', '#fde0ef', '#e6f5d0', '#b8e186', '#7fbc41', '#4d9221', '#276419'];
export const PiYG11 = ['#8e0152', '#c51b7d', '#de77ae', '#f1b6da', '#fde0ef', '#f7f7f7', '#e6f5d0', '#b8e186', '#7fbc41', '#4d9221', '#276419'];
export const RdBu3 = ['#ef8a62', '#f7f7f7', '#67a9cf'];
export const RdBu4 = ['#ca0020', '#f4a582', '#92c5de', '#0571b0'];
export const RdBu5 = ['#ca0020', '#f4a582', '#f7f7f7', '#92c5de', '#0571b0'];
export const RdBu6 = ['#b2182b', '#ef8a62', '#fddbc7', '#d1e5f0', '#67a9cf', '#2166ac'];
export const RdBu7 = ['#b2182b', '#ef8a62', '#fddbc7', '#f7f7f7', '#d1e5f0', '#67a9cf', '#2166ac'];
export const RdBu8 = ['#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#d1e5f0', '#92c5de', '#4393c3', '#2166ac'];
export const RdBu9 = ['#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#f7f7f7', '#d1e5f0', '#92c5de', '#4393c3', '#2166ac'];
export const RdBu10 = ['#67001f', '#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#d1e5f0', '#92c5de', '#4393c3', '#2166ac', '#053061'];
export const RdBu11 = ['#67001f', '#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#f7f7f7', '#d1e5f0', '#92c5de', '#4393c3', '#2166ac', '#053061'];
export const RdGy3 = ['#ef8a62', '#ffffff', '#999999'];
export const RdGy4 = ['#ca0020', '#f4a582', '#bababa', '#404040'];
export const RdGy5 = ['#ca0020', '#f4a582', '#ffffff', '#bababa', '#404040'];
export const RdGy6 = ['#b2182b', '#ef8a62', '#fddbc7', '#e0e0e0', '#999999', '#4d4d4d'];
export const RdGy7 = ['#b2182b', '#ef8a62', '#fddbc7', '#ffffff', '#e0e0e0', '#999999', '#4d4d4d'];
export const RdGy8 = ['#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#e0e0e0', '#bababa', '#878787', '#4d4d4d'];
export const RdGy9 = ['#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#ffffff', '#e0e0e0', '#bababa', '#878787', '#4d4d4d'];
export const RdGy10 = ['#67001f', '#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#e0e0e0', '#bababa', '#878787', '#4d4d4d', '#1a1a1a'];
export const RdGy11 = ['#67001f', '#b2182b', '#d6604d', '#f4a582', '#fddbc7', '#ffffff', '#e0e0e0', '#bababa', '#878787', '#4d4d4d', '#1a1a1a'];
export const RdYlBu3 = ['#fc8d59', '#ffffbf', '#91bfdb'];
export const RdYlBu4 = ['#d7191c', '#fdae61', '#abd9e9', '#2c7bb6'];
export const RdYlBu5 = ['#d7191c', '#fdae61', '#ffffbf', '#abd9e9', '#2c7bb6'];
export const RdYlBu6 = ['#d73027', '#fc8d59', '#fee090', '#e0f3f8', '#91bfdb', '#4575b4'];
export const RdYlBu7 = ['#d73027', '#fc8d59', '#fee090', '#ffffbf', '#e0f3f8', '#91bfdb', '#4575b4'];
export const RdYlBu8 = ['#d73027', '#f46d43', '#fdae61', '#fee090', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'];
export const RdYlBu9 = ['#d73027', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4'];
export const RdYlBu10 = ['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee090', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4', '#313695'];
export const RdYlBu11 = ['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee090', '#ffffbf', '#e0f3f8', '#abd9e9', '#74add1', '#4575b4', '#313695'];
export const Spectral3 = ['#fc8d59', '#ffffbf', '#99d594'];
export const Spectral4 = ['#d7191c', '#fdae61', '#abdda4', '#2b83ba'];
export const Spectral5 = ['#d7191c', '#fdae61', '#ffffbf', '#abdda4', '#2b83ba'];
export const Spectral6 = ['#d53e4f', '#fc8d59', '#fee08b', '#e6f598', '#99d594', '#3288bd'];
export const Spectral7 = ['#d53e4f', '#fc8d59', '#fee08b', '#ffffbf', '#e6f598', '#99d594', '#3288bd'];
export const Spectral8 = ['#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#e6f598', '#abdda4', '#66c2a5', '#3288bd'];
export const Spectral9 = ['#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#e6f598', '#abdda4', '#66c2a5', '#3288bd'];
export const Spectral10 = ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2'];
export const Spectral11 = ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2'];
export const RdYlGn3 = ['#fc8d59', '#ffffbf', '#91cf60'];
export const RdYlGn4 = ['#d7191c', '#fdae61', '#a6d96a', '#1a9641'];
export const RdYlGn5 = ['#d7191c', '#fdae61', '#ffffbf', '#a6d96a', '#1a9641'];
export const RdYlGn6 = ['#d73027', '#fc8d59', '#fee08b', '#d9ef8b', '#91cf60', '#1a9850'];
export const RdYlGn7 = ['#d73027', '#fc8d59', '#fee08b', '#ffffbf', '#d9ef8b', '#91cf60', '#1a9850'];
export const RdYlGn8 = ['#d73027', '#f46d43', '#fdae61', '#fee08b', '#d9ef8b', '#a6d96a', '#66bd63', '#1a9850'];
export const RdYlGn9 = ['#d73027', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#d9ef8b', '#a6d96a', '#66bd63', '#1a9850'];
export const RdYlGn10 = ['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee08b', '#d9ef8b', '#a6d96a', '#66bd63', '#1a9850', '#006837'];
export const RdYlGn11 = ['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#d9ef8b', '#a6d96a', '#66bd63', '#1a9850', '#006837'];
// Qualitative
export const Accent3 = ['#7fc97f', '#beaed4', '#fdc086'];
export const Accent4 = ['#7fc97f', '#beaed4', '#fdc086', '#ffff99'];
export const Accent5 = ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#386cb0'];
export const Accent6 = ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#386cb0', '#f0027f'];
export const Accent7 = ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#386cb0', '#f0027f', '#bf5b17'];
export const Accent8 = ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#386cb0', '#f0027f', '#bf5b17', '#666666'];
export const DarkTwo3 = ['#1b9e77', '#d95f02', '#7570b3'];
export const DarkTwo4 = ['#1b9e77', '#d95f02', '#7570b3', '#e7298a'];
export const DarkTwo5 = ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e'];
export const DarkTwo6 = ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02'];
export const DarkTwo7 = ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02', '#a6761d'];
export const DarkTwo8 = ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02', '#a6761d', '#666666'];
export const Paired3 = ['#a6cee3', '#1f78b4', '#b2df8a'];
export const Paired4 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c'];
export const Paired5 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99'];
export const Paired6 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c'];
export const Paired7 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f'];
export const Paired8 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00'];
export const Paired9 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6'];
export const Paired10 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a'];
export const Paired11 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99'];
export const Paired12 = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928'];
export const PastelOne3 = ['#fbb4ae', '#b3cde3', '#ccebc5'];
export const PastelOne4 = ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4'];
export const PastelOne5 = ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6'];
export const PastelOne6 = ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc'];
export const PastelOne7 = ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc', '#e5d8bd'];
export const PastelOne8 = ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc', '#e5d8bd', '#fddaec'];
export const PastelOne9 = ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc', '#e5d8bd', '#fddaec', '#f2f2f2'];
export const PastelTwo3 = ['#b3e2cd', '#fdcdac', '#cbd5e8'];
export const PastelTwo4 = ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4'];
export const PastelTwo5 = ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4', '#e6f5c9'];
export const PastelTwo6 = ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4', '#e6f5c9', '#fff2ae'];
export const PastelTwo7 = ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4', '#e6f5c9', '#fff2ae', '#f1e2cc'];
export const PastelTwo8 = ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4', '#e6f5c9', '#fff2ae', '#f1e2cc', '#cccccc'];
export const PastelTwo12 = ['#fbb4ae', '#fdcdac', '#b3e2cd','#ccebc5', '#cbd5e8', '#f4cae4', '#e6f5c9', '#fff2ae', '#f1e2cc', '#cccccc', '#b3cde3'];
export const SetOne3 = ['#e41a1c', '#377eb8', '#4daf4a'];
export const SetOne4 = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3'];
export const SetOne5 = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00'];
export const SetOne6 = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33'];
export const SetOne7 = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628'];
export const SetOne8 = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf'];
export const SetOne9 = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999'];
export const SetTwo3 = ['#66c2a5', '#fc8d62', '#8da0cb'];
export const SetTwo4 = ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3'];
export const SetTwo5 = ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854'];
export const SetTwo6 = ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f'];
export const SetTwo7 = ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f', '#e5c494'];
export const SetTwo8 = ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f', '#e5c494', '#b3b3b3'];
export const SetThree3 = ['#8dd3c7', '#ffffb3', '#bebada'];
export const SetThree4 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072'];
export const SetThree5 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3'];
export const SetThree6 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462'];
export const SetThree7 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69'];
export const SetThree8 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5'];
export const SetThree9 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9'];
export const SetThree10 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9', '#bc80bd'];
export const SetThree11 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9', '#bc80bd', '#ccebc5'];
export const SetThree12 = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9', '#bc80bd', '#ccebc5', '#ffed6f'];
