import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { userContext } from "../contexts/UserContext";
import {
	Button,
	Card,
	Col,
	Container,
	Row,
	Tab,
	Tabs,
	Tooltip,
	OverlayTrigger,
	Modal,
	Table,
	Badge,
} from "react-bootstrap";
import { AppSettings } from "../config";
import { Bar, Doughnut, Radar } from "react-chartjs-2";
import { UserDiff, FeedBack } from "../models/Stats";
import { InfoCircle } from "react-bootstrap-icons";
import { BsArrowsFullscreen } from "react-icons/bs";
import {
	genderDistro,
	genderData,
	averageImprov,
	ageDistro,
	prePostImprov,
	userRadarChart,
	agePercentage,
	improveRates,
	numberOfTests,
	numberOfTestsPercentage,
	feedbackAverages,
	recommendPercentage,
	completeness,
	userPercentiles,
	userCompleteness,
	averageTimeStats,
	subAboveTimeStats,
	userTimes,
	medians,
	suggestionsStat,
	recommendRate,
	suggestionsStatPercentage,
	testMethod,
	testMethodPercentage,
} from "../helper/ChartHelper";
import { useHistory } from "react-router-dom";
import "../styles.css";

export const StatisticPage: React.FC = () => {
	if (
		window.location.toString() !== AppSettings.AFTER_FINISH_URL + "fragender" ||
		window.location.toString() !== AppSettings.AFTER_FINISH_URL + "befragter"
	) {
		document.querySelector("body > div.WatsonAssistantChatHost")?.remove();
	}

	const context = useContext(userContext);
	const history = useHistory();
	const [userRights] = useState<string>(context.user!.user_rights_id);
	const [userID] = useState<number>(context.user!.user_id);
	const [stats, setStats] = useState([]);
	const [feedbackStats, setFeedbackStats] = useState([]);
	const [averageAge, setAverageAge] = useState(0);
	const [medianAge, setMedianAge] = useState(0);
	const [timeStats, setTimeStats] = useState([]);
	const [averageScores, setAverageScore] = useState<{
		entry_points: number;
		finished_points: number;
		point_difference: number;
	}>({ entry_points: 0, finished_points: 0, point_difference: 0 });
	const [userTimeStats, setuserTimeStats] = useState([]);
	const [userBadges, setUserBadges] = useState([]);
	const [userTestStatus, setUserTestStatus] = useState([]);
	const [passingStats, setPassingStats] = useState([]);
	const [passingStatsIncomplete, setPassingStatsIncomplete] = useState([]);
	const [medianScoresEntry, setmedianScoresEntry] = useState([]);
	const [medianScoresFinish, setmedianScoresFinish] = useState([]);
	const [feedbackStatsAnswers, setFeedbackStatsSliders] = useState([]);
	const [feedbackStatsRec, setFeedbackStatsRec] = useState([]);
	const [feedbackStatsSugg, setFeedbackStatsSugg] = useState([]);
	const [userLeaderboad, setUserLeaderboad] = useState([]);

	const [highscoreLeaderboad, setHighscoreLeaderboad] = useState([]);

	const [feedbackStatsMot, setFeedbackStatsMot] = useState([]);
	const [feedbackStatsTmethod, setFeedbackStatsTmethod] = useState([]);
	const [ageStats, setageStats] = useState([]);
	const [genderStats, setgenderStats] = useState([]);
	const [categoryImprovStats, setcategoryImprovStats] = useState([]);
	const [ageMisc, setAgeMisc] = useState(0);
	const [radarChart, setradarChart] = useState([{
		category_name:'Category',
		entry_points:0,
		finished_points:0,
		priorität:'1'
	}]);
	const [percentile, setPercentile] = useState([]);
	const [insufficientTests, setInsufficientTests] = useState(false);
	const [zoomId, setZoomId] = useState("");
	const [prePostcategoryImprovStats, setprePostcategoryImprovStats] = useState(
		[]
	);
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "bottom" as const,
			},
			/*       title: {
		display: true,
		text: "Chart.js Bar Chart",
	  }, */
		},
	};
	const radarChartOptions = {
		scales: {
			r: {
				suggestedMin: 0,
				//suggestedMax: 100,
			}
		}
	}

	const Baroptions = {
		indexAxis: "y" as const,
		responsive: true,
		plugins: {
			legend: {
				display: false,
				position: "bottom" as const,
			},
			/*       title: {
		display: true,
		text: "Altersgruppen",
	  }, */
		},
	};

	const renderTooltip = (str: string) => (
		<Tooltip id="button-tooltip">{str}</Tooltip>
	);

	const getAllStats = () =>
		axios.get(AppSettings.ADMIN_STATS,{
			headers:{
				Authorization: context.actions.authHeader()
			}
			}).then((res) => res);

	const getUserStatsByID = (userID: number) =>
		axios.get(AppSettings.USER_STATS + userID, {
			headers: {
				Authorization: context.actions.authHeader(),
			},
		}).then((res) => res);

	const getFeedbackStats = () =>
		axios.get(AppSettings.FEEDBACK_STATS).then((res) => res);

	const toggleStatisticZoom = (event:any) => {
		let id = event.currentTarget.id;
		if (zoomId === id) {
			setZoomId("")
		} else {
			setZoomId(id);
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (userRights === "user") {
			getUserStatsByID(userID).then((response) => {
				if (response.data.insufficent_test_results === 1) {
					setInsufficientTests(true);
				} else {
					setInsufficientTests(false);
					setradarChart(response.data.radar_chart_data);
				}
				setPercentile(response.data.percentile);
				setUserTestStatus(response.data.user_test_status);
				setuserTimeStats(response.data.user_time_stats);
				setUserLeaderboad(response.data.leaderboard);
				setUserBadges(response.data.badges);
			});
		} else {
			getAllStats()
				.then((response) => {
					if (response.status === 200 && isMounted) {
						let data = response.data;
						// setUserRights(context.user!.user_rights_id);
						// setUserID(context.user!.user_id);
						let averages = data.average_test_scores_percentage;

						setStats(data);
						setTimeStats(data.times);
						setAverageScore(averages);
						setgenderStats(data.gender_stats);
						setAgeMisc(data.age_stats.all_age);
						setmedianScoresEntry(data.median_scores.entry_points);
						setmedianScoresFinish(data.median_scores.finished_points);
						setageStats(data.age_stats.age_distro);
						setMedianAge(data.age_stats.median_age);
						setAverageAge(data.age_stats.average_age);
						setPassingStats(data.passed_drop.complete);
						// setPassingStatsIncomplete(data.passed_drop.incomplete);
						setcategoryImprovStats(data.category_avg_improvment_rate[0]);
						setprePostcategoryImprovStats(data.category_avg_improvment_rate[1]);
					}
				})
				.catch((err) => console.log(err));

			getFeedbackStats()
				.then((response) => {
					if (response.status === 200 && isMounted) {
						// setFeedbackStats(response.data);
						setFeedbackStatsSliders(response.data.sliders);
						setFeedbackStatsRec(response.data.recommendations);
						setFeedbackStatsSugg(response.data.suggestions);
						setFeedbackStatsMot(response.data.motivating_stats);
						setFeedbackStatsTmethod(response.data.test_method);
					}
				})
				.catch((err) => console.log(err));
		}

		return () => {
			isMounted = false;
		};
	}, []);


	useEffect(() => {
		axios
			.get(AppSettings.HIGH_SCORE_URL + "getLeaderboardHighscore/" + context.user?.user_id, {
				headers: {Authorization: context.actions.authHeader()},
			})
			.then((res) => {
				if (res.status === 200) {
					setHighscoreLeaderboad(res.data);
					//console.log(res.data);
				}
			})
			.catch((error) => {
				console.log("scoreboard nicht erhalten");
			});

	}, []);

	if (userRights === "user") {
		return (
			<>
				<Container fluid className="d-flex flex-wrap align-items-center">
						<>
							<Row xs={1} sm={2} md={3}>
								{(radarChart.length <= 2) ? (
										<Col>
											<Card className={"shadow m-2 rounded " + (zoomId === "radarChartStatistic" ? "statistic-zoomed" : "statistic")}>
												<Card.Body>
													<Card.Title>
														Lernzuwachs - Nicht genügend Daten
														<OverlayTrigger
															placement="bottom"
															delay={{ show: 250, hide: 400 }}
															overlay={renderTooltip(`Bitte vervollständige mindestens 3 Fachthemen
															, um folgende Statistik freizuschalten.`)}
														>
															<InfoCircle />
														</OverlayTrigger>
														<Button id={"radarChartStatistic"} className="zoomButton" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
															<BsArrowsFullscreen/>
														</Button>
													</Card.Title>
													<div style={{maxWidth: "700px", margin: "0 auto"}}>
														<Radar
															className="blur-fx"
															data={userRadarChart(radarChart)}
														/>
													</div>
												</Card.Body>
											</Card>
										</Col>
									) : (
										<Col>
											<Card className={"shadow m-2 rounded " + (zoomId === "radarChartStatistic" ? "statistic-zoomed" : "statistic")}>
												<Card.Body>
													<Card.Title>
														Lernzuwachs
														<Button id={"radarChartStatistic"} className="zoomButton" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
															<BsArrowsFullscreen/>
														</Button>
													</Card.Title>
													<div style={{maxWidth: "700px", margin: "0 auto"}}>
														<Radar
															data={userRadarChart(radarChart)}
															options={radarChartOptions}
														/>
													</div>
												</Card.Body>
											</Card>
										</Col>
								)}

								<Col>
									<Card className={"shadow m-2 rounded " + (zoomId === "percentileStatistic" ? "statistic-zoomed" : "statistic")}>
										<Card.Body>
											<Card.Title>
												Wie hast du abgeschnitten im Vergleich zu anderen
												Teilnehmern (Perzentil)
												{percentile.length < 1 ? (
													<OverlayTrigger
														placement="bottom"
														delay={{ show: 250, hide: 400 }}
														overlay={renderTooltip(`Bitte vervollständige mindestens 3 Fachthemen
														, um folgende Statistik freizuschalten.`)}
													>
														<InfoCircle />
													</OverlayTrigger>
													) : (
													<OverlayTrigger
														placement="bottom"
														delay={{ show: 250, hide: 400 }}
														overlay={renderTooltip(`Perzentil Erklärung: Im 95. Perzentil zu sein für ein Thema bedeutet, dass
													du besser als 95% der Teilnehmer sind, die dieses Thema beendet haben. Also gehörst du somit in den Top
													5%.`)}
													>
														<InfoCircle />
													</OverlayTrigger>
												)}
												<Button id="percentileStatistic" className="zoomButton" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
													<BsArrowsFullscreen/>
												</Button>
											</Card.Title>
											<Bar data={userPercentiles(percentile)} />
										</Card.Body>
									</Card>
								</Col>

								<Col>
									<Card className={"shadow m-2 rounded " + (zoomId === "timeStatistic" ? "statistic-zoomed" : "statistic")}>
										<Card.Body>
											<Card.Title>
												Benötigte Zeit für Test pro Fachthema
												<OverlayTrigger
													placement="bottom"
													delay={{ show: 250, hide: 400 }}
													overlay={renderTooltip(
														`Deine Eingangs- und Ausgangstest Zeiten:`
													)}
												>
													<InfoCircle />
												</OverlayTrigger>
												<Button id="timeStatistic" className="zoomButton" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
													<BsArrowsFullscreen/>
												</Button>
											</Card.Title>
											<Bar
												data={userTimes(userTimeStats)}
												options={{
													responsive: true,
													plugins: {
														legend: {
															position: "bottom" as const,
														},
													},
													scales: {
														x: {
															stacked: true,
														},
														y: {
															stacked: true,
														},
													},
												}}
											/>
										</Card.Body>
									</Card>
								</Col>

								<Col>
									<Card className={"shadow m-2 rounded " + (zoomId === "categoryProgressStatistic" ? "statistic-zoomed" : "statistic")}>
										<Card.Body>
											<Card.Title>
												Bearbeitungsstand Fachthemen
												<OverlayTrigger
													placement="bottom"
													delay={{ show: 250, hide: 400 }}
													overlay={renderTooltip(
														`Aus den 23 vorhandenen Themen sind:`
													)}
												>
													<InfoCircle />
												</OverlayTrigger>
												<Button id="categoryProgressStatistic" className="zoomButton" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
													<BsArrowsFullscreen/>
												</Button>
											</Card.Title>
											<div style={{maxWidth: "700px", margin: "0 auto"}}>
												<Doughnut data={userCompleteness(userTestStatus)}/>
											</div>
										</Card.Body>
									</Card>
								</Col>

								<Col>
									<Card className={"shadow m-2 rounded " + (zoomId === "leaderboardStatistic" ? "statistic-zoomed" : "statistic")}>
										<Card.Body>
											<Card.Title>Rangliste Lernzuwachs
												<Button id="leaderboardStatistic" className="zoomButton square-md" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
													<BsArrowsFullscreen/>
												</Button>
											</Card.Title>
											<Table striped bordered hover>
												<thead>
													<tr>
														<th>Name</th>
														<th>Summe Lernzuwachs</th>
													</tr>
												</thead>
												<tbody>
													{userLeaderboad.map((e: any) => {
														return (
															<tr>
																<td>{e.nickname}</td>
																<td>{e.score}</td>
															</tr>
														)
													}
													)}
												</tbody>
											</Table>
										</Card.Body>
									</Card>
								</Col>

								<Col>
									<Card className={"shadow m-2 rounded " + (zoomId === "gamingQuizStatistic" ? "statistic-zoomed" : "statistic")}>
										<Card.Body>
											<Card.Title>
												Rangliste Gaming-Quiz

												<Button id="gamingQuizStatistic" className="zoomButton" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
													<BsArrowsFullscreen/>
												</Button>
											</Card.Title>
											<Table striped bordered hover>
												<thead>
												<tr>
													<th>Name</th>
													<th>Highscore</th>
												</tr>
												</thead>
												<tbody>
												{highscoreLeaderboad.map((e: any) => {
														return (
															<tr>
																<td>{e.nickname}</td>
																<td>{e.high_score}</td>
															</tr>
														)
													}
												)}
												</tbody>
											</Table>
										</Card.Body>
									</Card>
								</Col>

								<Col>
									<Card className={"shadow m-2 rounded " + (zoomId === "badgeStatistic" ? "statistic-zoomed" : "statistic")}>
										<Card.Body>
											<Card.Title>
												Orden
												{/* <Info	Circle /> */}
												<Button id="badgeStatistic" className="zoomButton" variant="light" onClick={toggleStatisticZoom} onMouseDown={e => e.preventDefault()}>
													<BsArrowsFullscreen/>
												</Button>
											</Card.Title>
											<Row className="m-2">
												{userBadges.map((e: any) => (
													// console.log(e.badge)
													<>
														<Col className="col-xs-1 center-block">
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={renderTooltip(
																	e.unlocked
																		? `${e.name},
																		Erhalten am ${e.desc}`
																		: e.desc
																)}
															>
																<img
																	src={
																		"data:image/svg+xml;base64," +
																		window.btoa(e.badge)
																	}
																/>
															</OverlayTrigger>
														</Col>
													</>
												))}
											</Row>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							<Button onClick={() => history.push("/")} text-right>
								zur Hauptseite
							</Button>
						</>
				</Container>
			</>
		);
	} else {
		return (
			<>
				<Container fluid>
					<Tabs
						defaultActiveKey="quiz_stats"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="quiz_stats" title="Test">
							<Row xs={1} sm={2} md={3} >

											{categoryImprovStats.map((prio, key) => (
												<Col>
													<Card className="shadow m-2 rounded">
														<Card.Body>
															<Card.Title>
																Anzahl & Unterteilung der Tests
															</Card.Title>
															<Bar data={numberOfTests(prio)} options={Baroptions} />
															<Doughnut
																data={numberOfTestsPercentage(prio)}
																options={options}
															/>
														</Card.Body>
													</Card>
												</Col>
											))}

{/* 								{passingStats.map((prio, key) => (
									<Col>
										<Card className="shadow m-2 rounded">
											<Card.Body>
												<Card.Title>
													Anzahl der beendeten Themen{" "}
													<Badge
														className="h6"
														style={{ backgroundColor: prio_colors[key] }}
														pill
													>
														Priorität {++key}
													</Badge>
												</Card.Title>
												<Bar data={completeness(prio)} options={Baroptions} />
											</Card.Body>
										</Card>
									</Col>
								))} */}
								{prePostcategoryImprovStats.map((prio, key) => (
									<Col>
										<Card className="shadow m-2 rounded">
											<Card.Body>
												<Card.Title>
													Lernzuwachse
												</Card.Title>
												<Bar data={prePostImprov(prio)} options={Baroptions} />
											</Card.Body>
										</Card>
									</Col>
								))}
								{categoryImprovStats.map((prio, key) => (
									<Col>
										<Card className="shadow m-2 rounded">
											<Card.Body>
												<Card.Title>
													Themen Mittelwerte
												</Card.Title>
												<Bar data={improveRates(prio)} options={Baroptions} />
											</Card.Body>
										</Card>
									</Col>
								))}


								{timeStats.map((prio, key) => (
									<Col>
										<Card className="shadow m-2 rounded">
											<Card.Body>
												<Card.Title>
													Tests Durchschnittszeiten
												</Card.Title>
												<Bar
													data={averageTimeStats(prio)}
													options={Baroptions}
												/>
											</Card.Body>
										</Card>
									</Col>
								))}
								{timeStats.map((prio: any, key) => {
									return (
										<Col>
											<Card className="shadow m-2 rounded">
												<Card.Body>
													<Card.Title>
														Unter einer Minute
													</Card.Title>
													<>
														<Bar
															data={subAboveTimeStats(prio.sub_60)}
															options={Baroptions}
														/>
													</>
												</Card.Body>
											</Card>
										</Col>
									);
								})}
								{timeStats.map((prio: any, key) => {
									return (
										<Col>
											<Card className="shadow m-2 rounded">
												<Card.Body>
													<Card.Title>
														Mehr als 2 Minuten
													</Card.Title>
													<Bar
														data={subAboveTimeStats(prio.above_120)}
														options={Baroptions}
													/>
												</Card.Body>
											</Card>
										</Col>
									);
								})}
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>
												Mediane der Ein- und Ausgangstests
											</Card.Title>
											<Bar
												data={medians(medianScoresEntry, medianScoresFinish)}
											/>
										</Card.Body>
									</Card>

								</Col>
							</Row>
						</Tab>
						<Tab eventKey="Plattform" title="Plattform">
							<Row>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Geschlechtsunterteilung</Card.Title>
											<Bar
												data={genderData(genderStats)}
												options={Baroptions}
											/>
											<Doughnut
												data={genderDistro(genderStats)}
												options={options}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Altersgruppen</Card.Title>
											<Bar data={ageDistro(ageStats)} options={Baroptions} />
											<Doughnut
												data={agePercentage(ageStats, ageMisc)}
												options={options}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Sonstiges</Card.Title>
											<Table striped bordered hover>
												{/* <thead>
													<tr>
														<th>#</th>
														<th>First Name</th>
													</tr>
												</thead> */}
												<tbody>
													<tr>
														<td>Median Age</td>
														<td>{medianAge}</td>
													</tr>
													<tr>
														<td>Average Age</td>
														<td>{averageAge}</td>
													</tr>
													<tr>
														<td>Durchschnitt Eingangstestpunkte</td>
														<td>{averageScores?.entry_points}</td>
													</tr>
													<tr>
														<td>Durchschnitt Ausgangstestpunkte</td>
														<td>{averageScores?.finished_points}</td>
													</tr>
													<tr>
														<td>Durchschnitt Lernzuwachs</td>
														<td>{averageScores?.point_difference}</td>
													</tr>
												</tbody>
											</Table>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="Feedback" title="Feedback">
							<Row xs={1} sm={2} md={4}>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Vorschläge</Card.Title>
											<Bar
												data={suggestionsStat(feedbackStatsSugg)}
												options={Baroptions}
											/>
											<Doughnut data={suggestionsStatPercentage(feedbackStatsSugg)}
												options={options}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Weiterempfehlung</Card.Title>
											<Bar
												data={recommendRate(feedbackStatsRec)}
												options={Baroptions}
											/>
											<Doughnut data={recommendPercentage(feedbackStatsRec)}
												options={options}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Testmethode</Card.Title>
											<Bar
												data={testMethod(feedbackStatsTmethod)}
												options={Baroptions}
											/>
											<Doughnut data={testMethodPercentage(feedbackStatsTmethod)}
												options={options}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Statistiken als Motivationsmittel</Card.Title>
											<Bar
												data={testMethod(feedbackStatsMot)}
												options={Baroptions}
											/>
											<Doughnut data={testMethodPercentage(feedbackStatsMot)}
												options={options}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card className="shadow m-2 rounded">
										<Card.Body>
											<Card.Title>Mittelwerte der Feedbackfragen</Card.Title>
											<Bar
												data={feedbackAverages(feedbackStatsAnswers)}
												options={Baroptions}
											/>

											{/* <Doughnut data={recommendPercentage(feedbackStatsRec)} /> */}
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Tab>
					</Tabs>
					{ }
					<Button
						className="float-right"
						onClick={() =>
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							})
						}
					>
						⬆
					</Button>
				</Container>
			</>
		);
	}
};