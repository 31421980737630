export const skimming = `
# Skimming (Abschöpfen von Daten)
## <br> Definition
## <br> Arten
- Magnetstreifen
- Miniscanner
- Keylogger
- Wanzen
## <br> Ziel
## <br> Funktionsweise
## <br> NFC/RFID
- Definition
- Funktionsweise
- Gefahren
- Schutz
## <br> Gefahrenstelle
## <br> Erkennen
## <br> Betroffene
## <br> Rechtlich
- Daten
- Kameraüberwachung
- Geldabhebung
- Haftung
- Bankkonto
## <br> Was tun?
## <br> Sicherheitsaspekte
## <br> Schutzmaßnahmen
## <br> RFID-Blocker
`;