import {RouteComponentProps, useHistory} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {Button, Container} from "react-bootstrap";
import * as React from "react";
import {InterviewBackground} from "../components/InterviewBackground";
import {userContext} from "../contexts/UserContext";
import {UserStatus} from "../models/User";
import watsonTrainingIntegrationIDsJson from '../components/watson_training.json'

type TParams = { id: string };

declare global {
    interface Window {
        watsonAssistantChatOptions: any;
    }
}

export const FreeAnswerInterviewPage = ({ match }: RouteComponentProps<TParams>) => {
    const [category_id] = useState(match.params.id);
    const context = useContext(userContext);
    const [userStatus] = useState<UserStatus[]>(context.status);
    let categoryStatus: string = "";

    userStatus.forEach((entry) => {
        if (Number(category_id) === entry.category.category_id) {
            categoryStatus = entry.status;
        }
    });

    useEffect(() => {
        const customLanguagePack = {
            "closeAndRestartModal_title": "Chat-Verlauf löschen",
            "closeAndRestartModal_message": "Bist du sicher, dass du den Chat-Verlauf löschen willst?",
            "closeAndRestartModal_confirm": "Ja",
            "closeAndRestartModal_cancel": "Nein",
        };

        const watsonTrainingIDs = JSON.parse(JSON.stringify(watsonTrainingIntegrationIDsJson));
        let watsonTrainingID = ""

        if (category_id in watsonTrainingIDs) {
            watsonTrainingID = watsonTrainingIDs[category_id]

        window.watsonAssistantChatOptions = {
            integrationID: watsonTrainingID, // The ID of this integration.
            region: "eu-de", // The region your integration is hosted in.
            serviceInstanceID: "f450ea51-1230-4fcf-af43-652cab013db7", // The ID of your service instance.
            showCloseAndRestartButton: true,
            onLoad: function (instance: any) {
                instance.updateUserID(String(context.user?.user_id));
                instance.render();
                instance.updateLanguagePack(customLanguagePack);
            }
        };
        setTimeout(function () {
            const t = document.createElement('script');
            t.src = "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" + (window.watsonAssistantChatOptions.clientVersion || 'latest') + "/WatsonAssistantChatEntry.js";
            document.head.appendChild(t);
        });
    }
        else{
            console.log('Für dieses Fachthema ist noch kein Chatbot hinterlegt!')
        }
    }, [])

    return (
        <InterviewBackground title={"Trainingsdialog mit freien Antworten"}
                             textOfFirstRow={"Mit einem Klick auf das Icon unten rechts gelangst du zum Chatbot. " +
             "Mit diesem kannst du die Trainingseinheit in einem Schwierigkeitsgrad deiner Wahl starten."}
            textOfSecondRow={"Um den Trainingsdialog zu starten, muss ein Schwierigkeitsgrad ausgewählt werden."}
                             textOfThirdRow={"Der nächste Schritt ist die Beantwortung von Fragen, die dir der Chatbot stellt."}
                             textOfFourthRow={"Wenn du alle Trainingseinheiten erfolgreich absolviert hast, bist du bereit, " +
                                 "das Thema abzuschließen. Gehe dazu zum Abschlusstest und beantworte die Fragen."}
                             category_id={category_id}
                             categoryStatus={categoryStatus}
                             icon_path={"/images/befragter/watson_icon.png"} />
    );
}