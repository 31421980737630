export const computerviren = `
# Computerviren
## Grundlagen 
- Definition Computervirus 
- Definition Phishing 
- Definition Malware 
- Definition Datenträger
- Definition Antivirussoftware 
- Verbreitung von Computerviren
- Unterschied zwischen Computerviren und Trojaner

## Arten von Computerviren
- Definition Web-Script-Viren
- Definition Browser-Hijacker
- Definition Speicherresidente Viren
- Definition Makro-Viren
 
## Schutzmethoden vor Computerviren 
- Schutz vor gefährlichen E-Mail-Anhängen 
- Verwenden einer Antivirensoftware
- Software-Downloads aus unbekannten Quellen vermeiden
- Umgang mit Datenträger
- System- und Anwendungsaktualisierung

## Prävention und Behandlung von Computerviren 
- Vorgehen nach einer Infektion
- Anzeichen für eine mögliche Infektion


`;