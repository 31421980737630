import React, {useContext} from "react";
import {QuizContext} from "../../contexts/QuizContext";
import {useHistory} from "react-router-dom";

type StartPageProps={
    handleRestart:()=>void
}

export const StartPage: React.FC<StartPageProps> = ({handleRestart}) => {
    const context = useContext(QuizContext);
    const handleStart = context.actions.startQuiz;
    const history = useHistory();

    const goToHomepage = () => {
        history.push("/");
    };

    return (
        <>
            <div>
                <div className="leftTextBox2">
                    <h3 className="justify-content-start d-flex">Spielregeln:</h3>
                    <ul>
                        <li>Beantworte innerhalb von 2 Minuten so viele Fragen wie möglich!</li>
                        <li>Die Fragen sind über alle Fachthemen hinweg.</li>
                        <li>Du hast 3 Fehlversuche! Wenn deine Leben aufgebraucht sind verlierst du die Runde und die gesammelten Punkte gehen verloren.</li>
                    </ul>

                    <h3>Persönlicher Highscore: {context.highscore}</h3>
                </div>
                <div className="leftTextBox2 justify-content-end bereit-container">
                    <div id="startpage-quiz-btn-container">
                        <button className="general-quiz-btn btn-secondary" onClick={()=>{
                            goToHomepage()
                        }}>Zurück</button>
                        <button className="general-quiz-btn btn-primary" onClick={()=>{
                            handleRestart()
                            handleStart()
                        }}>Starten</button>
                    </div>
                </div>
            </div>
        </>
    );
};