export const gutePasswoerter = `
# Gute und sichere Passwörter 

## Definitionen 
- Gutes und sicheres Passwort
- 2-Faktor-Authentifizierung 
- Passwortmanager
- Passwortgenerator
- Brute-force-Angriff
 
## Gefahren im Umgang mit Passwörtern
- Speichern von Passwörtern im Webbrowser
- Grund für die Nutzung von sicheren Passwörtern
- Verwendung unterschiedlicher Passwörter
 
## Toolvorschläge
- Passwortmanager
- 2-Faktor-Authentifizierung

## Umgang mit sicheren Passwörtern
- Auswahl von sicheren Passwörtern
- Aufbewahrung von sicheren Passwörtern
- Ändern von Passwörtern

## Schützen von Passwörtern
- Schutz vor Brute-Force-Angriffen
- Umgang mit Passwortwiederherstellung
- 2-Faktor-Authentifizierung als weitere Sicherheitsmaßnahme

`;