export const datenschutz = `
# Datenschutz im Netz

## Definitionen
- Datenschutz im Netz
- DSGVO
- Personenbezogene Daten

## Bewusstsein für Datenschutz
- Datenschutzbestimmungen auf Webseiten

## Cookies

### Zusammenhänge
- zwischen Cookies und personenbezogenen Daten

### Verwendungszweck
- von Cookies

## Schutz der Personenbezogenen Daten
- im Internet
- auf Mobiltelefonen

## Mobiltelefonanwendungen
- App-Berechtigungen

`;