export const hackingOnlineBankdaten = `
# Hacking - Meine Online-Bankdaten im Netz
## <br> Definition
## <br> Funktionsweise
- TAN
- HBCI
## <br> Sicherheit
- WPA2
- Komplexes Passwort
- Eigenes Gerät nutzen
- Kontobewegungen regelmäßig überprüfen
## <br> Rechtslage
## <br> Schutz
`;