export const sprachassistenten = `
# Sprachassistenten - Wie sicher sind meine Daten?
## <br> Definition
- Sprachasisstent
## <br> Beispiele
- Siri
- Alexa
## <br> Funktionsweise
- Hey Siri
- Hey Google
## <br> Nutzer
## <br> Risiken
## <br> Vorgehensweise
## <br> Schutz
`;