import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import {RouteComponentProps, useHistory} from "react-router-dom";
import "../styles.css";
import {userContext} from "../contexts/UserContext";
import {UserStatus} from "../models/User";
import {Transformer} from "markmap-lib";
import * as markmap from "markmap-view";
import {topicsMap, indicesMap, colorMap} from "../components/markmap/mindmaps";
import watsonIntegrationIDsJson from '../components/watson_fragender.json';

const {Markmap, loadCSS, loadJS} = markmap;
const transformer = new Transformer();

const useM = (md: any, mdindicies: any, option: any) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current.innerHTML = "";
    const {root, features} = transformer.transform(md);
    const {styles, scripts} = transformer.getUsedAssets(features);
    if (styles) loadCSS(styles);
    if (scripts) loadJS(scripts, {getMarkmap: () => markmap});
    Markmap.create(ref.current, option, root);

    // collapse before return svg
    let collapseEvent: any = new CustomEvent("click");
    let circles = ref.current.querySelectorAll("circle");
    let index = 0;

    circles.forEach((element: { dispatchEvent: (arg0: any) => void; }) => {
      if (mdindicies.includes(index))
        element.dispatchEvent(collapseEvent);
      index++;
    });

  }, [md, option]);
  return <svg className={"svgmindmap"} ref={ref} style={{width: "100%", height: "30rem"}}></svg>;
};

type TParams = { id: string };

export const LearningPage = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const [category_id] = useState(match.params.id);
  const context = useContext(userContext);
  const [userStatus] = useState<UserStatus[]>(context.status);
  let mmstring: string;
  let mmindiciess: Array<Number>;
  let renderNewMM: string = "";
  let renderNewMMInverse: string = "";
  let categoryStatus: string = "";
  
  const [mindMapSource]: Array<any> = useState(() => {
    let src = "/images/mindmaps";

    userStatus.forEach((entry) => {
      if (Number(category_id) === entry.category.category_id) {
        src += entry.category.mindmap;
        categoryStatus = entry.status;
      }
      mmstring = topicsMap.get(category_id);
      mmindiciess = indicesMap.get(category_id);

      //set color
      document.documentElement.style.setProperty('--mmcolor', colorMap.get(category_id));
    });
    return [mmstring, mmindiciess, src];
  });

  const handleButton = () => {
    history.push("/abschlusstest/" + category_id);
  };

  useEffect(() => {
    const customLanguagePack = {
      "closeAndRestartModal_title": "Chat-Verlauf löschen",
      "closeAndRestartModal_message": "Bist du sicher, dass du den Chat-Verlauf löschen willst?",
      "closeAndRestartModal_confirm": "Ja",
      "closeAndRestartModal_cancel": "Nein",
    };

    const watsonIntegrationIDs = JSON.parse(JSON.stringify(watsonIntegrationIDsJson));
    let watsonIntegrationID = ""

    if (category_id in watsonIntegrationIDs) {
      watsonIntegrationID = watsonIntegrationIDs[category_id]

      window.watsonAssistantChatOptions = {
        integrationID: watsonIntegrationID, // The ID of this integration.
        region: "eu-de", // The region your integration is hosted in.
        serviceInstanceID: "f450ea51-1230-4fcf-af43-652cab013db7", // The ID of your service instance.
        showCloseAndRestartButton: true,
        onLoad: function(instance : any) {
          instance.updateUserID(String(context.user?.user_id));
          instance.render();
          instance.updateLanguagePack(customLanguagePack);
        }
      };
      setTimeout(function(){
        const t=document.createElement('script');
        t.src="https://web-chat.global.assistant.watson.appdomain.cloud/versions/" + (window.watsonAssistantChatOptions.clientVersion || 'latest') + "/WatsonAssistantChatEntry.js";
        document.head.appendChild(t);
      });
    }

    else {
      console.log('Für dieses Fachthema ist noch kein Chatbot verfügbar!')
    }
  }, [])

  return (
    <Container>
      <Row className="flex-column justify-content-md-start">
        <Col>
        {/* temp until all mm in new format
        todo remove old MM*/}
        <div style={{display: "none" }}>
        {mindMapSource[0] == null? (renderNewMM="none") : (renderNewMM="true")}
        {mindMapSource[0] == null? (renderNewMMInverse="true") : (renderNewMMInverse="none")}
        
        </div>
        
          <Image src={mindMapSource[2]} alt="Mindmap" fluid style={{display: renderNewMMInverse}}/>

          <div style={{display: renderNewMM}}>
            <h2 style={{paddingTop: "20px"}}>Interaktive Mindmap</h2>
            {useM(mindMapSource[0], mindMapSource[1], undefined)}
          </div>

        </Col>
        <Col>
          <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                categoryStatus === "3" ? (
                    <Tooltip id={`tooltip`}>Abschlusstest bereits bestanden!</Tooltip>
                ) : (
                    <div />
                )
              }
          >
            <Button onClick={() => handleButton()} disabled={categoryStatus === "3"}>Zum Abschlusstest</Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  );
};