import React, {useContext, useEffect, useState, Component} from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router-dom";
import "../styles.css";
import {AppSettings} from "../config";
import Alert from 'react-bootstrap/Alert';
import {categoryContext} from "../contexts/CategoryContext";

import {userContext} from "../contexts/UserContext";

enum TrainingOption {
  MULTIPLE_CHOICE = "befragter",
  ASK_QUESTIONS = "fragender",
  FREE_ANSWER_INTERVIEW = "freieAntworten"
}

function notification(entryPointsScore:any) {
  if (entryPointsScore <= 30) {
    return (
        <Alert variant={'info'}>
          <Alert.Heading>{entryPointsScore}% von 100% im Eingangstest erreicht!</Alert.Heading>
          Deine Kenntnisse zum aktuellen Fachthema sind ausbaufähig! Stelle dem Chatbot zu Beginn ein paar Fragen.
        </Alert>
    )
  }

  if (entryPointsScore > 30 && entryPointsScore <= 70) {
    return (
        <Alert variant={'info'}>
          <Alert.Heading>{entryPointsScore}% von 100% im Eingangstest erreicht!</Alert.Heading>
          Du hast dein Wissen im Eingangstest unter Beweis gestellt! Deshalb raten wir Dir mit den Quizzes zu starten.
        </Alert>
    )
  }

  if (entryPointsScore > 70) {
    return (
        <Alert variant={'info'}>
          <Alert.Heading>{entryPointsScore}% von 100% im Eingangstest erreicht!</Alert.Heading>
          Wow! Mit deinem Kenntnisstand solltest du ein Trainingsgespräch mit freien Antwortmöglichkeiten starten.
        </Alert>
    )
  }
}

type TParams = { id: string };

export const LearningEntryPage = ({ match }: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const context = useContext(userContext);
  const [category_id] = useState(match.params.id);
  const [show, setShow] = useState(true);
  const [entryPointsScore, setEntryPointsScore] = useState([]);
  const categories = useContext(categoryContext);

  const FetchTestInfo = async () => {
    const testInfoRequest = await fetch(AppSettings.TEST_URL + context.user?.user_id +"/" + category_id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: context.actions.authHeader()!,
      },
    });
    if (testInfoRequest.status === 200){
      const testInformation = (await testInfoRequest.json()).data
      setEntryPointsScore(testInformation.entry_points)
    }
  }

  if (
      window.location.toString() !== AppSettings.AFTER_FINISH_URL + "fragender/" + category_id ||
      window.location.toString() !== AppSettings.AFTER_FINISH_URL + "befragter/" + category_id
  )
  {
    document.querySelector("body > div.WatsonAssistantChatHost")?.remove();
  }


  function redirectTo ( trainingOption : TrainingOption) {
    history.push(`/${trainingOption}/${category_id}`)
  }

  useEffect(() => {
    FetchTestInfo()
  },[])

  return (
      <div className="container" style={{minHeight:'100'}}>
        <h1 className="text-center mt-5">{categories.actions.getCategory(Number(category_id))}</h1>
        <h3 className="text-center m-3">Wir haben folgende Lernmethoden für dich zur Auswahl:</h3>
        <Row className="justify-content-md-center mt-5">
          <Col md="4" >
            <Card>
              <Card.Header></Card.Header>
              <Card.Img className={"learningPageImage"} variant="top" src="/images/fragender.png" />
              <Card.Body>
                <Card.Title>Fragen stellen</Card.Title>
                <Card.Text className={"mb-3"}>
                  Löchere mich mit deinen Fragen zu einem Fachthema!
                </Card.Text>
                <Button variant="primary" onClick={() => redirectTo(TrainingOption.ASK_QUESTIONS)}>
                  Fragen stellen
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header></Card.Header>
              <Card.Img className={"learningPageImage"} variant="top" src="/images/befragter.png"/>
              <Card.Body>
                <Card.Title>Multiple-Choice Quiz lösen</Card.Title>
                <Card.Text className={"mb-3"}>
                  Starte ein Quiz mit vordefinierten Antwortmöglichkeiten!
                </Card.Text>
                <Button variant="primary" onClick={() => redirectTo(TrainingOption.MULTIPLE_CHOICE)}>
                  Multiple-Choice Quiz lösen
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header></Card.Header>
              <Card.Img className={"learningPageImage"} variant="top" src="/images/befragter.png"/>
              <Card.Body>
                <Card.Title>Trainingsdialog absolvieren</Card.Title>
                <Card.Text className={"mb-3"}>
                  Starte ein Trainingsdialog mit freien Antwortmöglichkeiten!
                </Card.Text>
                <Button variant="primary" onClick={() => redirectTo(TrainingOption.FREE_ANSWER_INTERVIEW)}>
                  Trainingsdialog absolvieren
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="p-5">
          {
              notification(entryPointsScore)
          }
        </div>
    </div>
  );
};
