import React, {useContext, useState} from "react";
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {Eye} from "react-bootstrap-icons";
import {userContext} from "../contexts/UserContext";
import {LoginOptions} from "../models/User";
import {PrivacyPolicyText} from "../components/PrivacyPolicyText";

export const SignIn: React.FC = () => {
	const context = useContext(userContext);
	const history = useHistory();

	const [username, setUsername] = useState("");
	const [errorMsgUsername, setErrorMsgUsername] = useState("");
	const [password, setPassword] = useState("");
	const [errorMsgPw, setErrorMsgPw] = useState("");
	const [passwordShow, setPasswordShow] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const [isLoading, setLoading] = useState(false);
	const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);

	const handleLogin = (event: any, loginOptions: LoginOptions) => {
		setLoading(true);

		checkInputFields();

		if (checkUsername()
			&& checkPassword())
		{
			context.actions.login(loginOptions)
				.then((response: any) => {
					setLoading(false);

					if (response.status === 200) {
						clearStates();
						history.push("/personalisierung");
					} else {
						setErrorMsg(response.data?.error);
					}
				})
				.catch((error: any) => {
					console.log(error);
				});
		}
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	};

	const checkInputFields = () => {
		checkUsername();
		checkPassword();
	};

	const checkUsername = () => {
		if (username.length === 0) {
			setErrorMsg("");
			setErrorMsgUsername("Es muss ein Benutzername eingegeben werden.");
			return false;
		} else {
			setErrorMsgUsername("");
			return true;
		}
	};

	const checkPassword = () => {
		if (password.length === 0) {
			setErrorMsg("");
			setErrorMsgPw("Es muss ein Passwort eingegeben werden.");
			return false;
		} else {
			setErrorMsgPw("");
			return true;
		}
	};

	const clearStates = () => {
		setUsername("");
		setErrorMsgUsername("");
		setPassword("");
		setErrorMsgPw("");
		setPasswordShow(false);
		setErrorMsg("");

		setLoading(false);
	};

	return (
		<div
			className="d-flex align-items-center customHeight"
		>
			<div
				className="container align-items-center login-container box-blur"
			>
				<Form style={{ padding: "20px" }}>
					<h3 className="text-center">Melde dich mit deinem Account an</h3>

					<Form.Group>
						<Form.Label className="font-weight-bold">Benutzername (Pseudonym)</Form.Label>
						<Form.Control
							type="name"
							name="username"
							placeholder="Benutzername eingeben"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgUsername}</Form.Text>
					</Form.Group>

					<Form.Group>
						<Form.Label className="font-weight-bold">Passwort</Form.Label>
						<InputGroup>
							<Form.Control
								type={passwordShow ? "text" : "password"}
								name="password"
								placeholder="Passwort eingeben"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<InputGroup.Prepend
								className="showPasswordIcon"
								onClick={() =>
									!passwordShow && password.length !== 0
										? setPasswordShow(true)
										: setPasswordShow(false)
								}
							>
								<InputGroup.Text>
									<Eye/>
								</InputGroup.Text>
							</InputGroup.Prepend>
						</InputGroup>
						<Form.Text className="text-danger FontForErrorMsg">{errorMsgPw}</Form.Text>
					</Form.Group>

					<Form.Text className="text-danger font-weight-bold">{errorMsg}</Form.Text>

					<Button
						className="mb-4 mt-4"
						variant="primary"
						size="lg"
						style={{backgroundColor: "#007bff"}}
						block
						onClick={(e: any) =>
							handleLogin(e, {
								nickname: username,
								password: password,
							})
						}
					>
						Einloggen
						{
							isLoading
							? (<span
									className="spinner-border spinner-border-sm ml-5"
									role="status"
									aria-hidden="true"
								/>)
							: (<span/>)
						}
					</Button>

					<Link to="/registrieren" className="text-blue text-center">
						Noch nicht registriert? Jetzt registrieren.
					</Link>
				</Form>
			</div>

			<footer className="text-muted bg-dark variant-dark footer">
				<div
					className="container d-flex justify-content-between"
					style={{ paddingBottom: "10px", paddingTop: "10px" }}
				>
					<div>
						<a href="https://fbi.h-da.de/">Hochschule Darmstadt Fachbereich Informatik</a>
					</div>
					<div className="privacyPolicyText" onClick={() => setPrivacyModalIsOpen(true)}>
						Datenschutzerklärung
					</div>
					<div>
						<a href="/#">Zum Seitenanfang</a>
					</div>
				</div>
			</footer>

			<Modal
				size="lg"
				show={privacyModalIsOpen}
				onHide={() => setPrivacyModalIsOpen(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Datenschutzerklärung
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PrivacyPolicyText />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setPrivacyModalIsOpen(false)}>
						Schließen
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
