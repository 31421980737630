import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../contexts/UserContext";
import RangeSlider from "react-bootstrap-range-slider";
import { InfoCircle, Textarea } from "react-bootstrap-icons";
import {
	Container,
	Button,
	Modal,
	Form,
	Card,
	Row,
	Col,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import "../styles.css";
// import Modal from "react-modal";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppSettings } from "../config";
import axios from "axios";

export const UmfrageOnline: React.FC = () => {
	const context = useContext(userContext);
	const [userID] = useState<number>(context.user!.user_id);
	const history = useHistory();
	const [questions, setQuestions] = useState<any[]>([]);
	const [value, setValue] = useState(0);
	const [value1, setValue1] = useState(0);
	const [value2, setValue2] = useState(0);
	const [dropdown, setDropdown] = useState<string | null>(null);
	const [dropdown1, setDropdown1] = useState<string | null>(null);
	const [comment, setComment] = useState<string>("");
	const [isDragged, setisDragged] = useState(false);
	const [isDragged1, setisDragged1] = useState(false);
	const [isDragged2, setisDragged2] = useState(false);
	const [modalState, setModalState] = useState(false);
	const [incompleteModalState, setincompleteModalState] = useState(false);
	const [radioValue, setRadioValue] = useState<number>(0);
	const [radio1Value, setRadio1Value] = useState<number | null>(null);
	const [radio2Value, setRadio2Value] = useState<number | null>(null);
	const [radio3Value, setRadio3Value] = useState<number | null>(null);

	const [textArea, setTextArea] = useState<string>("");
	const [countCharacters, setCountCharacters] = useState<number>(0);

	if (
		window.location.toString() !== AppSettings.AFTER_FINISH_URL + "fragender" ||
		window.location.toString() !== AppSettings.AFTER_FINISH_URL + "befragter"
	) {
		document.querySelector("body > div.WatsonAssistantChatHost")?.remove();
	}

	const retrieveQuestions = () =>
		axios.get(AppSettings.FEEDBACK_QUESTIONS).then((res) => res);

	useEffect(() => {
		retrieveQuestions().then((response) => {
			response.status === 200
				? setQuestions(response.data)
				: console.log(response.status);
		});
	}, []);

	const goToHomepage = () => {
		// eslint-disable-next-line no-restricted-globals
		history.push("/");
	};
	const handleTextArea = (data : string) => {
		setCountCharacters(data.length);
		setTextArea(data);
	}

	const handleSubmit = (e: any) => {
		e.preventDefault();
		console.log({
			user_id: userID,
			general_rating: value,
			topic_interest: value1,
			knowledge_growth: value2,
			motivating_stats: radio1Value,
			// why_stats_demotivate: dropdown,
			suggestions: dropdown,
			sufficient_stats: radio2Value,
			testing_method: radio3Value,
			// stats_addition_suggestion: dropdown1,
			recommendation: radioValue,
			textAreaInput: textArea,
		});
		// get our new errors
		/* const newErrors = findFormErrors();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {
		} */
		if (isDragged && isDragged1 && isDragged2 && radioValue !== null && textArea.length > 20) {
			axios
				.post(AppSettings.FEEDBACK, {
					user_id: userID,
					general_rating: value,
					topic_interest: value1,
					knowledge_growth: value2,
					motivating_stats: radio1Value,
					suggestions: dropdown,
					testing_method: radio3Value,
					sufficient_stats: radio2Value,
					// stats_addition_suggestion: dropdown1,
					recommendation: radioValue,
					textAreaInput: textArea,
				})
				.then((response) => {
					response.status === 200 ? setModalState(true) : setModalState(false);
				})
				.catch((error) => {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				});
		} else {
			setincompleteModalState(true);
		}

		// No errors! Put any logic here for the form submission!
		// alert("Thank you for your feedback!");
	};
	const renderTooltip = (props: any) => (
		<Tooltip id="button-tooltip" {...props}>
			Wenn du "0" auswählen möchtest musst du den Slider trotzdem einmal verschieben, damit wir dein Feedback registrieren.
		</Tooltip>
	);
	return (
		<div className="d-flex justify-content-center align-item-center">
			<Card className="shadow rounded" style={{ margin: "5%" }}>
				<div className="d-flex flex-column align-items-center">
					<Card.Title className="mt-3">Dein Feedback ist uns wichtig!</Card.Title>
					<Card.Body>
						<Form
							className={"justify-content-md-center"}
							style={{ width: "500px" }}
						>
							<Form.Group className="text-center">
								<Form.Label>
									Wie war die allgemeine Erfahrung mit der Lernplattform?
									<OverlayTrigger
										placement="right"
										delay={{ show: 250, hide: 400 }}
										overlay={renderTooltip}
									>
										<InfoCircle />
									</OverlayTrigger>
								</Form.Label>
								<RangeSlider
									value={value}
									min={0}
									max={5}
									onChange={(changeEvent: any) => {
										setisDragged(true);
										setValue(changeEvent.target.value);
										console.log({
											user_id: userID,
											general_rating: value,
											topic_interest: value1,
											knowledge_growth: value2,
											motivating_stats: radio1Value,
											suggestions: dropdown,
											sufficient_stats: radio2Value,
											testing_method: radio3Value,
											// stats_addition_suggestion: dropdown1,
											recommendation: radioValue,
										});
									}}
								/>
							</Form.Group>
							<Form.Group className="text-center">

								<Form.Label>
									Konntest du dein Wissen dank der Lernplattform erweitern?
									<OverlayTrigger
										placement="bottom"
										delay={{ show: 250, hide: 400 }}
										overlay={renderTooltip}
									>
										<InfoCircle />
									</OverlayTrigger>
								</Form.Label>
								<RangeSlider
									value={value1}
									min={0}
									max={5}
									onChange={(changeEvent: any) => {
										setisDragged1(true);
										setValue1(changeEvent.target.value);
									}}
								/>
							</Form.Group>
							<Form.Group className="text-center">

								<Form.Label>
									Wie interessant fandest du die Fachthemen?
									<OverlayTrigger
										placement="right"
										delay={{ show: 250, hide: 400 }}
										overlay={renderTooltip}
									>
										<InfoCircle />
									</OverlayTrigger>
								</Form.Label>
								<RangeSlider
									value={value2}
									min={0}
									max={5}
									onChange={(changeEvent: any) => {
										setisDragged2(true);
										setValue2(changeEvent.target.value);
										console.log({
											user_id: userID,
											general_rating: value,
											topic_interest: value1,
											knowledge_growth: value2,
											motivating_stats: radio1Value,
											suggestions: dropdown,
											sufficient_stats: radio2Value,
											testing_method: radio3Value,
											// stats_addition_suggestion: dropdown1,
											recommendation: radioValue,
										});
									}}
								/>
							</Form.Group>
							<Form.Group className="text-center">
								<Form.Label>
									Motiviert dich die Ansicht der Statistiken und Orden, sich zu
									verbessern?
								</Form.Label>
								<div>
									<Form.Check
										inline
										label="ja"
										name="group1"
										type="radio"
										onClick={() => {
											setRadio1Value(1);
											setRadio2Value(null);
											setDropdown(null);
											console.log(radio1Value);
										}}
									/>
									<Form.Check
										inline
										label="nein"
										name="group1"
										type="radio"
										onClick={() => {
											setRadio1Value(0);
										}}
									/>
								</div>
							</Form.Group>
							{radio1Value === 1 ? (
								<Form.Group className="text-center">
									<Form.Label>
										Findest du, dass die Anzahl der angezeigten Statistiken
										ausreichend ist?
									</Form.Label>
									<div>
										<Form.Check
											inline
											label="ja"
											name="group2"
											type="radio"
											onClick={() => {
												setRadio2Value(1);
												setDropdown(null);
											}}
										/>
										<Form.Check
											inline
											label="nein"
											name="group2"
											type="radio"
											onClick={() => setRadio2Value(0)}
										/>
									</div>
								</Form.Group>
							) : (
								<></>
							)}
							{radio1Value === 0 ? (
								<Form.Group className="text-center">
									<Form.Label>
										{/* Was würdest du dann bei der Statistikseite gerne ändern? */}
										{/* Woran liegt das? */}
										{questions[0]!.question}
									</Form.Label>
									<Form.Control
										as="select"
										onChange={(e) => setDropdown(e.target.value)}
										isInvalid={dropdown === null}
									>
										<option disabled selected>
											Bitte auswählen:
										</option>
										{questions.map((e) => {
											if (e.question_id === 1) {
												return <option value={e.answer_id}>{e.answer}</option>;
											}
										})}
										{/* 												<option value="insufficientInterestLevel">
													Die Statistiken sind nicht interessant genug, um
													Motivation aufzuwecken
												</option>
												<option value="demotivatingLeaderboard">
													Die Rangliste ist demotivierend, nur die eigenen
													Ergebnisse sind interessant
												</option>
												<option value="noInterestInStats">
													Generell kein Interesse an den Statistiken
												</option> */}
									</Form.Control>
								</Form.Group>
							) : (
								<></>
							)}
							{radio2Value === 0 && radio1Value !== 0 ? (
								<Form.Group className="text-center">
									<Form.Label>
										{/* Was würdest du dann bei der Statistikseite gerne ändern? */}
										Was würdest du gerne hinzufügen?
										{questions[1].question}
									</Form.Label>
									<Form.Control
										as="select"
										onChange={(e) => setDropdown(e.target.value)}
										isInvalid={dropdown === null}
									>
										<option value="" disabled selected>
											Bitte auswählen:
										</option>
										{questions.map((e) => {
											if (e.question_id === 2) {
												return <option value={e.answer_id}>{e.answer}</option>;
											}
										})}

										{/* 	<option value="moreLeaderboards">
													Mehr Ranglisten
												</option>
												<option value="moreTimedStats">
													Mehr Statistiken zu den Bearbeitungszeiten
												</option>
												<option value="other">anderes</option> */}
									</Form.Control>
								</Form.Group>
							) : (
								<></>
							)}
							{dropdown1 === "other" && dropdown1 !== undefined ? (
								<Form.Group className="text-center">

									<Form.Label>
										Vorschlag (min: 20 Zeichen, max: 140 Zeichen)
									</Form.Label>
									<Form.Control
										as="textarea"
										onChange={(e) => setComment(e.target.value)}
										isInvalid={comment!.length <= 20 || comment!.length > 140}
									/>
									{/* <Form.Control.Feedback type="invalid">
											</Form.Control.Feedback> */}
								</Form.Group>
							) : (
								<></>
							)}
							<Form.Group className="text-center">

								<Form.Label>
									Findest du dass nur ein Eingangs- und Ausgangstest ausreichend
									ist, um dein Wissen zu erweitern?
								</Form.Label>
								<div>
									<Form.Check
										inline
										label="ja"
										name="group4"
										type="radio"
										onClick={() => setRadio3Value(1)}
									/>
									<Form.Check
										inline
										label="nein"
										name="group4"
										type="radio"
										onClick={() => setRadio3Value(0)}
									/>
								</div>
							</Form.Group>
							<Form.Group className="text-center">
								<Form.Label>
									Würdest du die Lernplattform deinen Kollegen weiterempfehlen?
								</Form.Label>
								<div>
									<Form.Check
										inline
										label="ja"
										name="group5"
										type="radio"
										onClick={() => setRadioValue(1)}
									/>
									<Form.Check
										inline
										label="nein"
										name="group5"
										type="radio"
										onClick={() => setRadioValue(0)}
									/>
								</div>
							</Form.Group>
							<Form.Group className="mb-3">
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<br/>
									<Form.Label>Dein persönliches Feedback ist uns wichtig</Form.Label>
									<p>{countCharacters}/500</p>
								</div>
								<Form.Control
									as="textarea" rows={5}
									placeholder="Erzähl uns von deinen Erfahrungen! "
									//value = {textArea}
									//onChange={handleTextArea(value)}
									onChange={(e) => handleTextArea(e.target.value)}
									maxLength={500}
									isValid={textArea.length > 20}
									isInvalid={textArea.length > 0 && textArea.length < 20}
								/>
								{textArea.length > 0 && textArea.length < 20 && (
									<Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
										Bitte geben Sie mindestens 20 Zeichen ein.
									</Form.Control.Feedback>
								)}
							</Form.Group>
							<Button
								className="float-right"
								type="submit"
								onClick={handleSubmit}
							>
								Feedback schicken
							</Button>
						</Form>
					</Card.Body>
				</div>{" "}
			</Card>
			<Modal show={modalState}>
				<Modal.Header>
					<Modal.Title>Danke für dein Feedback!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Du kannst zurück zur Hauptseite oder dir die Ergebnisse
						anschauen.
					</p>
					<br />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => goToHomepage()}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={incompleteModalState}>
				<Modal.Header>
					<Modal.Title>Feedback unvollständig</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Bitte beantworte alle Fragen.</p>
					<br />
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => setincompleteModalState(false)}
					>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
