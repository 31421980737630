export const sexting = `
# Sexting im Netz
## <br> Definition
## <br> Statistik
## <br> Risiken
- Cybermobbing
- Sexortion
- Diebstahl
## <br> Social Media
## <br> Strafrecht
- erlaubt/legal
- nicht erlaubt/illegal
`;