import React, {useContext, useEffect, useState} from "react";
import {
    UserStatus,
} from "../models/User";
import {userContext} from "./UserContext";

type StatusContext = {
    actions: {
        updateUserStatus: (id:number, status:string) => any;
    };
    status: UserStatus[];
};

export const initialContext = {
    actions: {
        updateUserStatus: (id:number, status:string) => {},
    },
    status: [],

};

export const statusContext = React.createContext<StatusContext>(initialContext);

export const StatusProvider: React.FC = ({ children }) => {

    const context = useContext(userContext)
    const [userStatus, setUserStatus] = useState<UserStatus[]>([]);

    useEffect(() => {
        setUserStatus(context.status)
    })

    function updateUserStatus(id:number, status:string) {
        const index = userStatus.findIndex(s => s.user_status_id === id)

        userStatus[index].status = status
        setUserStatus([...userStatus])
    }

    return (
        <statusContext.Provider
            value={{
                actions: { updateUserStatus },
                status: userStatus,
            }}
        >
            {children}
        </statusContext.Provider>
    );
};
