export const malware = `
# Malware (Schadsoftware)

## Malware Grundlagen  
- Definition Malware  
- Von Malware betroffene Geräte
- Ziele von Malware
- Verbreitungswege von Malware
- Mögliche Schäden für Privatpersonen
- Mögliche Schäden für Unternehmen

## Arten von Malware 
- Definition Virus
- Definition Würmer
- Definition Trojaner
- Definition Ransomware
- Definition Spyware
- Definition Adware
- Definition Rootkits
- Unterschiede verschiedener Arten von Malware
  - Unterscheidung Virus - Malware
  - Unterschied Trojaner - Wurm
  - Unterschied Virus - Wurm
  - Unterschied Virus - Trojaner
  
## Behandlung von Malware Infektionen 
- Richtiges Verhalten nach einer Infektion
- Entfernung von Malware
- Anzeichen einer Infektion

## Schutzmaßnahmen gegen Malware-Angriffe 
- Verwenden einer Antivirensoftware
- Verwenden einer Firewall
- System- und Anwendungsaktualisierung
- Schutz vor gefährlichen E-Mail-Anhängen
- Vermeiden von Software-Downloads aus unbekannten Quellen 
- Kontosicherheit
- Erstellen von Backups
- Schutz vor gefährlichen Datenträgern


`;