import axios, {AxiosError} from "axios";
import React, {useContext, useEffect, useState} from "react";
import {
    ToggleButton,
    ButtonGroup,
    Col,
    Container, Form,
    Row, Tab, Tabs,
} from "react-bootstrap";
import {userContext} from "../contexts/UserContext";
import {Answer} from "../models/Answers";
import {AppSettings} from "../config";
import {shuffleArray} from "../helper/array_shuffle";
import {SuccessPage} from "../components/quizcomponents/SuccessPage";
import {StartPage} from "../components/quizcomponents/StartPage";
import {QuizContext} from "../contexts/QuizContext";
import {FailPage} from "../components/quizcomponents/FailPage";
import "../components/quizcomponents/stylesQuiz.css";
import {ProgressBarQuiz} from "../components/quizcomponents/ProgressBarQuiz";
import {Test} from "../models/Quiz";

const maxTimerQuiz = 120;
export const handleCatch =(error:AxiosError) => {
    if (error.response) {
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
    } else if (error.request) {
        //console.log(error.request);
    } else {
        //console.log("Error", error.message);
    }
    //console.log(error.config);
}

export const QuizPage: React.FC = () => {
    const context = useContext(userContext);
    const quizcontext = useContext(QuizContext);
    const handleEnd = quizcontext.actions.endQuiz;

    const [startTrivia, setStartTrivia] = useState(false);
    const [tests, setTests] = useState<Test[]>([]);
    const [nextQuestion, setNextQuestion] = useState(0);
   //übrige Leben
    const [lives, setLives] = useState(3);
    const [correctID, setCorrID] = useState(0);
    //Timer Variablen
    const [disable, setDisable] = React.useState(false);
    const [loadingBreak, setLoadingBreak] = useState(false);
    //Zählt falsche antwroten
    const [questionIndex, setQuestionIndex] = useState(0);
    const [countdownQuiz, setCountdownQuiz] = useState(maxTimerQuiz);

    //Sendet an die Datenbank Scoretracker
    function sendToScoretracker(success:1|2,points:number){
        axios
            .post(
                AppSettings.QUIZ_SCORETRACKER_URL + context.user?.user_id,
                {
                    user_id: context.user?.user_id,
                    quiztrivia_id: 1,
                    quiztrivia_score: points,
                    quiztrivia_attempts: 1,
                    quiztrivia_success: success,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: context.actions.authHeader(),
                    },
                }
            )
            .catch(handleCatch);
    }


    //Guckt ob man 3 Falsche hat
    //Wenn ja wird der Status im Child gewechselt und die Seite ändert sich
    const checkCorrectAnswers = () => {
        if (lives === 0) {
            quizcontext.actions.settingResult(false);
            setCountdownQuiz(maxTimerQuiz);
            setLoadingBreak(false);
            setQuestionIndex(0);
            setDisable(false);
            setCorrID(0);
            sendToScoretracker(2,quizcontext.points);
            handleEnd();
            quizcontext.actions.setPoints(0);
        } else {
            quizcontext.actions.settingResult(true);
        }
    }

    //Kontrolliert ob der Timer 0 erreicht hat
    //Falls ja wird das Spiel beendet
    const checkTimer = () => {
        if (countdownQuiz === 0) {
            quizcontext.actions.setPoints(quizcontext.points);
            setCountdownQuiz(maxTimerQuiz);
            quizcontext.actions.settingResult(true);
            setDisable(false);
            setCorrID(0);
            setQuestionIndex(0);
            checkCorrectAnswers();
            setLoadingBreak(false);
            checkHighScore();
            sendToScoretracker(1,quizcontext.points);
            handleEnd();
        }
    }

    //Startet den Timer für das Quiz sobald auf Ready in der Startseite gedrückt wird
    useEffect(() => {
        let interval:any;
        if (quizcontext.timerstart && countdownQuiz>0) {
            interval = setTimeout(() => {
                setCountdownQuiz(prevCounter=> prevCounter-1);
            }, 1000);
        } else if(countdownQuiz === 0){
            checkTimer();
        }
        return () => clearTimeout(interval);
    }, [quizcontext.timerstart, countdownQuiz]);

    //Laden der nächsten Frage
    useEffect(() => {
        let interval:any;
        if (loadingBreak) {
            interval = setInterval(() => {
                //Überprüft ob es GameOver ist
                checkCorrectAnswers();
                //setzt ID wieder auf 0 damit Farben wieder passen
                setCorrID(0);
                //Lädt nächste Frage
                setQuestionIndex((prev) => prev + 1);
                setDisable(false);
                //Damit die nächste Frage beantwortet werden kann
                setLoadingBreak(false);
            }, 600);
        }
        return () => clearInterval(interval);
    }, [loadingBreak, disable]);

    //startet das Quiz und holt die Fragen
    useEffect(() => {
        quizcontext.actions.resetStartEndQuiz();
        if (!startTrivia) {
            axios
                .post(
                    AppSettings.TEST_URL + "createQuizTest",
                    {
                        user_id: context.user?.user_id,
                        //category_id: 99,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: context.actions.authHeader(),
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        response.data.data
                            .forEach((e: any) => shuffleArray(e.answers));
                        setTests(shuffleArray(response.data.data));
                        setStartTrivia(true);
                    }
                })
                .catch(handleCatch);
        }
    }, [context.actions, context.user?.user_id]);

    function restart(){
        setQuestionIndex(0);
        shuffleArray(tests);
        setCountdownQuiz(maxTimerQuiz);
        setLives(3);
        quizcontext.actions.setPoints(0);
    }

    return (
        <div>
            <div>
                <h1 className="text-center mt-5 quiz-headline-padding">Quizze dich zum Sieg!</h1>
                <div className="d-flex align-items-center testHeight " style={{backgroundColor: "#FFFFFF"}}>
                    <div className="container align-items-center justify-content-center box-blur"
                         style={{backgroundColor: "#FFFFFF"}}>

                        {/*Bedingungen welche Seite angezeigt wird*/}
                        {!quizcontext.start && !quizcontext.end && <StartPage handleRestart={restart}/>}
                        {quizcontext.start && quizcontext.end && quizcontext.result && <SuccessPage/>}
                        {quizcontext.start && quizcontext.end && !quizcontext.result && <FailPage/>}

                        {quizcontext.start && !quizcontext.end && tests.length > 0 &&
                            <Container>
                                <Row>
                                    <Col>
                                        <Tabs className={"invisible"} activeKey={questionIndex}
                                              id="controlled-tab-example">
                                            {tests.map((question, index) => {
                                                return (renderQuestion(question, index))
                                            })}
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {quizcontext.start && !quizcontext.end && tests.length > 0 &&
                            <div className="container d-flex justify-content-end quiz-container-padding">
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    //Soll Highscore anpassen damit diese dann übergeben werden können
    function checkHighScore() {
        if (quizcontext.points > quizcontext.highscore) {
           quizcontext.actions.setHighscore(quizcontext.points);
            axios
                .post(
                    AppSettings.HIGH_SCORE_URL + "updateHighscore/" + context.user?.user_id,
                    {
                        user_id: context.user?.user_id,
                        high_score: quizcontext.points,
                        nickname: context.user?.nickname,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: context.actions.authHeader(),
                        },
                    }
                )
                .catch(handleCatch);
        }
    }

    function renderAnswers(answer: Answer) {
        return (
            <Row className="justify-content-md-center">
                <div key={`default-radio-${answer.answer_id}`} className="mb-3">
                    <ButtonGroup className="m-2 quizcontent" toggle>
                        <ToggleButton
                            key={answer.answer_id}
                            type="radio"
                            variant="outline-secondary-quiz"
                            name={"radio" + answer.answer_id}
                            value={answer.answer_id}
                            disabled={disable}
                            style={correctID === 0 ? {color: "black"} : (correctID === answer.answer_id ? {backgroundColor: "#a9caf5"} : {backgroundColor: "#f1b0b7"})}

                            onClick={() => {
                                validateAnswers(answer.answer_id, questionIndex);
                                setLoadingBreak(true);
                                if (questionIndex >= nextQuestion) {
                                    setNextQuestion(questionIndex + 1);
                                }
                                setDisable(true);
                            }}
                        >
                            &nbsp;{answer.description}
                        </ToggleButton>
                    </ButtonGroup>
                </div>
            </Row>
        )
    }

    //Ausgabe der Fragen
    function renderQuestion(questiontrivia: Test, index: number) {
        return (
            <Tab title="" eventKey={String(index)}>
                <div>
                    <div id="question-quiz-container">
                        <h2 className="leben-links">Übrige Leben: {lives}</h2>
                        <h2 className="leben-links">Erreichte Punkte: {quizcontext.points}</h2>
                    </div>

                    <ProgressBarQuiz
                        bgcolor={"#a9caf5"}
                        completed={countdownQuiz}
                        maxQuizTime={maxTimerQuiz}
                    />
                </div>

                <div className="container d-flex quiz-container-padding mt-4">
                    <h2 className="text-center flex-grow-1">{questiontrivia.description}</h2>
                </div>
                <Form>
                    {questiontrivia.answers.map((answer) => {
                        return (renderAnswers(answer))
                    })}
                </Form>
            </Tab>
        )
    }

    //Auswertung der Antwort mit den Punkten und Leben
    function validateAnswers(answerId: number, indexQuestion: number) {
        setCorrID(tests[indexQuestion].right_answer);
        if (tests[indexQuestion].right_answer === answerId) {
            if (!disable) {
                quizcontext.actions.setPoints(quizcontext.points+10);
            }
        } else {
            if (!disable) {
               setLives(lives - 1);
            }
        }
    }
};