import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Col,
    Container, Form,
    Row, Tab, Tabs,
    Modal
} from "react-bootstrap";
import {RouteComponentProps, useHistory, useParams} from "react-router-dom";
import {userContext} from "../contexts/UserContext";
import {Answer} from "../models/Answers";
import {AppSettings} from "../config";
import {UserStatus} from "../models/User";
import {shuffleArray} from "../helper/array_shuffle";
import {Check, X} from "react-bootstrap-icons";
import {statusContext} from "../contexts/StatusContext";
import {categoryContext} from "../contexts/CategoryContext";


type TParams = { id: string };
type Test = {
    question_id: number;
    description: string;
    category_id: number;
    difficult_id: number;
    right_answer: number;
    test_id: number;
    answers: Answer[];
};

type Post = {
    user_id: number | undefined;
    test_id: number;
    answers: Answer[];
    timer: number;
};

export const EntryTestPage= ({ match }: RouteComponentProps<TParams>) => {
    const [category_id] = useState(match.params.id);
    const context = useContext(userContext);
    const statContext = useContext(statusContext);
    const categories = useContext(categoryContext);
    const history = useHistory();
    const [percentage, setPercentage] = useState(-1);
    const [fire, setFire] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [tests, setTests] = useState<Test[]>([]);
    const {id} = useParams<TParams>();
    const [radio, setRadio] = useState<any>([{}]);
    const [postRadio, setPostRadio] = useState<any>([...radio]);
    const [test_id, setTestID] = useState(0);
    const [post_values] = useState([...radio]);
    const [userStatus] = useState<UserStatus[]>(context.status);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [nextQuestion, setNextQuestion] = useState(0);
    const [questionAnswer, setQuestionAnswer] = useState<any>([{}]);
    const [testReview] = useState([...questionAnswer]);
    const [showValidation, setShowValidation] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [timerState] = useState(context.timer);

    const changeHandler = (
        answerId: number,
        indexQuestion: number,
        questionId: number,
    ) => {
        const values = [...radio];

        values[indexQuestion] = answerId;
        post_values[indexQuestion] = [questionId, answerId];

        setPostRadio(post_values);
        setRadio(values);
        setTestID(tests[0].test_id);
        setIsActive(true);
    };

    const updateCategoryStatus = () => {
        let user_status_id: number;
        userStatus.forEach((value) => {
            if (value.category.category_id.toString() === id) {
                user_status_id = value.user_status_id;
                axios
                    .put(
                        AppSettings.USER_STATUS + user_status_id,
                        {
                            status: "2",
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: context.actions.authHeader(),
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            //Status Successfully updated
                            statContext.actions.updateUserStatus(user_status_id, "2")
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log("Error", error.message);
                        }
                        console.log(error.config);
                    });
            }
        });
    };

    const submitHandler = () => {
        const user_id = context.user?.user_id;
        const submitObject: Post = {
            user_id: user_id,
            test_id: test_id,
            answers: postRadio,
            timer: seconds,
        };
        if (
            postRadio.filter((e: any) => Object.keys(e).length !== 0).length ===
            tests.length
        ) {
            setIsActive(false);
            axios
                .post(
                    AppSettings.TEST_URL + "entryPointsTest",
                    {
                        submitObject,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: context.actions.authHeader(),
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        updateCategoryStatus();
                        setPercentage(response.data.data.points);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    console.log(error.config);
                });
        }
    };

    const goToHomepage = () => {
        // eslint-disable-next-line no-restricted-globals
        history.push("/");
    };

    useEffect(() => {
        let interval: any;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds + 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    useEffect(() => {
        if (!fire) {
            axios
                .post(
                    AppSettings.TEST_URL + "createEntryTest",
                    {
                        user_id: context.user?.user_id,
                        category_id: id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: context.actions.authHeader(),
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.status === "done") {
                            history.push("/lernmethode");
                        }
                        response.data.data
                            .forEach((e: any) => shuffleArray(e.answers));

                        setTests(shuffleArray(response.data.data));
                        setFire(true);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    console.log(error.config);
                });
        }
    }, [context.actions, context.user?.user_id]);

    return (
        <div>
            {!showValidation && (
                <div>
                    <h1 className="text-center mt-4">Eingangstest: {categories.actions.getCategory(Number(category_id))}</h1>
                    <p className="text-center lead text-muted mb-4">Teste dein Wissen!</p>
                    <div className="d-flex align-items-center testHeight " style={{backgroundColor: "#FFFFFF"}}>
                        <div className="container pb-0 align-items-center justify-content-center testContainer box-blur"
                             style={{backgroundColor: "#FFFFFF"}}>
                            {tests.length > 0 ? (
                                <Container className="d-flex" style={{ minHeight: 450 }}>
                                    {timerState && (
                                        <p className="text-primary font-weight-bolder font-weight-bold">Timer: {seconds}</p>
                                    )}
                                    <Row className="mx-auto my-auto">
                                        <Col>
                                            <Tabs className={"invisible"} activeKey={questionIndex}
                                                  id="controlled-tab-example">
                                                {tests.map((question, index) => {
                                                    return (renderQuestion(question, index))
                                                })}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Container>
                            ) : (
                                <h1></h1>
                            )}
                            <div className="container mb-4 d-flex justify-content-end">
                                <Button
                                    className="mr-2"
                                    variant="secondary"
                                    disabled={questionIndex === 0}
                                    onClick={() => setQuestionIndex((prev) => prev - 1)}
                                >
                                    Zurück
                                </Button>
                                {questionIndex !== tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex === tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            setQuestionIndex((prev) => prev + 1)
                                        }}
                                    >
                                        Weiter
                                    </Button>
                                )}
                                {questionIndex === tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex !== tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            submitHandler();
                                            setQuestionIndex(0);
                                            setModalState(true);
                                        }}
                                    >
                                        Eingabe bestätigen
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showValidation && (
                <div>
                    <h1 className="text-center mt-4">Deine Ergebnisse</h1>
                    <p className="text-center lead text-muted mb-4">Übersicht deiner Antworten</p>
                    <div className="d-flex align-items-center testHeight " style={{backgroundColor: "#FFFFFF"}}>
                        <div className="container pb-0 align-items-center justify-content-center testContainer box-blur"
                             style={{backgroundColor: "#FFFFFF"}}>
                            {tests.length > 0 ? (
                                <Container className="d-flex" style={{ minHeight: 450 }}>
                                    <Row className="mx-auto my-auto">
                                        <Col>
                                            <Tabs className={"invisible"} activeKey={questionIndex}
                                                  id="controlled-tab-example">
                                                {tests.map((question, index) => {
                                                    return (renderQuestionValidation(question, index))
                                                })}
                                            </Tabs>
                                        </Col>
                                    </Row>
                                </Container>
                            ) : (
                                <h1></h1>
                            )}
                            <div className="container mb-4 d-flex justify-content-end">
                                <Button
                                    className="mr-2"
                                    variant="secondary"
                                    disabled={questionIndex === 0}
                                    onClick={() => setQuestionIndex((prev) => prev - 1)}
                                >
                                    Zurück
                                </Button>
                                {questionIndex !== tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex === tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            setQuestionIndex((prev) => prev + 1)
                                        }}
                                    >
                                        Weiter
                                    </Button>
                                )}
                                {questionIndex === tests.length - 1 && (
                                    <Button
                                        className="success"
                                        disabled={questionIndex !== tests.length - 1 || questionIndex >= nextQuestion}
                                        onClick={() => {
                                            history.push(`/lernmethode/${id}`)
                                        }}
                                    >
                                        Zur Lernmethode
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal show={modalState}>
                <Modal.Header>
                    <Modal.Title>Eingangstest abgeschlossen!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        Du hast {percentage}% von 100% im Eingangstest erreicht.
                    </h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setModalState(false);
                        history.push(`/`);
                    }}>
                        Home
                    </Button>

                    <Button variant="secondary" onClick={() => {
                        setModalState(false);
                        history.push(`/lernmethode/${id}`);
                    }}>
                        Zur Lernmethode
                    </Button>

                    <Button variant="primary" onClick={() => {
                        setShowValidation(true);
                        setModalState(false);
                    }}>
                        Zur Übersicht
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

    function renderAnswers(answer: Answer) {
        return (
            <div key={`default-radio-${answer.answer_id}`} className="mb-3">
                <Form.Check
                    type="radio"
                    name={`${answer.question_id}`}
                    id={`${answer.answer_id}`}
                    label={answer.description}
                    onClick={() => {
                        changeHandler(answer.answer_id, questionIndex, answer.question_id);
                        validateAnswers(answer.answer_id, questionIndex, answer.description);
                        if (questionIndex >= nextQuestion) {
                            setNextQuestion(questionIndex + 1);
                        }
                    }}
                />
            </div>
        )
    }

    function renderQuestion(question: Test, index: number) {
        return (
            <Tab title="" eventKey={String(index)}>
                <div className="container d-flex mb-5 mt-4">
                    <h4 className="text-center flex-grow-1" style={{hyphens:"auto"}}>{question.description}</h4>
                    <h4 className="text-center font-weight-bold ml-3">{questionIndex + 1}/{tests.length}</h4>
                </div>
                <Form>
                    {question.answers.map((answer) => {
                        return (renderAnswers(answer))
                    })}
                </Form>
            </Tab>
        )
    }

    function renderQuestionValidation(question: Test, index: number) {
        return (
            <Tab title="" eventKey={String(index)}>
                <div className="container d-flex mb-5 mt-4">
                    <h4 className="text-center flex-grow-1">{question.description}</h4>
                    <h4 className="text-center font-weight-bold ml-3">{questionIndex + 1}/{tests.length}</h4>
                </div>
                <Form>
                    {question.answers.map((answer) => {
                        return (renderAnswerValidation(answer, question.right_answer, index))
                    })}
                </Form>
            </Tab>
        )
    }

    function renderAnswerValidation(answer: Answer, rightAnswer: number, index: number) {
        let selectedAnswer = testReview[index][0];
        let check = false;
        let color;
        if (selectedAnswer === answer.description) {
            check = true;
        }

        if (answer.answer_id === rightAnswer) {
            color = "blue";
        } else {
            color = "red";
        }

        return (
            <div className="container d-flex">
                <div className="text-center">
                    {color == "blue" && (
                        <Check/>
                    )}
                    {color == "red" && (
                        <X/>
                    )}
                </div>
                <div key={`default-radio-${answer.answer_id}`} className="mb-3 flex-grow-1">
                    <Form.Check type="radio" id={`${answer.answer_id}` + 1000}>
                        <Form.Check.Input type="radio" disabled checked={check}/>
                        <Form.Check.Label
                            style={{color: `${color}`}}
                        >{answer.description}</Form.Check.Label>
                    </Form.Check>
                </div>
            </div>
        )
    }

    function validateAnswers(answerId: number, indexQuestion: number, answerDescription: string) {
        const values = [...radio];
        values[indexQuestion] = answerId;

        if (tests[indexQuestion].right_answer === answerId) {
            testReview[indexQuestion] = [answerDescription, true];
        } else {
            testReview[indexQuestion] = [answerDescription, false];
        }
        setQuestionAnswer(values);
        validateCorrectAnswers();
    }

    function validateCorrectAnswers() {
        let counter = 0;
        {
            testReview.map((question) => {
                if (question[1] == true) {
                    counter++;
                }
            })
        }
        setCorrectAnswers(counter);
    }
};
