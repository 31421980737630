export const sichererUmgangImNetz = `
# Sicherer Umgang im Netz

## Definitionen
- Cookies
- Sicherer Umgang im Netz 
- Pop-Ups
- 2-Faktor-Authentifizierung
- Scammer
- Phishing
- Definition Mouseover
- Definition QR-Codes

## Grundlegende Schutzmaßnahmen 
- Allgemeine Schutzmaßnahmen
- Viren und Malewareschutz
- Phishing-Präventation
- Merkmale von Phishing-Mails
- Merkmale von unsicheren Websiten
-  Erkennen & Schutz vor Quishing
- Merkmale von Fake-Shops
- Erkennen und Schützen vor Scamming
  - Allgemeine Schutzmaßnahmen vor Scamming 
  - Erkennen von Scamming 
  - Schutz vor Romance-Scamming 
  - Erkennen von Romance-Scamming
  
## Risiken und Gefahren im Online Bereich 
- Allgemeine Risiken und Gefahren im Online-Bereich
- Cyberkriminalität 
- Cybergrooming 
- Datenschutzverletzungen 
- Scamming 
  - Allgemeine Informationen über Scamming 
  - Romance-Scamming 
  - Fake-Shops
  - Fake-Gewinnversprechen
  - Quishing
`;