export const ddos = `
# DDoS

## Definitionen
- Definition DDoS
- Definition DDoS Mailbombing
- Definition DoS
- Vorgehensweise von DDoS-Angriffen

## Risiken von DDoS-Angriffen
- Wirtschaftliche Schäden
- Imageschäden

## Erkennen & Schutz vor DDoS-Angriffen
- Erkennung von DDoS Angriffen
- Allgemeine Schutzmaßnahmen
- DNS-Forwarding\t
- BGP-Routing

## Beispiele von DDoS-Angriffen
- Syn flooding
- Ping flooding\t
- Mail bombing\t

## Formen von DoS-Angriffen
- Syn-Flooding
- Ping-Flooding
- Mailbombing

`;