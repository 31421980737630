import React from "react";

export type CardElementProps = {

}

export const PrivacyPolicyText: React.FC<CardElementProps> = () => {
    return (
        <>
            <div className="dsvgoText">
                <p>
                    <b>In Kraft seit: 12. August 2021</b> <br/>
                    <b>letzte Änderung: 01. April 2024</b>
                </p>

                <h2>Allgemeine Vorbemerkungen </h2>
                <p>Diese Datenschutzerklärung umfasst die Grundsätze der Datenverarbeitung an der Hochschule Darmstadt
                    sowie die Datenerhebungen über ihre Webseite.
                    Durch die Lernplattform des Forschungsprojektes KI-basierte Lernplattform der
                    nächsten Generation für Cybersicherheit und IT-Awareness der Hochschule Darmstadt wird
                    IT-Awareness-Wissen vermittelt. </p>

                <h2>Verantwortung und Zuständigkeiten</h2>
                <p>Für den Betrieb sowie die Richtigkeit und Aktualität der Informationen wird keine Gewähr übernommen.
                    Die Abteilung IT-Dienste und -Anwendungen der Hochschule Darmstadt ist für den Betrieb des Servers
                    zuständig. Für die Organisation in den einzelnen Fachbereichen sind die jeweiligen Redakteure
                    verantwortlich. Das Präsidium hat die allgemeine inhaltliche Verantwortung und entscheidet in
                    Zweifelsfällen über die Zulässigkeit der Daten.
                    Die Hochschulkommunikation pflegt die Einstiegseiten des zentralen Auftritts. Im Übrigen sind die
                    Abteilungen, Fachbereiche und Institutionen der Hochschule für die dargestellten Inhalte selbst
                    verantwortlich.
                    Umgang mit Daten
                    Die Hochschule Darmstadt nimmt den Schutz von personenbezogenen Daten sehr ernst. Wir verarbeiten
                    personenbezogene Daten, die beim Besuch unserer Webseiten erhoben werden, unter Beachtung der
                    geltenden datenschutzrechtlichen Bestimmungen. Es gelten insbesondere die
                    EU-Datenschutzgrundverordnung (DS-GVO), das Hessische Datenschutz- und Informationsfreiheitsgesetz
                    (HDSIG) und das Telemediengesetz (TMG).
                    Im Folgenden unterrichten wir Sie über Art, Umfang und Zwecke der Erhebung und Verwendung
                    personenbezogener Daten.
                    Ihre Daten werden von uns weder veröffentlicht, noch unberechtigt an Dritte weitergegeben. </p>

                <h2>1. Datenerhebung und -verarbeitung bei Zugriffen aus dem Internet</h2>
                <p> Wenn Sie unsere Webseiten besuchen, speichern unsere Webserver automatisch jeden Zugriff in einer
                    Protokolldatei. Diese Daten werden getrennt von anderen Daten, die Sie im Rahmen der Nutzung unseres
                    Angebotes eingeben, gespeichert. Eine Zuordnung dieser Daten zu einer bestimmten Person ist uns
                    nicht möglich. Diese Daten dienen ausschließlich der Überprüfung und Sicherung eines zuverlässigen
                    technischen Betriebs des Webservers. Diese Daten werden nach einer Vorhaltezeit von 7 Tagen
                    gelöscht.
                    Folgende Daten werden erfasst:
                </p>

                <ul>
                    <li><b>IP-Adresse</b></li>
                    <li><b>Verwendeter Browser</b></li>
                    <li><b>Datum und Uhrzeit des Zugriffs</b></li>
                    <li><b>Name und URL der abgerufenen Datei</b></li>
                    <li><b>Angeforderte URL</b></li>
                    <li><b>Übertragene Datenmenge</b></li>
                    <li><b>Zugriffsstatus des Web-Servers (Datei übertragen, Datei nicht gefunden, Kommando nicht
                        ausgeführt, etc.)</b></li>
                </ul>

                <p>Der Login beim Zugang zu geschützten Bereichen wird teilweise protokolliert, um Missbrauchsversuche
                    und Passwortattacken erkennen zu können. Es werden dabei keine Daten gespeichert, mit deren Hilfe
                    personenbezogene Profile über das Nutzerverhalten erstellt werden.
                    Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 b) der DS-GVO.
                </p>

                <h2>2. Nutzung und Weitergabe personenbezogener Daten</h2>
                <p>Um die Dienste der Lernplattform (z.B. Ausstellung von Zertifikaten) erbringen zu können, erheben wir
                    Ihre personenbezogenen Daten. Jegliche Nutzung Ihrer personenbezogenen Daten erfolgt nur zu den
                    genannten Zwecken und in dem zur Erreichung dieser Zwecke erforderlichen Umfang. Bitte beachten Sie,
                    dass wir Nutzerdaten auch für interne Zwecke nutzen, einschließlich dem Anlernen und der
                    kontinuierlichen Verbesserung unserer KI-basierten Lernplattform. Diese Daten werden anonymisiert
                    und aggregiert verwendet, um die Effektivität unserer Lernmodelle zu verbessern und personalisierte
                    Lernerfahrungen zu ermöglichen. Darüber hinaus können Ihre Daten für Trainingszwecke innerhalb
                    unserer Plattform verwendet werden, um die Qualität und Relevanz unserer Lerninhalte zu optimieren.
                    Ihre Privatsphäre und der Schutz Ihrer Daten bleiben dabei stets unsere oberste Priorität, und wir
                    verpflichten uns, alle gesammelten Daten gemäß den geltenden Datenschutzgesetzen zu behandeln. </p>

                <h3>2.1 Folgende Personenbezogene Daten werden gesammelt:</h3>
                <ul>
                    <li><b>Erhebung von Daten zum Aufbau des Benutzerprofils:</b></li>
                    <ul>
                        <li><b>Name und Nachname des Nutzers</b></li>
                        <li><b>Lerntyp und Spielertyp eines Nutzers</b></li>
                        <li><b>Gestellte Fragen an die KI-Chatbots</b></li>
                        <li><b>E-Mail des Nutzers</b></li>
                        <li><b>Alter des Nutzers</b></li>
                        <li><b>Berufsbezeichnung und ggf. Studiengang</b></li>
                        <li><b>Geschlecht des Nutzers</b></li>
                    </ul>

                    <li><b>Erhebung von Nutzungsdaten der Lernplattform:</b></li>
                    <ul>
                        <li><b>Zeipunkt des Plattformzugriffs</b></li>
                        <li><b>Dauer der Sitzung</b></li>
                        <li><b>Dauer der Bearbeitung von Lernmodulen</b></li>
                        <li><b>Aufgerufene Lernmaterialien und Kurse</b></li>
                        <li><b>Gestellte Fragen an die KI-Chatbots</b></li>
                        <li><b>Anzahl der Interaktionen mit den KI-Chatbots</b></li>
                        <li><b>Häufigkeit und Muster der Plattformnutzung</b></li>
                    </ul>

                    <li><b>Feedback und Bewertung:</b></li>
                    <ul>
                        <li><b>Geschriebene Kommentare oder Rückmeldungen</b></li>
                        <li><b>Angaben zu bevorzugten oder weniger bevorzugten Aspekten der Plattform</b></li>
                        <li><b>Bewertungen der verwendeten Lernmethoden</b></li>
                        <li><b>Vorschläge zur Verbesserung von Inhalten oder Funktionen</b></li>
                        <li><b>Hinweise auf technische Probleme oder Schwierigkeiten bei der Nutzung</b></li>
                    </ul>

                    <li><b>Erhebung von Leistungsdaten:</b></li>
                    <ul>
                        <li><b>Ergebnisse von abgeschlossenen Tests, Quizzen oder Prüfungen</b></li>
                        <li><b>Fortschritt in abgeschlossenen Lernmodulen</b></li>
                        <li><b>Lernzuwachs der Nutzer bei Abschluss der Kurse</b></li>
                    </ul>
                </ul>

                <p>Keine dieser Daten werden ohne vorherige Einwilligung des Nutzers an Dritte weitergegeben. Die
                    übermittelten Daten werden in einer Datenbank gespeichert, die nur Administratoren zugänglich ist.
                    Übermittlungen personenbezogener Daten an staatliche Behörden erfolgen nur im Rahmen zwingender
                    nationaler Rechtsvorschriften. <br/>
                    Rechtsgrundlage der Verarbeitung sind Art. 6 Abs. 1 b) (Vertragserfüllung) und c) (gesetzliche
                    Vorgaben) der DS-GVO.
                    Soweit Sie uns Ihre Einwilligung zur Datenverarbeitung (etwa über eine Kontakformular oder eine
                    Newsletteranforderung) gegeben haben, werden Ihre Daten nur zu den dort festgelegten Zwecken
                    verarbeitet.
                    Rechtsgrundlage der Verarbeitung sind Art. 6 Abs. 1 a) der DS-GVO (Einwilligung).
                </p>
                <h2>3. Sicherheit</h2>

                <p>Unsere technisch-organisatorischen Sicherheitsmaßnahmen, mit denen wir sämtliche Daten vor dem
                    Zugriff Unberechtigter schützen, werden stets auf dem aktuellen technischen Stand gehalten.
                    Personenbezogene Informationen werden grundsätzlich in verschlüsselter Form übertragen. </p>

                <h2>4. Links zu Webseiten anderer Anbieter</h2>
                <p>Unsere Webseite können Links zu Webseiten anderer Anbieter enthalten. Wir weisen darauf hin, dass
                    diese Datenschutzerklärung ausschließlich für die Webseite der Lernplattform des
                    Forschungsprojekte KI-basierte Lernplattform der nächsten Generation der Hochschule Darmstadt gilt.
                    Wir haben keinen Einfluss darauf und kontrollieren nicht, dass andere Anbieter die geltenden
                    Datenschutzbestimmungen einhalten. </p>

                <h2>5. Recht auf Auskunft, Berichtigung, Sperrung oder Löschung von Daten</h2>
                <p>Ihnen als von der Datenverarbeitung betroffenen Person stehen verschiedene Rechte zu:</p>
                <ul>
                    <li><b>Widerrufsrecht bei Einwilligungen: Von Ihnen erteilte Einwilligungen können Sie jederzeit
                        uns
                        gegenüber widerrufen. Die Datenverarbeitung, die auf der widerrufenen Einwilligung beruht,
                        darf
                        dann für die Zukunft nicht mehr fortgeführt werden.</b></li>
                    <li><b>Auskunftsrecht: Sie können Auskunft über Ihre von uns verarbeiteten personenbezogenen
                        Daten
                        verlangen. Dies gilt insbesondere für die Zwecke der Datenverarbeitungen, die Kategorien der
                        personenbezogenen Daten, ggf. die Kategorien von Empfängern, die Speicherdauer, ggf. die
                        Herkunft Ihrer Daten sowie ggf. für das Bestehen einer automatisierten Entscheidungsfindung
                        einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Details.</b></li>
                    <li><b>Berichtigungsrecht: Sie können die Berichtigung unrichtiger oder die Vervollständigung
                        Ihrer
                        bei uns gespeicherten personenbezogenen Daten verlangen.</b></li>
                    <li><b>Löschungsrecht: Sie können die Löschung Ihrer bei uns gespeicherten personenbezogenen
                        Daten
                        verlangen, soweit deren Verarbeitung nicht zur Ausübung des Rechts auf freie
                        Meinungsäußerung
                        und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen
                        Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                        erforderlich
                        ist. </b></li>
                    <li><b>Recht auf Einschränkung der Verarbeitung: Sie können die Einschränkung der Verarbeitung
                        Ihrer personenbezogenen Daten verlangen, soweit die Richtigkeit der Daten von Ihnen
                        bestritten
                        wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen. Außerdem steht
                        Ihnen
                        dieses Recht zu, wenn wir die Daten nicht mehr benötigen, Sie diese jedoch zur
                        Geltendmachung,
                        Ausübung oder Verteidigung von Rechtsansprüchen benötigen. Darüber hinaus haben Sie dieses
                        Recht, wenn Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten eingelegt
                        haben.</b></li>
                    <li><b>Recht auf Datenübertragbarkeit: Sie können verlangen, dass wir Ihnen Ihre
                        personenbezogenen
                        Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
                        maschinenlesebaren Format übermitteln. Alternativ können Sie die direkte Übermittlung der
                        von
                        Ihnen uns bereitgestellten personenbezogenen Daten an einen anderen Verantwortlichen
                        verlangen,
                        soweit dies möglich ist. </b></li>
                    <li><b>Beschwerderecht: Sie können sich bei der für uns zuständigen Aufsichtsbehörde
                        beschweren,
                        z.B. wenn Sie der Ansicht sind, dass wir Ihre personenbezogenen Daten in unrechtmäßiger
                        Weise
                        verarbeiten. </b></li>
                </ul>

                <p>Die zuständige Aufsichtsbehörde ist:<br/>
                    Der Hessische Datenschutzbeauftragte Gustav-Stresemann-Ring 1 65189 Wiesbaden Telefon: 0611
                    1408-0 E-Mail: poststelle@datenschutz-hessen.de Internet: www.datenschutz.hessen.de <br/>
                    Sofern wir Ihre personenbezogenen Daten auf Basis eines berechtigten Interesses verarbeiten,
                    haben Sie das Recht, Widerspruch gegen diese Verarbeitung einzulegen. Möchten Sie von Ihrem
                    Widerspruchsrecht Gebrauch machen, genügt eine Mitteilung in Textform. Sie können uns also
                    gerne
                    anschreiben, ein Fax schicken oder sich per E-Mail an uns wenden. <br/>
                    Der Datenschutzbeauftragte der Hochschule Darmstadt ist unter datenschutz@h-da.de
                    erreichbar. </p>

                <h2>6. Gültigkeit und Aktualität der Datenschutzerklärung</h2>
                <p>Mit der Nutzung unserer Webseite willigen Sie in die oben beschriebene Datenverwendung ein.
                    Diese Datenschutzerklärung ist unmittelbar gültig und ersetzt alle früheren Erklärungen. </p>
            </div>
        </>
    )
}