export const cybergrooming = `
# Cybergrooming (Internetpädophilie)
## <br> Definition
## <br> Anwendungsumgebung
## <br> Merkmale
- Opfermerkmale
- Tätermerkmale
## <br> Motive
- Webcamsex
- Nacktbilder
## <br> Erkennen
- viele Komplimente
- Verständnis für alles
- Kamera kaputt bei Videocalls
## <br> Strafrecht
- erlaubt/legal
- nicht erlaubt/illegal
## <br> Gegenmaßnahme
`;