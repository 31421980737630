export const cybermobbing = `
# Cybermobbing und -stalking
## <br> Definition
- Cybermobbing
- Cyberstalking
## <br> Vorgehensweise
## <br> Betroffene
- Cybermobbing
- Cyberstalking
## <br> Rechtslage
- erlaubt/legal
- nicht erlaubt/illegal
## <br> Schutz
- Chatfilter
- Täter blockieren
`;