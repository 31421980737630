import React, {useContext} from "react";
import {Container} from "react-bootstrap";
import {userContext} from "../contexts/UserContext";

const UsersTable: React.FC = () => {
  return (
      <Container className="mt-5">
      </Container>
  );
};

export default UsersTable;
