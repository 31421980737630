export const ausspaehenVonDaten = `
# Ausspähen von Daten
## <br> Definition
## <br> Daten schützen
## <br> Datenmissbrauch
## <br> Identitätsdiebstahl
- geheime Daten
- personenbezogene Daten
## <br> Verboten
## <br> Rechtslage
- erlaubt/legal
- nicht erlaubt/illegal
- Strafmaß
`;