export const socialEngineering = `
# Sicherer Umgang im Netz 
## Einführung in Social Engineering 
- Psychologische Aspekte des Social Engineerings 
- Definition von Social Engineering
 
## Typen von Social Engineering
- Pretexting
- Baiting
- Phishing
- Tailgating
- Reverse Social Engineering
- Vishing
 
## Häufige Social Engineering-Angriffe 
- Beispiel für Social Engineering  
- Beispiel für Reverse Social Engineering 
- Beispiel für Vishing  
- Beispiel für Tailgating  
 
## Erkennen & Schutzmaßnahmen von Social Engineering 
- Allgemeine Schutzmaßnahmen 
- Verdächtige E-Mails identifizieren
- Umgang mit verdächtigen Anrufen
 
`;