export const catfishing = `
# Catfishing
## <br> Definition
## <br> Anwendungsumgebung
- Instagram
- Facebook
- Twitter
## <br> Erkennung
## <br> Motive
- Geldbetrug
- Mobbing
- Rache
- Macht
- Testen
- Schüchternheit
- Langeweile
## <br> Rechtslage
- erlaubt/legal
- nicht erlaubt/illegal
## <br> Gegenmaßnahme
`;