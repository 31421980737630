import React from "react";

type BarProp = {
    bgcolor: string;
    completed: number;
}
export const ProgressBar = ({ bgcolor, completed }: BarProp) => {
    // const { bgcolor, completed } = props;

    return (

        <div className="mb-3" style={{
            alignContent: 'center',
            height: 25,
            width: '100%',
            backgroundColor: "#e0e0de",
            borderRadius: 50,
        }}>
            <div style={{
                height: '100%',
                width: `${completed}%`,
                backgroundColor: bgcolor,
                borderRadius: 'inherit',
                textAlign: 'center'
            }
            }>
                <span style={{
                    userSelect: 'none',
                    padding: 5,
                    color: 'white',
                    fontWeight: 'bold'
                }}>{`${completed}%`}</span>
            </div>
        </div >
    );
};

// export default ProgressBar;
