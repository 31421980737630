import React, { useEffect, useState } from "react";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import { userContext } from "../contexts/UserContext";
import { statusContext } from "../contexts/StatusContext";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {UserStatus} from "../models/User";
import { FiSettings } from "react-icons/fi";

const Navbars: React.FC = () => {
	const context = useContext(userContext);
	const statContext = useContext(statusContext)
	const history = useHistory();
	const [userStatus, setUserStatus] = useState<UserStatus[]>(context.status);
	const [userState, setUserState] = useState({
		showUserBoard: false,
		showAdminBoard: false,
	});

	useEffect(() => {
		setUserStatus(context.status)
	}, [context.status]);

	useEffect(() => {
		setUserStatus(statContext.status)
	}, [statContext.status]);

	useEffect(() => {
		if (context) {
			if (context.user?.user_rights_id.includes("admin")) {
				setUserState({
					showUserBoard: true,
					showAdminBoard: true,
				});
			}
			if (context.user?.user_rights_id.includes("user")) {
				setUserState({
					showUserBoard: true,
					showAdminBoard: false,
				});
			}
		} else {
			setUserState({
				showUserBoard: false,
				showAdminBoard: false,
			});
		}
	}, [context]);

	const HandleLogout = () => {
		setUserState({
			showUserBoard: false,
			showAdminBoard: false,
		});
		context.actions.logout();
		history.push("/");
		window.location.reload();
	};

	function renderTopics() {
		let topicsAtoH: UserStatus[] = [];
		let topicsItoP: UserStatus[] = [];
		let topicsQtoZ: UserStatus[] = [];


		userStatus.sort(function(a, b) {
			var textA = a.category.category_name.toUpperCase();
			var textB = b.category.category_name.toUpperCase();
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		})

		userStatus.forEach((entry, idx, array) => {
			if (Array.from(entry.category.category_name)[0].match(/^[a-hA-H]+$/)) {
				topicsAtoH.push(entry);
			}
			else if (Array.from(entry.category.category_name)[0].match(/^[i-pI-P]+$/)) {
				topicsItoP.push(entry)
			}
			else if (Array.from(entry.category.category_name)[0].match(/^[q-zQ-Z]+$/)) {
				topicsQtoZ.push(entry)
			}
		});
		let html = [];
		html.push(renderDropdowns(topicsAtoH, "(A-H)"));
		html.push(renderDropdowns(topicsItoP, "(I-P)"));
		html.push(renderDropdowns(topicsQtoZ, "(Q-Z)"));
		return html;
	}

	function renderDropdowns(priorityOne: UserStatus[], topicName: any) {
		return (
			<NavDropdown title={"Themen " + topicName} id={"collasible-nav-dropdown-" + topicName}>
				{priorityOne.map((entry) => {
					let title = entry.category.category_name
					let link = openTopicLink(entry.user_status_id, entry.category.category_id)
					return (
						<NavDropdown.Item as={Link} id={"-nav-dropdown-item" + entry.category.category_id} onClick={() => setExpanded(false)} to={"/" + link}>
							{title}
						</NavDropdown.Item>
					);
				})}
			</NavDropdown>
		);
	}

	function openTopicLink(userIdStatusId:number, catId:number) {
		const index = userStatus.findIndex(s => s.user_status_id === userIdStatusId)
		if (userStatus[index].status === "1") return("eingangstest/" + catId)
		else if (userStatus[index].status === "2") return("lernmethode/" + catId)
		else if (userStatus[index].status === "3") return("lernmethode/" + catId)
		else return <p>Something went wrong</p>;
	}

	const [expanded, setExpanded] = useState(false);

	return (
		<Navbar expanded={expanded} collapseOnSelect expand="lg" fixed="top" bg="dark" variant="dark">
			<Navbar.Brand as={Link} onClick={() => setExpanded(false)} to="/">
				SecureBot - Lernplattform
			</Navbar.Brand>
			<Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav"/>
			<Navbar.Collapse id="responsive-navbar-nav">
				{userState.showUserBoard && context.user && (
					<Nav>
						<Nav.Link as={Link} onClick={() => setExpanded(false)} to="/">
							Startseite
						</Nav.Link>
						{/*{ context.status && (renderTopics())}*/}
						<Nav.Link as={Link} to="/quiz">
							Gaming-Quiz
						</Nav.Link>

						<Nav.Link as={Link} onClick={() => setExpanded(false)} to="/statistik">
							Statistik
						</Nav.Link>

						<Nav.Link as={Link} onClick={() => setExpanded(false)} to="/OnlineUmfrage">
							Feedback
						</Nav.Link>
					</Nav>
				)}
				<Nav>
					{userState.showAdminBoard && context.user && (
						<NavDropdown title="Admin-Tools" id="collasible-nav-dropdown">
							<NavDropdown.Item as={Link} to="/kategorien">
								Kategorien
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/fragen">
								Fragen
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/antworten">
								Antworten
							</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item as={Link} to="/benutzer">
								Benutzer
							</NavDropdown.Item>
						</NavDropdown>
					)}
				</Nav>
				{!context.user && (
					<Nav className="ml-auto">
						<Nav.Link as={Link} onClick={() => setExpanded(false)} to="/login">
							Login
						</Nav.Link>
						<Nav.Link as={Link} onClick={() => setExpanded(false)} to="/registrieren">
							Registrieren
						</Nav.Link>
					</Nav>
				)}
				{context.user && (
					<Nav className="ml-auto">
						<Navbar.Text>
							<p className="m-0 p-0">
								{" "}
								Willkommen: {context.user.nickname} &nbsp;
							</p>
						</Navbar.Text>
						<Button onClick={() => HandleLogout()} variant="outline-light">
							Logout
						</Button>
						<Nav.Link as={Link} onClick={() => setExpanded(false)} to="/einstellungen">
							<FiSettings size={28}></FiSettings>
						</Nav.Link>
					</Nav>
				)}
			</Navbar.Collapse>
		</Navbar>
	);
};


export default Navbars;
