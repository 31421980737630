import React, {useContext} from "react";
import {IoReloadOutline} from "react-icons/io5";
import {QuizContext} from "../../contexts/QuizContext";
import {useHistory} from "react-router-dom";

export const SuccessPage = () => {
    const context = useContext(QuizContext);
    const handleRestart = context.actions.resetStartEndQuiz;
    const successPoints = context.points;
    const successHighscore = context.highscore;
    const history = useHistory();

    const goToHomepage = () => {
        history.push("/");
    };

    return (
        <>
            <div id="success-container">
                <div id="success-text-container">
                    <div>
                        <h4>
                            <img
                                src="/images/quiz/trophy.png"
                                alt=""
                                style={{
                                    width: "8%",
                                }}
                            />Gut gemacht!
                            <img
                                src="/images/quiz/trophy.png"
                                alt=""
                                style={{
                                    width: "8%",
                                }}
                            />
                        </h4>
                        <br/>
                    </div>

                    <div>
                        <h4>Erreichte Punktzahl: {successPoints}</h4>
                    </div>

                    <div>
                        <h4>Persönlicher Highscore: {successHighscore}</h4>
                    </div>
                </div >

            </div>
            <div className="leftTextBox3 justify-content-end zuruck-container">
                <button className="general-quiz-btn btn-secondary" onClick={()=>{
                    goToHomepage()
                }}>Zurück</button>
                <button className="general-quiz-btn btn-primary" onClick={handleRestart}>Neustart</button>
            </div>
        </>
    );
};
