import {Button, Col, Dropdown, Modal, OverlayTrigger, Row, Tooltip, Container} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AppSettings} from "../config";
import {PrivacyPolicyText} from "../components/PrivacyPolicyText";
import {AiOutlineCheckCircle, BiCircle} from "react-icons/all";
import {BsStar, BsStarFill} from "react-icons/bs";
import {userContext} from "../contexts/UserContext";
import {FilterOptions, SortOptions, UserStatus} from "../models/User";
import {Link, useHistory} from "react-router-dom";
import {ProgressBar} from "../components/ProgressBar";
import axios from "axios";

export const HomePage: React.FC = () => {
	const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
	const context = useContext(userContext);
	// const [userID] = useState<any>(context.user!.user_id);
	const history = useHistory();
	const [prioProgress, setPrioProgress] = useState([]);
	const [prioToolTip, setPrioTooltip] = useState([]);
	const [topicProgress, setTopicProgress] = useState(0);
	const [topicTooltip, setTopicTooltip] = useState("");
	const [gaveFeedback, setgaveFeedback] = useState(false);
	const [feedBackNotGiven, setFeedbackNotGiven] = useState(false);
	const [userStatus, setUserStatus] = useState<UserStatus[]>(context.status);
	const [showMorePrioTwoText, setShowMorePrioTwoText] =
		useState("Mehr anzeigen");
	const [showMorePrioTwo, setShowMorePrioTwo] = useState(false);
	const [showMoreIndex] = useState(3);
	const [collapsePrioTwoTopics, setCollapsePrioTwoTopics] = useState(false);
	const [collapsePrioThreeTopics, setCollapsePrioThreeTopics] = useState(false);
	const [topicSearchValue, setTopicSearchValue] = useState("");
	const [filterOptions, setFilterOptions] = useState<FilterOptions>(context.filterOptions);
	const [sortOptions, setSortOptions] = useState<SortOptions>(context.sortOptions);
	const [showDropdown, setShowDropdown] = useState(false);
	const [loading, setLoading] = useState(false);
	const target = useRef(null);


	const [userState, setUserState] = useState({
		showUserBoard: false,
		showAdminBoard: false,
	});

	const renderTooltip = (str: string) => (
		<Tooltip id="button-tooltip">{str}</Tooltip>
	);

	useEffect(() => {
		if (context.user) {
			axios.get(AppSettings.USER_STATUS + context.user?.user_id,{
				headers: {
					"Content-Type": "application/json",
					Authorization: context.actions.authHeader()
				}
			})
				.then((response) => {
					if (response.status === 200) {
						setUserStatus(response.data.data);
						setLoading(true);
						let gavethefeedback = false;
						//because it does not have its own column in user table 😞
						axios.get(AppSettings.FEEDBACK + context.user?.user_id, {
							headers: {
								"Content-Type": "application/json",
								Authorization: context.actions.authHeader()
							}
						})
							.then((response) => {
								if (response.status === 200 && response.data.data === 1) {
									gavethefeedback = true;
									setgaveFeedback(gavethefeedback);
									setFeedbackNotGiven(false);
								} else {
									gavethefeedback = false;
									let len = userStatus.filter(
										(e: any) => parseInt(e.status) === 3
									).length;
									if (len === 23 && gavethefeedback === false) {
										setFeedbackNotGiven(true);
									}
								}
							});
					}
				})
				.catch((error) => {
					if (error.response) {
						//console.error
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log("Error", error.message);
					}
					console.log(error.config);
				});
		}
	}, [context.user?.user_id]);

	useEffect(() => {
		//change from context check to context.user check
		if (context.user) {
			axios.get(AppSettings.USER_STATS + context.user!.user_id, {
				headers: {
					"Content-Type": "application/json",
					Authorization: context.actions.authHeader()
				}
			}).then((res) => {
				setPrioProgress(res.data.prio_percent_completion);
				setPrioTooltip(res.data.prio_completion_tooltip_helper);
				setTopicProgress(res.data.topic_percent_completion);
				setTopicTooltip(res.data.topic_completion_tooltip_helper);
			});
			if (context.user?.user_rights_id.includes("admin")) {
				setUserState({
					showUserBoard: true,
					showAdminBoard: true,
				});
			}
			if (context.user?.user_rights_id.includes("user")) {
				setUserState({
					showUserBoard: true,
					showAdminBoard: false,
				});
			}
		} else {
			setUserState({
				showUserBoard: false,
				showAdminBoard: false,
			});
		}
	}, [context]);

	const toggleButtonText = (text: string) => {
		if (text === "Mehr anzeigen") {
			setShowMorePrioTwoText("Weniger anzeigen");
		} else {
			setShowMorePrioTwoText("Mehr anzeigen");
		}
	};

	const toggleFavorite = (event:any, id:number) => {
		event.cancelBubble = true;
		if(event.stopPropagation) event.stopPropagation();

		let user_status_id: number;
		userStatus.forEach((value) => {
			if (value.category.category_id === id) {
				user_status_id = value.user_status_id;
				axios
					.put(
						AppSettings.USER_STATUS + user_status_id,
						{
							status: value.status,
							is_favorite: !value.is_favorite,
						},
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: context.actions.authHeader(),
							},
						}
					)
					.then((response) => {
						if (response.status === 200) {
							value.is_favorite = !value.is_favorite;
							setUserStatus([...userStatus])
						}
					})
					.catch((error) => {
						if (error.response) {
							//console.error
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						} else if (error.request) {
							console.log(error.request);
						} else {
							console.log("Error", error.message);
						}
						console.log(error.config);
					});
			}
		});
	}

	const handleFilterSelection = (filterOption:string, filterOptionIndex:number) => {
		if (filterOption === "Favorite") {
			filterOptions.favorite = !filterOptions.favorite;
		} else if (filterOption === "Status") {
			filterOptions.status[filterOptionIndex] = !filterOptions.status[filterOptionIndex];
		}
		const updatedFilterOptions = {...filterOptions};
		setFilterOptions(updatedFilterOptions);
	}

	const handleSortSelection = (sortOption:string) => {
		if (sortOption === "Favorite") {
			sortOptions.favorite = !sortOptions.favorite;
		} else if (sortOption === "Alphabet") {
			sortOptions.alphabet = !sortOptions.alphabet;
			if(sortOptions.alphabet) sortOptions.status = false;
		} else if (sortOption === "Status") {
			sortOptions.status = !sortOptions.status;
			if(sortOptions.status) sortOptions.alphabet = false;
		}
		const updatedSortOptions = {...sortOptions};
		setSortOptions(updatedSortOptions);
	}

	if (
		window.location.toString() !== AppSettings.AFTER_FINISH_URL + "fragender" ||
		window.location.toString() !== AppSettings.AFTER_FINISH_URL + "befragter"
	) {
		document.querySelector("body > div.WatsonAssistantChatHost")?.remove();
	}

	function renderTopics() {
		let topics: UserStatus[] = [];

		//filterTopics
		userStatus.forEach((entry) => {
			if (entry.category.category_name.toLowerCase().includes(topicSearchValue.toLowerCase())) {
				if (filterOptions.favorite) {
					if (entry.is_favorite && filterOptions.status[parseInt(entry.status) - 1]) {
						topics.push(entry);
					}
				} else {
					if (filterOptions.status[parseInt(entry.status) - 1]) {
						topics.push(entry);
					}
				}
			}
		});

		if(sortOptions.alphabet) {
			topics = topics.sort((a, b) => a.category.category_name.localeCompare(b.category.category_name));
		} else if(sortOptions.status) {
			//Sortieren nach Status: In Bearbeitung -> Offen -> Fertig
			topics = topics.sort((a, b) => ((a.status !== "2" && b.status === "2" || a.status === "3" && b.status !== "3") ? 1 : ((a.status === b.status) ? 0 : -1)));
		}
		if(sortOptions.favorite) {
			topics = topics.sort((a, b) => ((!a.is_favorite && b.is_favorite) ? 1 : (a.is_favorite === b.is_favorite) ? 0 : -1));
		}

		let html = [];
		html.push(renderTopicsHelper(topics));
		return html;
	}

	function openTopicLink(userStatus: UserStatus) {
		switch (userStatus.status) {
			case "1":
				history.push("/eingangstest/" + userStatus.category.category_id);
				break;
			case "2":
				history.push("/lernmethode/" + userStatus.category.category_id);
				break;
			case "3":
				history.push("/lernmethode/" + userStatus.category.category_id);
				break;
			default:
				return <p>Something went wrong</p>;
		}
	}
	function renderCard(userStatus: UserStatus) {
		return (
			<div className="col-md-4" key={userStatus.category.category_id}>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={
						userStatus.status === "3" ? (
							<Tooltip id={`tooltip`}>Thema bereits abgeschlossen!</Tooltip>
						) : (
							<div />
						)
					}
				>
					<div
						className="card mb-4 box-shadow borderTheme topic-card"
						ref={target}
						onClick={() => {
							openTopicLink(userStatus)
						}}
					>
						<img
							className="card-img-top img-thumbnail topic-card-image"
							src={"/images/topics" + userStatus.category.image}
							alt={`${userStatus.category.category_name}`}
						/>

						<BsStarFill className="favorite-icon-background"/>
						{userStatus.is_favorite ?
							<BsStarFill className="favorite-icon" onClick={event => toggleFavorite(event, userStatus.category.category_id)}/>
							: <BsStar className="favorite-icon" onClick={event => toggleFavorite(event, userStatus.category.category_id)}/>
						}

						<div className="card-body">
							<div className="d-flex justify-content-between align-items-center">
								<p className="card-text">{userStatus.category.category_name}</p>
								{userStatus.status === "1" && loading && (
									<small className="text-danger">Offen</small>
								)}
								{userStatus.status === "2" && loading && (
									<small className="text-warning">In Bearbeitung</small>
								)}
								{userStatus.status === "3" && loading && (
									<small className="text-success">Fertig</small>
								)}
								{!loading && (
									<small
									className="spinner-border spinner-border-sm ml-5"
									role="status"
									aria-hidden="true"
									/>
									)}
									</div>
									</div>
									</div>
									</OverlayTrigger>
									</div>
									);
								}

	function renderTopicsHelper(topics: UserStatus[]) {
		return (
			<div className="album py-5 bg-default">
				<div className="container">
					<Row>
						<Col>
							<h1 className="jumbotron-heading">Fachthemen</h1>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex align-items-center">
							<OverlayTrigger
								placement="top"
								delay={{show: 250, hide: 400}}
								overlay={renderTooltip(
								`Du hast ${topicTooltip} Themen beendet`
								)}
							>
								<ProgressBar bgcolor="#78c800" completed={topicProgress} />
							</OverlayTrigger>
						</Col>
						<Col className="d-flex align-items-center justify-content-end mb-3">
							<Dropdown show={showDropdown} alignRight onToggle={(isOpen, event, meta) => {
								if (!(meta.source === undefined) && (meta.source.toString() == "select")) {
									setShowDropdown(true);
								} else {
									setShowDropdown(!showDropdown);
								}
								}}>


								<Dropdown.Toggle variant="secondary">
									Suchen
								</Dropdown.Toggle>


								<Dropdown.Menu>
									<div className="pl-3 pr-3 pb-3 pt-2">
										<input className="form-control" type="text" placeholder="Suchen" aria-label="Search" onChange={(event) => setTopicSearchValue(event.currentTarget.value)}/>
									</div>
									<table>
										<tbody>
											<tr>
												<th className="align-top">
													<h5 className="pl-3">Sortieren</h5>
													<Dropdown.Item key="sort-favorite" onClick={() => handleSortSelection("Favorite")}>{sortOptions.favorite ? (<AiOutlineCheckCircle className="mr-3 mb-1"/>) : (<BiCircle className="mr-3 mb-1"/>)}Favoriten zuerst</Dropdown.Item>
													<Dropdown.Divider/>
													<h6 className="pl-3">nach</h6>
													<Dropdown.Item key="sort-alphabet" onClick={() => handleSortSelection("Alphabet")}>{sortOptions.alphabet ? (<AiOutlineCheckCircle className="mr-3 mb-1"/>) : (<BiCircle className="mr-3 mb-1"/>)}Alphabet</Dropdown.Item>
													<Dropdown.Item key="sort-status" onClick={() => handleSortSelection("Status")}>{sortOptions.status ? (<AiOutlineCheckCircle className="mr-3 mb-1"/>) : (<BiCircle className="mr-3 mb-1"/>)}Status</Dropdown.Item>
												</th>
												<th className="align-top">
													<h5 className="pl-3">Filtern</h5>
													<Dropdown.Item key="filter-favorite" onClick={() => handleFilterSelection("Favorite", 0)}>{filterOptions.favorite ? (<AiOutlineCheckCircle className="mr-3 mb-1"/>) : (<BiCircle className="mr-3 mb-1"/>)}Nur Favoriten</Dropdown.Item>
													<Dropdown.Divider/>
													<h6 className="pl-3">Status</h6>
													<Dropdown.Item key="filter-status1" onClick={() => handleFilterSelection("Status", 0)}>{filterOptions.status[0] ? (<AiOutlineCheckCircle className="mr-3 mb-1"/>) : (<BiCircle className="mr-3 mb-1"/>)}Offen</Dropdown.Item>
													<Dropdown.Item key="filter-status2" onClick={() => handleFilterSelection("Status", 1)}>{filterOptions.status[1] ? (<AiOutlineCheckCircle className="mr-3 mb-1"/>) : (<BiCircle className="mr-3 mb-1"/>)}In Bearbeitung</Dropdown.Item>
													<Dropdown.Item key="filter-status3" onClick={() => handleFilterSelection("Status", 2)}>{filterOptions.status[2] ? (<AiOutlineCheckCircle className="mr-3 mb-1"/>) : (<BiCircle className="mr-3 mb-1"/>)}Fertig</Dropdown.Item>
												</th>
											</tr>
										</tbody>
									</table>
									</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
					<div className="row">
						{topics.map((entry) => {
							return renderCard(entry);
						})}
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<main role="main">
				<section
					className="jumbotron text-center mb-1"
					style={{ paddingBottom: "5rem" }}
				>
					<div className="container" key={"process_procedure_video"}>
						{/*<h1 className="jumbotron-heading">IT-Awareness Training</h1>*/}
						{/*<p className="lead text-muted">*/}
						{/*	Stärke deine menschliche Firewall mit*/}
						{/*</p>*/}
						<img
							src="/images/logos/new.png"
							style={{
								width: "50%",
								margin: 0,
							}}
						/>
						<h4>Ein Forschungsprojekt der Hochschule Darmstadt</h4>
					</div>
					{!userState.showUserBoard && (
						<p className={"login-register-text"}>
							<Link
								to="/login"
								className="btn btn-primary my-2 login-register-button"
							>
								Login
							</Link>
							<Link
								to="/registrieren"
								className="btn btn-secondary my-2 login-register-button"
							>
								Registrieren
							</Link>
						</p>
					)}
				</section>
				{!userState.showUserBoard && (
					<section style={{ marginTop: "40px", marginBottom: "40px", alignItems: "center" }}>
					<Container >
						<Row className="row align-items-center">
							<Col>
								<img src="images/homepage/Problematik.png" style={{ maxWidth: "100%" }} />
							</Col>
							<Col>
								<div>
									<h4 style={{ marginBottom: "0.5em" }}>Deutschland ist und bleibt ein hoch attraktives Geschäftsfeld für Cyberkriminelle</h4>
									<p className="indexinfo">
										Die „Schwachstelle Mensch“ ist eine der größten Bedrohungen für die IT-Sicherheit von Unternehmen,
										Organisationen und Endnutzern. Der Proofpoint Human Factor Report (2019) belegt: 99% aller Angriffe zielen
										auf die “Schwachstelle Mensch” ab und setzen für ihren Erfolg auf mindestens eine menschliche Interaktion.
										Dabei nutzen Angreifer zunehmend emotionale Auslöser wie Stress am Arbeitsplatz, Angst, Neugier oder globale
										Ereignisse wie die Corona-Pandemie oder den Ukraine-Konflikt für ihre Social-Engineering-Attacken und
										schaffen so immer neue und effektivere Angriffsvektoren. Auch die Schäden für die deutsche Wirtschaft nehmen
										zu: In den letzten drei Jahren wurde jedes zweite Unternehmen Opfer eines erfolgreichen Cyber-Angriffs.
										Erschwerend kommt hinzu, dass bis zu 47% der Unternehmen im Falle einer Ransomware-Attacke gezwungen waren,
										das geforderte Lösegeld zu zahlen. Der Standort Deutschland ist und bleibt für Cyberkriminelle ein
										hochattraktives Geschäftsfeld. Es bedarf daher eines neuen Ansatzes, um die Anwender effektiver und
										nachhaltiger zu schulen und damit die Unternehmen vor Schäden zu schützen.
									</p>
								</div>
							</Col>
						</Row>
						<hr />
						<Row className="row align-items-center">
							<Col>
								<div>
									<h4 style={{ marginBottom: "0.5em" }}>Ein neuer Ansatz für personalisiertes Lernen muss untersucht werden</h4>
									<p className="indexinfo">
										Die Lösung ist die KI-basierte NG (Next Generation) Lernplattform SecureBot zur zielgruppenspezifischen
										Vermittlung von Expertenwissen im Bereich Cybersicherheit und IT-Awareness, die im Rahmen des durch das
										Förderprogramm Distr@l geförderten Forschungsprojekts „KI-basierte Next Generation Lernplattform für
										Cybersicherheit und IT-Awareness“ der Hochschule Darmstadt entwickelt wird. Mit der NG-Lernplattform
										SecureBot soll ein neuer und innovativer Ansatz zur Verfügung gestellt werden, um Nutzer effektiver und
										nachhaltiger zu schulen und damit Unternehmen und Privatpersonen vor einer komplexen und stark
										fluktuierenden Cyber-Bedrohungslage zu schützen. Dazu wird der Nutzer mit seinem individuellen Lernverhalten
										in den Mittelpunkt des Lernprozesses gestellt. Dies ermöglicht die individuelle Anpassung des Lernprozesses
										durch eine adaptive und digitale Lernumgebung. Darüber hinaus bindet die NG-Lernplattform KI-Chatbots als
										Sicherheitsberater und Personal Trainer aktiv in den Lernprozess ein.
									</p>
								</div>
							</Col>
							<Col>
								<img className="" src="images/homepage/Lösung.png" style={{ maxWidth: "100%" }} />
							</Col>
						</Row>
					</Container>
				</section>
				)} 

				
				{userState.showUserBoard && renderTopics()}
			</main>

			<footer className="text-muted bg-dark variant-dark footer" style={{ marginTop: "40px"}}>
				<div
					className="container d-flex justify-content-between"
					style={{paddingBottom: "10px", paddingTop: "10px"}}
				>
					<div>
						<a href="https://fbi.h-da.de/">Hochschule Darmstadt Fachbereich Informatik</a>
					</div>
					<div className="privacyPolicyText" onClick={() => setPrivacyModalIsOpen(true)}>
						Datenschutzerklärung
					</div>
					<div>
						<a href="/#">Zum Seitenanfang</a>
					</div>
					<div>
						<a href="mailto:securebot-contact.fbi@h-da.de"> Kontaktiere uns</a>
					</div>
					<div>
						<p style={{color: "#1165c9"}}>Version: Beta</p>
					</div>
				</div>
			</footer>

			<Modal
				size="lg"
				show={privacyModalIsOpen}
				onHide={() => setPrivacyModalIsOpen(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Datenschutzerklärung
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PrivacyPolicyText />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setPrivacyModalIsOpen(false)}>
						Schließen
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={feedBackNotGiven}
				onHide={() => setFeedbackNotGiven(false)}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title>Alle Fachthemen abgeschlossen 🎉</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Du hast nun alle Fachthemen abgeschlossen, jedoch uns noch kein Feedback
						gegeben. Bitte gib uns dein Feedback zu der Lernplattform. Dies
						dauert nur weniger als eine Minute und hilft uns die Plattform zu
						verbessern.
					</p>

					<b>Vielen Dank für dein Verständnis!</b>
					<br />
					<br />
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => history.push("/OnlineUmfrage")}
					>
						Feedback
					</Button>
					<Button variant="secondary" onClick={() => setFeedbackNotGiven(false)}>
						Schließen
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
