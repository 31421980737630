import React, {useContext} from "react";
import {QuizContext} from "../../contexts/QuizContext";
import {IoReloadOutline, IoSchoolOutline} from "react-icons/io5";
import {useHistory} from "react-router-dom";

export const FailPage = () => {
    const context = useContext(QuizContext);
    const handleRestart = context.actions.resetStartEndQuiz;
    const history = useHistory();

    const goToHomepage = () => {
        history.push("/");
    };

    return (
        <>
            <div id="fail-head-container">
                <IoSchoolOutline id="fail-icon"/>
                <h2 id="fail-headline"> Übung macht den Meister! </h2>
                <IoSchoolOutline id="fail-icon"/>
            </div>
            <br />

            <div id="fail-container">
                <h4>Dein persönlicher Highscore: {context.highscore}</h4>
            </div>

            <div className="leftTextBox3 justify-content-end zuruck-container">
                <button className="general-quiz-btn btn-secondary" onClick={()=>{
                    goToHomepage()
                }}>Zurück</button>
                <button className="general-quiz-btn btn-primary" onClick={handleRestart}>Neustart</button>
            </div>
        </>
    );
};
