export const ransomware = `
# Ransomware

## Definitionen 
 
- Definition Ransomware 
- Definition Phishing 
- Definition Malware 
- Definition Exploits
- Definition Ransomware as a Service (RaaS) 
- Unterschied Ransomware und Computerviren


## Arten von Ransomware 
- Screenlocker Definition und Funktionsweise 
- Cryptolocker Definition und Funktionsweise 
- Leakware Definition und Funktionsweise 

## Funktionsweise 
- Verbreitung von Malware auf dem System 
- Merkmale eines erfolgreichen Ransomware Angriff
- Zielgruppen von Ransomware-Angriffen
- Schutz vor Ransomware
- Erkennen von Ransomware
- Backups sicher aufbewahren
- Ziele eines Ransomware-Angriffs
- Maßnahmen nach einer Infektion mit Ransomware


## Verbreitungsmethoden
- Allgemeine Verbreitungsmethode 
- Verbreitung durch Phishing-Mails
- Verbreitung durch Infizierte Websites und Anzeigen 
- Verbreitung durch Wechseldatenträger  
- Verbreitung durch Schwachstellen und Exploits
 `;