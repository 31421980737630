export const phishing = `
# Phishing

## Phishing Grundlagen  
- Definition Phishing  
- Definition Phishing URL
- Unterschied Phishing und Spam
- Schäden von Phishing
- Wie kann die Infizierung durch Phishing erfolgen

## Arten von Phishing 
- Definition Spray-and-Pray Phishing  
- Definition Smishing 
- Definition CEO Fraud
- Definition Phishing Mail 
- Definition Spear-Phishing
- Definition Vishing
- Definition Pharming
- Definition Quishing

## Phishing-Angriffstechniken
- Angriffsvektoren von Phishing
- Zusammenhang Social Engineering und Phishing
- Zusammenhang Malware und Phishing

## Erkennen und Schutzmaßnahmen gegen Phishing-Versuchen
- Verhalten nach Infizierung 
- Infizierung erkennen
- Phishing-Präventation
- Verdächtige E-Mails identifizieren
- Umgang mit verdächtigen Anrufen
- Erkennen von unsicheren Websiten
- Erkennen & Schutz vor Quishing

## Beispiele für Phishing-Angriffe
- Beispiel für einen CEO Fraud 
- Beispiel für einen Vishing-Versuch
- Beispiel für einen Phishing-Versuch
- Beispiel für einen Smishing-Versuch 


`;