import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, ListGroup, Modal, Row} from "react-bootstrap";
import {FaUserCircle} from "react-icons/fa";
import {IoIosArrowForward} from "react-icons/io";
import axios from "axios";
import {AppSettings} from "../config";
import {userContext} from "../contexts/UserContext";
import {Eye} from "react-bootstrap-icons";
import Certificate from "../components/Certificate";
import {BlobProvider, PDFDownloadLink} from "@react-pdf/renderer";
import {UserStatus} from "../models/User";
import {Category} from "../models/Category";
import authHeader from "../services/auth-header";

export const SettingsPage: React.FC = () => {

     type UserData = {
        nickname: string;
        e_mail: string | "";
        age: number;
        gender_id: number;
        fieldOfStudy: string;
        name:string | "";
        regkey:string | "";
    };

    const context = useContext(userContext)
    const [userData, setUserData] = useState<UserData | null>(null);
    const [switchTimer, setSwitchTimer] = useState(context.timer);
    const [switchTheme, setSwitchTheme] = useState(false);
    const [modalSettings, setModalSettings] = useState(false);
    const [modalChangePassword, setModalChangePassword] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [email, setEmail] = useState(userData?.e_mail);
    const [name, setName] = useState(userData?.name);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordShow, setPasswordShow] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [errorMsgOldPw, setErrorMsgOldPw] = useState("");
    const [passwordConfirmShow, setPasswordConfirmShow] = useState(false);
    const [errorMsgPw, setErrorMsgPw] = useState("");
    const [errorMsgConfirmPw, setErrorMsgConfirmPw] = useState("");
    const [theme, setTheme] = useState('default');
    const [editName, setEditName] = useState(false);
    const [userStatus] = useState<UserStatus[]>(context.status);


    const gender = ["Weiblich", "Männlich", "Divers"];

    const onSwitchTimer = () => {
        setSwitchTimer(!switchTimer);
        context.actions.updateTimer(!switchTimer);
    };

    const onSwitchTheme = () => {
        setSwitchTheme(!switchTheme)
        if (theme == 'default') setTheme('darkly');
        else if (theme == 'darkly') setTheme('default');
    };

    const handleModalSettings = () => setModalSettings(!modalSettings);

    const handleModalChangePassword = () => setModalChangePassword(!modalChangePassword);

    const checkPassword = (value: string) => {
        let requiredComponents =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,35}$/;

        if (!value.match(requiredComponents)) {
            setErrorMsgPw(
                "Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten."
            );
            return false;
        } else {
            setErrorMsgPw("");
            return true;
        }
    };

    const checkConfirmPassword = (value: string, confirmPassword: string) => {
        if (value !== confirmPassword) {
            setErrorMsgConfirmPw("Die Passwörter stimmen nicht überein!");
            return false;
        } else {
            setErrorMsgConfirmPw("");
            return true;
        }
    };

    const checkOldPassword = (oldPassword: string) => {
        let requiredComponents =
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,35}$/;

        if (!oldPassword.match(requiredComponents)) {
            setErrorMsgOldPw(
                "Bitte altes Passwort eingeben"
            );
            return false;
        } else {
            setErrorMsgOldPw("");
            return true;
        }
    };

    const getCertificateProps = () =>  {
        let completedCategories: Category[] = []

        userStatus.forEach((entry) => {
            if (entry.status === "3") {
                completedCategories.push(entry.category)
            }
        });

        return {
            username: userData?.nickname,
            timestamp: getTCurrentTimestamp(),
            age: userData?.age,
            categories: completedCategories
        }
    }

    const getTCurrentTimestamp = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        return dd + '.' + mm + '.' + yyyy;
    }

    useEffect(() => {
        axios
            .get(AppSettings.USER_DATA + context.user?.user_id, {
                headers: {
                    Authorization: context.actions.authHeader()
                }
            } )
            .then((response) => {
                if (response.status === 200) {
                    setUserData(response.data.data);
                    setEmail(response.data.data.e_mail);
                    setName(response.data.data.name);
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
    },[context.user?.user_id])

    const saveEmail = () => {
        axios
            .put(AppSettings.USER_EMAIL, {id: context.user?.user_id, email: email}, {
                headers: {
                    Authorization: context.actions.authHeader()
                }
            } )
            .then((response) => {
                if (response.status === 200) {
                    if (email!.length > 1)
                        setUserData(prev => {
                            //überprüfen ob es einen vorherigen Zustand gibt, ansonsten null
                            if (prev === null) return null;
                            return {
                                //Kopieren aller vorhandenen Daten von prev
                                ...prev,
                                //Aktualisieren der email
                                e_mail: response.data.data.email,
                            };
                        });                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
        setEditEmail(false);
        setModalSettings(false);
    };

    const saveName = () => {
        axios
            .put(AppSettings.USER_NAME, {id: context.user?.user_id, name: name}, {
                headers: {
                    Authorization: context.actions.authHeader()
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    if (name!.length > 1)
                        setUserData(prev => {
                            if (prev === null) return null;
                            return {
                                ...prev,
                                name: response.data.data.name,
                            };
                        });
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }
                console.log(error.config);
            });
        // backend request to change name
        setEditName(false);
        setModalSettings(false);
    }

    const savePassword = () => {
        if (checkPassword(password) && checkConfirmPassword(password, confirmPassword) && checkOldPassword(oldPassword)) {
            axios
                .put(AppSettings.USER_PASSWORD, {id: context.user?.user_id, password: password, oldPassword: oldPassword},{
                    headers:{
                        'Authorization': context.actions.authHeader()
                    }})
                .then((response) => {
                    if (response.status === 200) {
                        if (email!.length > 1) userData!.e_mail = email!;
                        setPassword("");
                        setConfirmPassword("");
                        setOldPassword("");
                        setModalChangePassword(false);
                    }
                })
                .catch((error) => {
                    if(error.response.status === 401) {
                        setErrorMsgOldPw("Altes Passwort ist nicht korrekt.")
                    }
                    if (error.response) {
                        console.log(error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    console.log(error.config);
                });
        }
    };

    useEffect(() => {

    }, [userData])

    return (
        <div className="container">
            <h1 className="text-center mt-5">Einstellungen</h1>
            <Row className="justify-content-md-center mt-5">
                <Col md="6">
                    <ListGroup>
                        <ListGroup.Item action onClick={handleModalSettings}>
                            <Row className="align-items-center">
                                <Col xs={"2"} sm={"auto"}>
                                    <FaUserCircle size={42}></FaUserCircle>
                                </Col>
                                <Col xs={"8"} sm={"auto"}>
                                    <Row className="align-items-center">
                                        <p className="mb-0">
                                            {userData?.name == "" && (<span>Vor- und Nachname </span>)}
                                            {userData?.name !== "" && (<span>{userData?.name}</span>)}
                                            <br/>
                                            {userData?.e_mail == "" && (<span>keine E-Mail registriert</span>)}
                                            {userData?.e_mail !== "" && (<span>{userData?.e_mail}</span>)}
                                        </p>
                                    </Row>
                                </Col>
                                <Col className="text-right">
                                    <IoIosArrowForward></IoIosArrowForward>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col xs={"8"} md={"auto"} >
                                    Benutzername:
                                </Col>
                                <Col className="text-right">
                                    {userData?.nickname}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item action onClick={handleModalChangePassword}>
                            <Row>
                                <Col md={"auto"} >
                                    Passwort ändern
                                </Col>
                                <Col className="text-right">
                                    <IoIosArrowForward></IoIosArrowForward>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col xs={"8"} md={"auto"} >
                                    Berufsbezeichnung:
                                </Col>
                                <Col className="text-right">
                                    {userData?.fieldOfStudy}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                {/*später soll hier Alter durch Geburtsdatum ausgetauscht werden*/}
                                <Col xs={"8"} md={"auto"} >
                                    Alter:
                                </Col>
                                <Col className="text-right">
                                    {userData?.age}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Row>
                                <Col xs={"8"} md={"auto"} >
                                    Geschlecht:
                                </Col>
                                <Col className="text-right">
                                    {userData?.gender_id != null && (gender[userData!.gender_id - 1])}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        {/*{userData?.nickname != null && (*/}
                        {/*    <ListGroup.Item>*/}
                        {/*        <Row>*/}
                        {/*            <Col xs={"8"} md={"auto"} >*/}
                        {/*                Zertifikat:*/}
                        {/*            </Col>*/}
                        {/*            <Col className="text-right">*/}
                        {/*                <BlobProvider document={<Certificate props={getCertificateProps()}/>}>*/}
                        {/*                    {({ url}) => (*/}
                        {/*                        <a href={(url != null) ? url : ""} target="_blank">Anzeigen</a>*/}
                        {/*                    )}*/}
                        {/*                </BlobProvider>*/}
                        {/*                <span> | </span>*/}
                        {/*                <PDFDownloadLink document={<Certificate props={getCertificateProps()} />} fileName="SecureBot-Zertifikat.pdf">*/}
                        {/*                    {({ blob, url, loading, error }) => (loading ? 'Dokument lädt...' : 'Download')}*/}
                        {/*                </PDFDownloadLink>*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*    </ListGroup.Item>*/}
                        {/*)}*/}
                        <ListGroup.Item action>
                            <Row>
                                <Col xs={"8"} md={"auto"}>
                                    Timer für Eingang und Ausgangstest
                                </Col>
                                <Col className="text-right">
                                    <Form>
                                        <Form.Check
                                            onChange={onSwitchTimer}
                                            checked={switchTimer}
                                            type="switch"
                                            id="switch-timer"
                                        />
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>

            <Modal show={modalSettings} onHide={handleModalSettings}>
                <Modal.Header closeButton>
                    <Modal.Title>Profil bearbeiten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Vollständiger Name</Form.Label>
                        <InputGroup>
                            <Form.Control disabled={!editName} type="text" onChange={(e) => {setName(e.currentTarget.value)}} placeholder={"Vor- und nachname"} value={name}/>
                            <InputGroup.Prepend>
                                {editName && (
                                    <InputGroup.Text onClick={saveName} style={{cursor:'pointer'}} className="text-primary">speichern</InputGroup.Text>
                                )}
                                {!editName && (
                                    <InputGroup.Text onClick={() => setEditName(!editName)} style={{cursor:'pointer'}} className="text-primary">bearbeiten</InputGroup.Text>
                                )}
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-Mail Adresse</Form.Label>
                        <InputGroup>
                            <Form.Control disabled={!editEmail} type="email" onChange={(e) => {setEmail(e.currentTarget.value)}} placeholder="Email eingeben" value={email}/>
                            <InputGroup.Prepend>
                                {editEmail && (
                                    <InputGroup.Text onClick={saveEmail} style={{cursor:'pointer'}} className="text-primary">speichern</InputGroup.Text>
                                )}
                                {!editEmail && (
                                    <InputGroup.Text onClick={() => setEditEmail(!editEmail)} style={{cursor:'pointer'}} className="text-primary">bearbeiten</InputGroup.Text>
                                )}
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button variant="secondary" onClick={() => {
                            setEditEmail(false);
                            setModalSettings(false);
                        }}>
                            Abbrechen
                        </Button>
                    </>
                </Modal.Footer>
            </Modal>

            <Modal show={modalChangePassword} onHide={handleModalChangePassword}>
                <Modal.Header closeButton>
                    <Modal.Title>Passwort ändern</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label className="font-weight-bold">altes Passwort*</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={oldPasswordShow ? "text" : "password"}
                                name="oldPassword"
                                placeholder="altes Passwort eingeben"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <InputGroup.Prepend className="showPasswordIcon">
                                <InputGroup.Text>
                                    <Eye style={{cursor:'pointer'}}
                                         onClick={() =>
                                             !oldPasswordShow && oldPassword.length !== 0
                                                 ? setOldPasswordShow(true)
                                                 : setOldPasswordShow(false)
                                         }
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                        <Form.Text className="text-danger">{errorMsgOldPw}</Form.Text>

                        <Form.Label className="font-weight-bold">Passwort*</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={passwordShow ? "text" : "password"}
                                name="password"
                                placeholder="Passwort eingeben"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputGroup.Prepend className="showPasswordIcon">
                                <InputGroup.Text>
                                    <Eye style={{cursor:'pointer'}}
                                        onClick={() =>
                                            !passwordShow && password.length !== 0
                                                ? setPasswordShow(true)
                                                : setPasswordShow(false)
                                        }
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                        <Form.Text className="text-danger">{errorMsgPw}</Form.Text>

                        <Form.Label className="font-weight-bold">
                            Passwort bestätigen*
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={passwordConfirmShow ? "text" : "password"}
                                name="checkPassword"
                                placeholder="Passwort bestätigen"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <InputGroup.Prepend className="showPasswordIcon">
                                <InputGroup.Text>
                                    <Eye style={{cursor:'pointer'}}
                                        onClick={() =>
                                            !passwordConfirmShow && confirmPassword.length !== 0
                                                ? setPasswordConfirmShow(true)
                                                : setPasswordConfirmShow(false)
                                        }
                                    />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup>
                        <Form.Text className="text-danger">
                            {errorMsgConfirmPw}
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <>
                        <Button variant="secondary" onClick={() => {
                            setPassword("");
                            setConfirmPassword("");
                            setModalChangePassword(false)
                        }}>
                            Abbrechen
                        </Button>
                        <Button variant="primary" onClick={() => {
                            savePassword();
                        }}>
                            Speichern
                        </Button>
                    </>
                </Modal.Footer>
            </Modal>
        </div>
    );
};