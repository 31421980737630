import React from "react";

type BarProp = {
    bgcolor: string;
    completed: number;
    maxQuizTime: number;
}
export const ProgressBarQuiz = ({ bgcolor, completed, maxQuizTime }: BarProp) => {
    // const { bgcolor, completed } = props;
    //Rechnet die übergebene Zahl in Prozent aus
    const zwischenErgebnis = completed/maxQuizTime;
    const ergebnis = zwischenErgebnis*100;
    let farbe: any;


    if(ergebnis>50){
        farbe = "#66bafa";
    } else if(ergebnis>25){
        farbe ="#cfb41f";
    } else if(ergebnis<=25){
        farbe ="#fa7266";
    }

    return (
        <div style={{
            alignContent: 'right',
            height: 25,
            width: '50%',
            backgroundColor: "#e0e0de",
            borderRadius: 100,
            margin: 20,
        }}>

                <div style={{
                    height: '100%',
                    width: `${ergebnis}%`,
                    backgroundColor: farbe,
                    borderRadius: 'inherit',
                    textAlign: 'left'
                }
                }>
                    {/* Text in Progress Bar*/}
                    <span style={{
                    userSelect: 'none',
                    padding: 15,
                    color: 'black',
                    fontWeight: 'bold'
                }}>{`${completed}`}</span>
            </div>
        </div>
    );
};


