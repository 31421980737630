import React, {createContext, useState, useEffect, useContext} from "react";
import axios from "axios";
import {AppSettings} from "../config";
import {userContext as UserContext} from "./UserContext";
import {handleCatch} from "../pages/QuizPage";

type quizContent = {
    actions: {
        startQuiz: () => void;
        endQuiz: () => void;
        resetStartEndQuiz: () => void;
        settingResult: (val : boolean) => void;
        setHighscore: (high: number) => void;
        setPoints: (points: number) => void;
    };
    start: boolean;
    end: boolean;
    result: boolean;
    timerstart: boolean;
    highscore: number;
    points: number;
}

export const initialContext = {
    actions: {
        startQuiz: () => {},
        endQuiz: () => {},
        resetStartEndQuiz: () => {},
        settingResult: (val : boolean) => {},
        setHighscore: () => {},
        setPoints: () => {},
    },
    start: false,
    end: false,
    result: false,
    timerstart: false,
    highscore: 0,
    points: 0,

};

export const QuizContext = createContext<quizContent>(initialContext);

export const QuizContextProvider: React.FC = ({children}) => {
    const userContext = useContext(UserContext);
    const [quizStarted, setQuizStarted] = useState(false);
    const [quizFinished, setQuizFinished] = useState(false);
    const [quizWin, setQuizWin] = useState(false);
    const [quizActiveTimer, setQuizActiveTimer] = useState(false);
    const [quizHighscore, setQuizHighscore] = useState(0);
    const [quizPoints, setQuizPoints] = useState(0);

    //holt den vorherigen Highscore
    useEffect(() => {
        if(userContext.user) {
            axios
                .get(AppSettings.HIGH_SCORE_URL + "get/" + userContext.user?.user_id, {
                    headers: {Authorization: `Bearer ${userContext.user.access_token}`},
                })
                .then((res) => {
                    if (res.status === 200) {
                        setQuizHighscore(res.data.data);
                    }
                })
                .catch(handleCatch);
        }
    }, [userContext.actions, userContext.user]);

    useEffect(() => {
        setQuizStarted(false);
    },[])

    function startQuiz() {
        setQuizStarted(true);
        setQuizActiveTimer(true);
    }

    function endQuiz() {
        setQuizFinished(true);
        setQuizActiveTimer(false);
    }

    function resetStartEndQuiz(){
        setQuizStarted(false);
        setQuizFinished(false);
        setQuizActiveTimer(false);
    }

      return (
        <QuizContext.Provider
            value={{
                actions: { startQuiz, endQuiz, resetStartEndQuiz, settingResult:setQuizWin, setHighscore:setQuizHighscore, setPoints:setQuizPoints},
                start: quizStarted,
                end: quizFinished,
                result: quizWin,
                timerstart: quizActiveTimer,
                highscore: quizHighscore,
                points: quizPoints,
            }}
        >
            {children}
        </QuizContext.Provider>
    );
}


