import React from "react";
import {useHistory} from "react-router-dom";
import {Button, Container, Tooltip, OverlayTrigger} from "react-bootstrap";

type InterviewProp = {
    title : string,
    textOfFirstRow : string,
    textOfSecondRow: string,
    textOfThirdRow: string,
    textOfFourthRow: string,
    category_id : string,
    categoryStatus : string,
    icon_path : string,
}
export const InterviewBackground = ( interviewProp : InterviewProp) => {
    const history = useHistory();
    const handleButton = () => {
        history.push("/abschlusstest/" + interviewProp.category_id);
    };

    return (
        <Container>
            <section className="jumbotron text-center" style={{paddingBottom: "5rem"}}>
                <div className="container" key={"process_procedure_video"}>
                    <h1 className="jumbotron-heading">{interviewProp.title}</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className={"col s12"}>
                            <div className={"row"}>
                                <div className="col s4">
                                    <img
                                        src={interviewProp.icon_path}
                                        alt=""
                                    />
                                </div>
                                <div className="col s8 my-auto" style={{textAlign: "left"}}>
                                    <p className="header-topic-text">{interviewProp.textOfFirstRow}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className={"col s12"}>*/}
                    {/*        <div className={"row"}>*/}
                    {/*            <div className="col s8 my-auto" style={{textAlign: "left"}}>*/}
                    {/*                <p className="header-topic-text"> {interviewProp.textOfSecondRow}*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*            <div className="col s4">*/}
                    {/*                <img*/}
                    {/*                    src={"/images/befragter/start.png"}*/}
                    {/*                    alt=""*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className={"col s12"}>
                            <div className={"row"}>

                                <div className="col s8 my-auto" style={{textAlign: "left"}}>
                                    <p className="header-topic-text">{interviewProp.textOfThirdRow}
                                    </p>
                                </div>
                                <div className="col s4">
                                    <img
                                        src={"/images/befragter/dialog_icon.png"}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col s12"}>
                            <div className={"row"}>
                                <div className="col s4">
                                    <img
                                        src={"/images/befragter/done_green.png"}
                                        alt=""
                                    />
                                </div>
                                <div className="col s8 my-auto" style={{textAlign: "left"}}>
                                    <p className="header-topic-text"> {interviewProp.textOfFourthRow}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <OverlayTrigger
                    placement="bottom"
                    delay={{show: 250, hide: 400}}
                    overlay={
                        interviewProp.categoryStatus === "3" ? (
                            <Tooltip id={`tooltip`}>Abschlusstest bereits bestanden!</Tooltip>
                        ) : (
                            <div />
                        )
                    }
                >
                    <Button style={{float: "left", marginLeft: "13px"}} onClick={() => handleButton()} disabled={interviewProp.categoryStatus === "3"}>Zum Abschlusstest</Button>
                </OverlayTrigger>
            </section>
        </Container>


    );
}