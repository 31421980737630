import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {Category} from "../models/Category";
import {userContext} from "./UserContext";
import {AppSettings} from "../config";

type CategoryContext = {
    actions: {
        getCategory: (id: number) => any;
    };
    categories: Category[];
};

export const initialContext = {
    actions: {
        getCategory: (id: number) => {
        },
    },
    categories: [],
};

export const categoryContext = React.createContext<CategoryContext>(
    initialContext
);

export const CategoryProvider: React.FC = ({children}) => {
    const context = useContext(userContext);
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        let isMounted = true;

        async function getCategories() {
            if (context.user) {
                await axios.get(AppSettings.CATEGORY_URL, {
                    headers: {Authorization: context.actions.authHeader()},
                })
                    .then((response) => {
                        if (response.status === 200 && isMounted) {
                            setCategories(response.data.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log("Error", error.message);
                        }
                        console.log(error.config);
                    });
            }
        }

        //  if (context.actions.authHeader() !== null) {
        getCategories();
        return () => {
            isMounted = false
        };
        //  }
    }, [context.actions]);

    function getCategory(id: number) {
        const index = categories.findIndex(c => c.category_id === id)
        return categories[index].category_name
    }

    return (
        <categoryContext.Provider
            value={{
                actions: {getCategory},
                categories: categories || [],
            }}
        >
            {children}
        </categoryContext.Provider>
    );
};
