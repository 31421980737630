export const industriespionage = `
# Industrie- und Wirtschaftsspionage
## <br> Definition
- Industriespionage
- Wirtschafsspionage
## <br> Unterschied
## <br> Ziel
## <br> Strafrecht
- erlaubt/legal
- nicht erlaubt/illegal
## <br> Schutzmaßnahmen
- Informationsschutz
- Sicherheitsstandard
`;