export const botnetze = `
# Botnetze

## Definitionen
- Definition C&C Server
- Definition Botmaster
- Definition Botnetz
- Definition Bot
- Definition von guten Bots
- Definition von Zombie-Geräte
- Definition von Honeypots
- Definition von Sinkhole-Systemen

## Infizierungen und Risiken
- Infizierung von Endgeräten
- Erkennen einer Infizierung
- Risiken von Zombie-PCs
- Risiken von Botnetzen 

## Beispiele von Botnetzen
- Schutz vor Botnetzen
- Aufspüren von Botnetzen
- Bereinigung einer Infektion

## Beispiele von Botnetzen
- Avalanche
- VPNfilter
- Smart App
- Aktuell bekannte Botnetze 

`;