export const fakeShops = `
# Bedrohung
## <br> Fake-Shops
- Definition
- Merkmale
    - Falsche Adresse
    - Zahlungsmethoden
    - Datenschutzerklärung
    - Shop Bewertungen
    - Rechtschreib- und Grammatikfehler
    - Domain
    - Falsches Impressum
    - Preis
    - Gütesiegel
- Schutz
- Vorgehen nach einer Bestellung
## <br> Betrug
- Definition
- Vorgehensweise
    - Aufbau der Vorgehensweise
        - Fake Rechnung
        - Verdächtige Aktivität
    - Schutz vor Vorgehensweise
        - Fake Rechnung
        - Verdächtige Aktivität
- Melden von Betrug
## <br> Abofallen
- Definition
- Richtlinien
- Gefahren für Abofallen
- Rechtslage
- Vorgehen bei ungewollt abgeschlossenem Abo
- Widerrufsrecht
- Vorgehen bei Zahlungsaufforderung
`;