import {
	Gender,
	Age,
	Category,
	UserDiff,
	FeedBack,
	Percentile,
} from "../models/Stats";
import {
	Chart,
	ArcElement,
	Tooltip,
	Legend,
	Title,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
} from "chart.js";
import {
	Paired12,
	DarkTwo8,
	SetThree12,
	PastelTwo12,
} from "../colorschemes.brewer";
import { count } from "console";

Chart.register(
	ArcElement,
	Tooltip,
	Legend,
	Title,
	CategoryScale,
	LinearScale,
	BarElement,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler
);

export const genderDistro = (genderStats: Array<Gender>) => {
	return {
		labels: genderStats.map((x: Gender) => x.gender),
		datasets: [
			{
				data: genderStats.map((x: Gender) => x.percentage),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const genderData = (genderStats: Array<Gender>) => {
	return {
		labels: genderStats.map((x: Gender) => x.gender),
		datasets: [
			{
				label: "Geschlechtsunterteilung",
				data: genderStats.map((x: Gender) => x.count),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const averageImprov = (categoryImprovStats: Array<Category>) => {
	return {
		labels: categoryImprovStats.map((x: Category) => x.category_name),
		datasets: [
			{
				data: categoryImprovStats.map((x: Category) => x.number_of_tests),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const agePercentage = (ageStats: Array<Age>, allAgeCount: number) => {
	return {
		labels: ageStats.map((x: Age) => x.age_group),
		datasets: [
			{
				data: ageStats.map((x: Age) => ((x.age_count / allAgeCount) * 100).toFixed(2)),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const ageDistro = (ageStats: Array<Age>) => {
	return {
		labels: ageStats.map((x: Age) => x.age_group),
		datasets: [
			{
				label: "Altersgruppen",
				data: ageStats.map((x: Age) => x.age_count),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const improveRates = (improv: Array<Category>) => {
	return {
		labels: improv.map((x: Category) => x.category_name),
		datasets: [
			{
				label: "Lernzuwachs in %",
				data: improv.map((x: Category) => x.category_average),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const numberOfTests = (improv: Array<Category>) => {
	return {
		labels: improv.map((x: Category) => x.category_name),
		datasets: [
			{
				label: "Anzahl der Tests",
				data: improv.map((x: Category) => x.number_of_tests),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const numberOfTestsPercentage = (improv: Array<Category>) => {
	return {
		labels: improv.map((x: Category) => x.category_name),
		datasets: [
			{
				data: improv.map((x: Category) => x.category_percentage),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const prePostImprov = (categoryImprovStats: Array<Category>) => {
	const filtered_categories = categoryImprovStats.reduce(
		(data_arr: Array<Category>, x: Category) => {
			if (x.pre_average > 0 && x.post_average > 0) data_arr!.push(x);
			return data_arr;
		},
		[]
	);

	return {
		labels: filtered_categories.map((x: Category) => x.category_name),
		datasets: [
			{
				label: "Vor der Nutzung des Bots",
				data: filtered_categories.map((x: Category) => x.pre_average),
				backgroundColor: PastelTwo12[0],
				// borderColor: "rgb(255, 99, 132)",
				// backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
			{
				label: "Nach der Nutzung des Bots",
				data: filtered_categories.map((x: Category) => x.post_average),
				backgroundColor: PastelTwo12[2],
				// borderColor: "rgb(53, 162, 235)",
				// backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
		],
	};
};

export const completeness = (passed: any) => {
	return {
		labels: passed.map((x: any) => x.category_name),
		datasets: [
			{
				label: "Anzahl der beendeten Themen",
				data: passed.map((x: any) => x.amount),
				backgroundColor: PastelTwo12[0],
			},
			// {
			// 	label: 'Anzahl der angefangen Themen',
			// 	data: passed[1].map((x: any) => x.amount),
			// 	backgroundColor: PastelTwo12[2]
			// }
		],
	};
};


export const medians = (entry: any, finish: any) => {

	return {
		labels: entry.map((x: any) => "Priorität " + x.prio),
		datasets: [
			{
				label: "Median der Eingangstest",
				data: entry.map((x: any) => x.median_score),
				backgroundColor: PastelTwo12[0],
			},
			{
				label: "Median der Ausgangstest",
				data: finish.map((x: any) => x.median_score),
				backgroundColor: PastelTwo12[2],
			},
		],
	};
};

export const averageTimeStats = (times: any) => {
	return {
		labels: times.averages.map((x: any) => x.category_name),
		datasets: [
			{
				label: "Durchschnitt der Zeiten für den Eingangstest in Minuten",
				data: times.averages.map((x: any) => x.entry_time_avg / 60),
				backgroundColor: PastelTwo12[3],
			},
			{
				label: "Durchschnitt der Zeiten für den Ausgangstest in Minuten",
				data: times.averages.map((x: any) => x.finish_time_avg / 60),
				backgroundColor: PastelTwo12[2],
			},
		],
	};
};

export const subAboveTimeStats = (times: any) => {
	return {
		labels: times.entry.map((x: any) => x.category_name),
		datasets: [
			{
				label: "Anzahl der Eingangstests" ,
				data: times.entry.map((x: any) => x.count),
				backgroundColor: PastelTwo12[3],
			},
			{
				label: "Anzahl der Ausgangstest",
				data: times.finish.map((x: any) => x.count),
				backgroundColor: PastelTwo12[2],
			},
		],
	};
};

// user stats
export const userRadarChart = (userDiff: Array<UserDiff>) => {
	return {
		labels: userDiff.map((x: UserDiff) => x.category_name.length < 15 ? x.category_name : x.category_name.split(' ')[0]),
		datasets: [
			{
				label: "Vor der Nutzung des Bots",
				data: userDiff.map((x: UserDiff) => x.entry_points),
			},
			{
				label: "Nach der Nutzung des Bots",
				data: userDiff.map((x: UserDiff) => x.finished_points),
				backgroundColor: "rgba(255, 99, 132, 0.2)",
				borderColor: "rgba(255, 99, 132, 1)",
			},
		],
	};
};

export const userPercentiles = (perc: Array<Percentile>) => {
	return {
		labels: perc.map((x: Percentile) => x.category_name.length < 15 ? x.category_name : x.category_name.split(' ')[0]),
		datasets: [
			{
				label: "Du bist besser als",
				data: perc.map((x: Percentile) => x.percentile.percentile_rating),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const userCompleteness = (userComp: Array<any>) => {
	return {
		labels: userComp.map((x: any) => {
			let label;
			switch (x.status) {
				case "1":
					label = "Offen";
					break;
				case "2":
					label = "In Bearbeitung";
					break;
				case "3":
					label = "Fertig";
					break;
				default:
					break;
			}
			return label;
		}),
		datasets: [
			{
				data: userComp.map((x: any) => x.finished_state),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const userTimes = (userTimes: any) => {
	return {
		labels: userTimes.map((x: any) => x.category_name.length < 15 ? x.category_name : x.category_name.split(' ')[0]),
		datasets: [
			{
				label: "Eingangstest Zeit in Sekunden",
				data: userTimes.map((x: any) => x.entry_test_time),
				backgroundColor: PastelTwo12[4],
			},
			{
				label: "Ausgangstest Zeit in Sekunden",
				data: userTimes.map((x: any) => x.finish_test_time),
				backgroundColor: PastelTwo12[5],
			},
		],
	};
};

//feedback stats
export const feedbackAverages = (feedbackStats: any) => {
	return {
		labels: feedbackStats.map((x: any) => x.name),
		datasets: [
			{
				data: feedbackStats.map((x: any) => x.score),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const suggestionsStat = (feedbackStats: any) => {
	return {
		labels: feedbackStats.map((x: any) => x.answer),
		datasets: [
			{
				data: feedbackStats.map((x: any) => x.count),
				backgroundColor: PastelTwo12,
			}
		]
	}
}
export const suggestionsStatPercentage = (feedbackStats: any) => {
	let overall = feedbackStats.
		reduce((acc: any, curr: any) => {
			return acc + curr.count
		}, 0)

	return {
		labels: feedbackStats.map((x: any) => x.answer),
		datasets: [
			{
				data: feedbackStats.map((x: any) => (x.count / overall) * 100),
				backgroundColor: PastelTwo12,
			}
		]
	}
}

export const testMethod = (feedbackStats: any) => {	
	return {
		labels: feedbackStats.map((x: any) => x.name),
		datasets: [
			{
				label: 'Testmethode',
				data: feedbackStats.map((x: any) => x.count),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const testMethodPercentage = (feedbackStats: any) => {
	
	return {
		labels: feedbackStats.map((x: any) => x.name),
		datasets: [
			{
				label: 'Testmethode',
				data: feedbackStats.map((x: any) => (x.count/x.overall)*100),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const recommendPercentage = (feedbackStats: any) => {
	return {
		labels: feedbackStats.map((x: any) => x.name.length < 15 ? x.name : x.name.split(' ')[0]),
		datasets: [
			{
				data: feedbackStats.map((x: any) => (x.score / x.overall) * 100),
				backgroundColor: PastelTwo12,
			},
		],
	};
};

export const recommendRate = (feedbackStats: any) => {
	return {
		labels: feedbackStats.map((x: any) => x.name.length < 15 ? x.name : x.name.split(' ')[0]),
		datasets: [
			{
				label: 'Empfehlungen',
				data: feedbackStats.map((x: any) => x.score),
				backgroundColor: PastelTwo12,
			},
		],
	};
};
